import React from 'react';
import styled from 'styled-components';
import TableSection from '../../../Components/General/Media/MediaTable/TableSection';
import { MEDIA_EVENT_COLUMN } from '../../../Components/General/Data/GeneralData';
import AddButton from '../../../Components/General/Media/MediaReuseable/AddButton';
const Event = ({ Data }) => {

	//------------------------------------
	return (
		<StyledConatiner>
			<TableSection
				Columu={MEDIA_EVENT_COLUMN}
				Data={Data.data}
				subName='event'
			/>
			<AddButton to={`/general/event/edit/newevent?tag=0`} />
		</StyledConatiner>
	);
};

export default Event;
const StyledConatiner = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
	margin-bottom: 80px;
`;

