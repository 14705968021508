import React, { createContext, useContext, useState } from 'react';
//---------------------------------------------------creating context----------------------------
const DataContext = createContext();
export const useData = () => {
	return useContext(DataContext);
};
//----------------------------------------------------------------------------------------------
const DataProvider = (props) => {
	//const Url = 'https://dev.abg-am.com/api/v1';
	const Url = process.env.REACT_APP_API

	//-----------------
	const [popUp, setPopUp] = useState('');
	const [error, setError] = useState('');

	///----------------------------
	const [collborate, setCollborate] = useState('');
	//--
	//------------------------------title---------------------------------
	const [title, setTitle] = useState({ title: '', subtitle: '' });
	//------Table---------------------table-------------------------------------
	const [tableData, setTableData] = useState({
		table: '',
		tableNavData: '',
		tableNameData: '',
		tableActive: '',
	});
	///---------------------------------ProductData------------------
	const [productData, setProductData] = useState('');
	//-------------------------------------------values--------------------------------
	const value = {
		collborate,
		setCollborate,
		//-------------
		title,
		setTitle,
		///------------------
		tableData,
		setTableData,
		//----------------
		Url,
		//------------
		productData,
		setProductData,
		//------
		popUp,
		setPopUp,
		//---------
		error,
		setError,
	};
	///-------------------------------------------------------------------------
	return (
		<DataContext.Provider value={value}>{props.children}</DataContext.Provider>
	);
};

export default DataProvider;
