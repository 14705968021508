export const Analytics_Data = [
	{
		id: '1',
		name: ' Acquisitions',
		link: 'acquisitions',
	},
	{
		id: '2',
		name: 'Audience',
		link: 'audience',
	},
	{
		id: '3',
		name: 'Behavior',
		link: 'behavior',
	},
];
