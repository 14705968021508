import React from 'react';
import styled from 'styled-components';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

const TableInput = ({ id, name, ...prop }) => {
	const { userPermission } = userAuthorization();

	return (
		<>
			<Input
				type='text'
				id={id}
				name={name}
				{...prop}
				readOnly={userPermission.disableInput}
			/>
		</>
	);
};

export default TableInput;
const Input = styled.input`
	width: 100%;
	display: block;
	height: 40px;
	background: transparent;
	border: solid 1px #ccc;
	transition: all 0.3s ease;
	padding: 0 15px;
	outline: none;
	&:focus {
		border-color: #39a9cb;
	}
`;
