import React, { useState } from 'react';
import styled from 'styled-components';
import TreeViewContainer from './TreeViewContainer';
import { Turn as Hamburger } from 'hamburger-react';

const SideMenu = () => {
	const [isOpen, setOpen] = useState(false);

	return (
		<StyledConatiner>
			<Menu>
				<Hamburger
					toggled={isOpen}
					toggle={setOpen}
					size={20}
					color='#f0852d'
				/>
				<h2>Menu</h2>
			</Menu>
			<TreeViewContainer />
		</StyledConatiner>
	);
};

export default SideMenu;
const StyledConatiner = styled.aside`
	max-width: 280px;
	min-width: 280px;
	height: calc(100vh - 110px);
	background-color: #ffffff;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	position: sticky;
	top: 70px;
	left: 0;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 10px;
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #0ae;
		height: 50px;
		border-radius: 2px;
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			color-stop(0.5, rgba(255, 255, 255, 0.2)),
			color-stop(0.5, transparent),
			to(transparent)
		);
	}
	span {
		display: grid;
		place-items: center;
	}
`;
const Menu = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	& .hamburger-react {
		padding: 0;
		position: absolute;
		left: -12px;
	}
	h2 {
		font-size: 1.2rem;
		font-weight: 600;
	}
`;
