import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import TableEditModal from '../../../Components/Reuseable/_TableEdit/TableEditModal';


const TableEdit = () => {
	const { productQuerys } = Api_Helper();
	const { uid, type } = useParams();
	const [value, setValue] = useState();

	///----------------------
	const getProduct = async () => {
		const Product = await productQuerys(type, uid, 'get');
		setValue(Product.data);
	};
	///-----------------------------
	useEffect(() => {
		getProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type, uid]);
	return (
		<>
			<EditConatiner>
				{value && <TableEditModal Data={value} fetch={getProduct} />}
			</EditConatiner>
		</>
	);
};

export default TableEdit;
const EditConatiner = styled.div`
	width: 100%;
	min-height: calc(100vh - 110px);
	display: grid;
	place-items: center;
	padding: 20px;
	position: relative;
`;
