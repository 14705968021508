export const Menu_Data = [
	{
		id: 1,
		Name: 'Properties',
		link: 'properties',
	},
	{
		id: 2,
		Name: 'TDS',
		link: 'tds',
	},
	{
		id: 3,
		Name: 'MSDS',
		link: 'msds',
	},
	{
		id: 4,
		Name: 'Regulatory',
		link: 'regulatory',
	},
	{
		id: 5,
		Name: 'Availability & Samples',
		link: 'region',
	},
];
