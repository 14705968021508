import React from 'react';
import { Route } from 'react-router-dom';
import CollaborateEdit from '../_COLLABORATE/CollaborateEdit';
import ConnectEdit from '../_CONNECT/ConnectEdit';
import OpportunitiesEdit from '../_OPPORTUNITIES/OpportunitiesEdit';
import ArticleEdit from './ArticleEdit';
import EventEdit from './EventEdit';
import { SocialmediaEdit } from './SocialmediaEdit';
import TestimonialEdit from './TestimonialEdit';
//---------------------------This is the routes for the edit pages routes--------------------
const GeneralEdit = () => {
	return (
		<>
			<Route path='/general/collaborate/edit/:uid' exact>
				<CollaborateEdit />
			</Route>
			<Route path='/general/opportunities/edit/:uid' exact>
				<OpportunitiesEdit />
			</Route>
			<Route path='/general/event/edit/:uid' exact>
				<EventEdit />
			</Route>
			<Route path='/general/article/edit/:uid' exact>
				<ArticleEdit />
			</Route>
			<Route path='/general/testimonial/edit/:uid' exact>
				<TestimonialEdit />
			</Route>
			<Route path='/general/socialmedia/edit/:uid' exact>
				<SocialmediaEdit />
			</Route>
			{/* //---------------------------- */}
			<Route path='/general/enquiry/edit/:uid' exact>
				<ConnectEdit />
			</Route>
			<Route path='/general/tds-request/edit/:uid' exact>
				<ConnectEdit />
			</Route>
			<Route path='/general/msds-request/edit/:uid' exact>
				<ConnectEdit />
			</Route>
			<Route path='/general/sample-request/edit/:uid' exact>
				<ConnectEdit />
			</Route>
			<Route path='/general/contact-us/edit/:uid' exact>
				<ConnectEdit />
			</Route>
			{/* --------------------------------- */}
		</>
	);
};

export default GeneralEdit;
