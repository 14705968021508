import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import AreaEdit from '../../../Components/General/Media/_INPUT/AreaInput';
import MediaInput from '../../../Components/General/Media/_INPUT/MediaInput';
import { FaCamera } from 'react-icons/fa';
import BackButton from '../../../Components/Reuseable/BackButton';
import UpdateButton from '../../../Components/General/Media/MediaReuseable/UpdateButton';
import ResetButton from '../../../Components/General/Media/MediaReuseable/ResetButton';
import Warning from '../../../Components/Reuseable/Warning';
import PopUp from '../../../Components/Reuseable/PopUp';
import Modal from '../../../Components/General/Media/MediaReuseable/Modal';
import { DeleteButton } from '../../../Components/General/Media/MediaReuseable/DeleteButton';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

//----------------------------
const TestimonialEdit = () => {
	const { uid } = useParams();
	const Loction = useLocation();
	const { tag } = queryString.parse(Loction.search);
	const { getQuerys, getUrl, validation } = Api_Helper();
	const imageFile = useRef();
	const [Image, setImage] = useState(null);
	//------------------------------------------------
	const [popUp, setPopUp] = useState('');
	const [waring, setWaring] = useState('');
	const type = ['png', 'jpg', 'webp', 'jpeg'];
	const [data, setData] = useState();
	const [isOpen, setIsOpen] = useState('');
	const { userPermission } = userAuthorization();

	//-------------
	const FormSchema = Yup.object({
		category: Yup.string()
			.max(60, 'Must be 60 characters or less')
			.required('Required'),
		testimony: Yup.string()
			.min(50, 'Must be 50 characters or less')
			.max(300, 'Must be 300 characters or less ')
			.required('Required'),
		attestant: Yup.string()
			.required('Required')
			.max(150, 'Must be 150 charcters or less'),
	});
	//-----------------Image----------------
	const handleChange = (e) => {
		if (
			e.target.files[0] &&
			type.includes(e.target.files[0].type.split('/')[1].toLowerCase())
		) {
			if (e.target.files[0].size / 1024 / 1024 < 5) {
				setImage(e.target.files[0]);
				const src1 = URL.createObjectURL(e.target.files[0]);
				formik.setFieldValue('image', src1);
			} else {
				setPopUp('Image Should Less than 5MB');
			}
		} else {
			setPopUp('Please upload only png ,jpg or jpeg files');
		}
	};
	//------------------------------Formik--------
	const formik = useFormik({
		initialValues: {
			category: '',
			testimony: '',
			attestant: '',
			image: '',
		},
		validationSchema: FormSchema,
		onSubmit: () => {
			updateHandler();
		},
	});
	//---------------------post nomportfolio-------------------
	const nonPortfolioHandler = async (data) => {
		const collection_identifier =
			tag === '1' ? `testimonial/${uid}` : 'testimonial';
		const Product = await getQuerys('post', collection_identifier, data);
		console.log(Product);
		setPopUp('Updated');
		setWaring('');
		imageFile.current.value = '';
		fetchProduct();
	};
	//-------------------------------------
	const fetchProduct = async () => {
		try {
			if (tag === '1') {
				const Query = { query: { u_id: uid } };
				const Details = await getQuerys('patch', 'testimonial', Query);
				const Data = Details.data[0];
				setData(Data);
				formik.resetForm({
					values: {
						category: Data.category,
						testimony: Data.testimony,
						attestant: Data.attestant,
						image: Data.image,
					},
				});
			} else {
				formik.resetForm({
					values: {
						category: '',
						testimony: '',
						attestant: '',
						image: '',
					},
				});
			}
		} catch (e) {
			console.log(e);
		}
	};

	//-----------------update---------------------
	const updateHandler = async () => {
		try {
			if (tag === '1' ? waring : true) {
				if (Image) {
					const name = Image.name;
					await validation(Image, 300, 300);
					const url = await getUrl(Image, name, 'testimonial/');
					const imageLink = url.data.url;
					formik.setFieldValue('image', imageLink);
					const data = { document: { ...formik.values, image: imageLink } };
					nonPortfolioHandler(data);
				} else {
					if (formik.values.image) {
						const data = { document: formik.values };
						nonPortfolioHandler(data);
						fetchProduct();
						setPopUp('Updated');
					} else {
						setPopUp('Image is Not Set');
					}
				}
			} else {
				setPopUp('Nothing to Update');
			}
		} catch (error) {
			console.log(error);
			if (error.response) {
				console.log(error.response.data);
				setPopUp('The Image width height should be 300 X 300');
			} else {
				setPopUp('Sorry Try Again later');
			}
		}
	};
	//-------------------------
	const waringHandler = () => {
		if (
			data.category === formik.values.category &&
			data.testimony === formik.values.testimony &&
			data.attestant === formik.values.attestant &&
			data.image === formik.values.image
		) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
	};

	//--------------------------------------------------
	useEffect(() => {
		fetchProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, tag]);
	//-------------------------
	useEffect(() => {
		data && formik.values && waringHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, formik.values]);
	return (
		<StyledConatiner>
			{isOpen && (
				<Modal
					uid={isOpen}
					type='testimonial'
					state={setIsOpen}
					subName='Testimonial'
				/>
			)}
			{waring && <Warning message={waring} top='10px' right='20px' />}
			{popUp && <PopUp state={[popUp, setPopUp]} />}
			<TestimonialBox>
				<BackButton />
				<TestimonialImage>
					<input
						ref={imageFile}
						type='file'
						id='file'
						name='file'
						accept='image/*'
						disabled={userPermission.disableUpdate}
						required
						onChange={handleChange}
					/>
					<label htmlFor='file'>
						<img
							src={formik.values.image}
							alt='test'
							id='imagePrv'
							onError={(e) => {
								e.target.src = '/Assets/loading.png';
							}}
						/>
						<FaCamera />
					</label>
				</TestimonialImage>
				<TestimonialInputs>
					<AreaEdit
						onChange={formik.handleChange}
						value={formik.values.testimony || ''}
						lable='testimony'
						name='testimony'
						onBlur={formik.handleBlur}
						errorData={formik}
					/>
					<MediaInputSection>
						<MediaInput
							onChange={formik.handleChange}
							value={formik.values.attestant || ''}
							lable='attestant'
							name='attestant'
							onBlur={formik.handleBlur}
							errorData={formik}
							width='350px'
						/>
						<MediaInput
							onChange={formik.handleChange}
							value={formik.values.category || ''}
							lable='category'
							name='category'
							onBlur={formik.handleBlur}
							errorData={formik}
							width='200px'
						/>
					</MediaInputSection>
					<ButtonsBox>
						<div>
							<ResetButton
								onClick={fetchProduct}
								disabled={waring === '' ? true : false}
							/>
							<UpdateButton onClick={formik.handleSubmit} />
						</div>
						{tag === '1' && <DeleteButton onClick={() => setIsOpen(uid)} />}
					</ButtonsBox>
				</TestimonialInputs>
			</TestimonialBox>
		</StyledConatiner>
	);
};

export default TestimonialEdit;

const StyledConatiner = styled.div`
	width: 100%;
	min-height: calc(100vh - 110px);
	display: grid;
	place-items: center;
	position: relative;
`;
const TestimonialBox = styled.div`
	position: relative;
	width: 100%;
	max-width: 850px;
	height: 330px;
	background-color: #f6f6f6;
	padding: 40px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	display: flex;
	align-items: center;
`;
const TestimonialImage = styled.div`
	width: 30%;
	height: 100%;
	display: grid;
	place-items: center;
	position: relative;
	margin: 10px 20px 10px 0;
	img {
		width: 180px;
		height: 180px;
		border-radius: 10px;
		object-fit: cover;
	}
	input {
		display: none;
	}
	label {
		position: absolute;
		top: 20px;
		left: 0;
		transform: translate(-50%-50%);
		width: 180px;
		height: 180px;
		color: #ffb511;
		display: grid;
		place-items: center;
		font-size: 2rem;
		background-color: #00000030;
		&:hover {
			background-color: #00000060;
			cursor: pointer;
		}
	}
`;
const TestimonialInputs = styled.div`
	width: 100%;
	height: 100%;
`;
const MediaInputSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const ButtonsBox = styled.div`
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
