import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { MdKeyboardArrowDown } from 'react-icons/md';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useEffect } from 'react';
import CountUp from 'react-countup';
import TableChildRow from './TableChildRow';
const TableRowBox = ({ Id, data }) => {
	const [open, IsOpen] = useState(false);
	const [childOpen, IsChildOpen] = useState(false);
	const [child, setChild] = useState('');

	const { getQuerys } = Api_Helper();
	const [Data, setData] = useState();
	//----------------------------------
	const fetechData = async () => {
		try {
			setData();
			const queryData = { query: { family_id: data.family_id } };
			const ProductData = await getQuerys('patch', 'product', queryData);
			setData(ProductData.data.length);
			const SystemData = await getQuerys('patch', 'system', queryData);
			setData((state) => state + SystemData.data.length);
		} catch (e) {
			console.log(e);
		}
	};
	useEffect(() => {
		data && fetechData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data]);
	//----------------------------------------styles--------------------------
	return (
		<MainContainer
			Height={data.category.length}
			ChildHeight={child}>
			<TableRow>
				<RowItem>{Id + 1}</RowItem>
				<RowItem>{data.name}</RowItem>
				<RowItem>
					{data.category.length}
					<Icons onClick={() => IsOpen(!open)}>
						<MdKeyboardArrowDown />
					</Icons>
				</RowItem>
				<RowItem>
					<CountUp end={Data && Data} duration={2.75} />
				</RowItem>
			</TableRow>
			<TableDetails className={open ? 'Active' : 'close'} open={open}>
				<SubList >
					{data.category.map((item, idx) => {
						return (
							<TableChildRow
								key={idx}
								Item={ item }
								id={idx}
								State={[childOpen, IsChildOpen, setChild]}
							/>
						);
					})}
				</SubList>
			</TableDetails>
		</MainContainer>
	);
};

export default TableRowBox;
const MainContainer = styled.div`
	width: 100%;
	overflow: hidden;
	.close {
		height: 0;
	}
	.Active {
		height: ${({ Height, ChildHeight }) => Height * 32 }px;
	}
`;
const TableRow = styled.div`
	width: 100%;
	height: 40px;
	background-color: #ffffff;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	display: grid;
	grid-template-columns: 1fr 1.4fr 2fr 1fr;
	place-items: center;
	margin-bottom: 5px;
	z-index: 3;
`;

const RowItem = styled.div`
	width: 80%;
	text-align: left;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 0.8rem;
	display: flex;
	align-items: center;
`;
const Icons = styled.div`
	font-size: 1.2rem;
	display: grid;
	place-items: center;
	margin-left: 10px;
`;
const TableDetails = styled.div`
	width: 100%;
	height: 200px;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	background-color: #e4e3e3;
	z-index: 0;
	transition: all 1s ease;
`;
const SubList = styled.div`
	padding: 0 10px;
	
`;
