import { Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { MdDone } from 'react-icons/md';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
import { useData } from '../../../Context/DataProvider';
import Warning from '../../Reuseable/Warning';

const Msds = ({ Formik }) => {
	const [waring, setWaring] = useState('');
	const [istrue, setIstrue] = useState(
		Formik.values.msds_link[0].is_downloadable !== true ? true : false,
	);
	const { productQuerys, getUrl } = Api_Helper();
	const { uid, type } = useParams();
	const { productData, setPopUp, setError } = useData();
	const Product = JSON.parse(productData);
	const { userPermission } = userAuthorization();
	const [load, setLoad] = useState(false);
	const ProductData = JSON.parse(productData);

	//---------------------
	const [File, setFile] = useState(null);
	const fileRef = useRef();
	const Filetype = ['pdf'];
	//-----------------File----------------
	const handleChange = (e) => {
		if (
			e.target.files[0] &&
			Filetype.includes(e.target.files[0].type.split('/')[1].toLowerCase())
		) {
			if (e.target.files[0].size / 1024 / 1024 < 2) {
				setFile(e.target.files[0]);
				setWaring('File Added ');
				fileUpload(e.target.files[0]);
			} else {
				setPopUp('File Should Less than 2MB');
			}
		} else {
			setPopUp('Please upload only PDF Format');
		}
	};
	//-----------------------
	const fileUpload = async (FileData) => {
		try {
			setLoad(true);
			const name = FileData.name;
			const Path = `portfolio/${
				type === 'system' ? 'by_application' : 'by_chemistry'
			}/${
				type === 'system'
					? ProductData.table_reference[0]
					: ProductData.table_reference
			}/${
				type === 'product'
					? ProductData.product_name.replaceAll(' ', '_')
					: ProductData.system_name.replaceAll(' ', '_')
			}/`;

			const url = await getUrl(FileData, name, Path);
			const msds_link = Formik.values.msds_link;
			msds_link[0].url = url.data.url;
			Formik.setFieldValue('msds_link', msds_link);
			const msds = {
				msds_link: [{ ...Formik.values.tds_link[0], url: url.data.url }],
			};
			const Products = await productQuerys(type, uid, 'post', msds);
			console.log(Products);
			setPopUp('UpLoaded');
			setError('');
			fileRef.current.value = '';
			setWaring('');
			setFile('');
			setLoad(false);

		} catch (error) {
			console.log(error);
			setLoad(false);
		}
	};
	//----------------
	const handleIs_downloadable = () => {
		const msds = Formik.values.msds_link;
		setIstrue(!istrue);
		msds[0].is_downloadable = istrue ? true : 'form';
		Formik.setFieldValue('msds_link', msds);
	};
	//---------------------
	const waringHandler = () => {
		if (
			Product.msds_link[0].is_downloadable ===
			Formik.values.msds_link[0].is_downloadable
		) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
	};
	//----------------------------
	useEffect(() => {
		Product && Formik.values && waringHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Formik.values, Product]);
	return (
		<MsdsBox>
			{waring && <Warning message={waring} />}
			<DownLoad>
				<Info>Downloadable :</Info>
				<CheckboxS>
					<FormControlLabel
						value='start'
						control={
							<Checkbox
								color='primary'
								checked={Formik.values.msds_link[0].is_downloadable === true}
								lable='is_downloadable'
								name='is_downloadable'
								disabled={userPermission.disableUpdate}
								onChange={() => handleIs_downloadable()}
							/>
						}
						label={<InfoTag>Yes</InfoTag>}
						labelPlacement='start'
					/>
					<FormControlLabel
						value='start'
						control={
							<Checkbox
								color='secondary'
								checked={Formik.values.msds_link[0].is_downloadable !== true}
								lable='is_downloadable'
								name='is_downloadable'
								disabled={userPermission.disableUpdate}
								onChange={() => handleIs_downloadable()}
							/>
						}
						label={<InfoTag>No</InfoTag>}
						labelPlacement='start'
					/>
				</CheckboxS>
			</DownLoad>
			<DropFile
				IsActive={
					userPermission.disableUpdate
						? 'NotActive'
						: Formik.values.msds_link[0].is_downloadable === true
						? 'Active'
						: 'NotActive'
				}>
				<label htmlFor='fileInput'>
					<div>
						{load && <Done/>}
						<h6>{File && File.name}</h6>
						<img src='/Assets/pdf.svg' alt='pdf' />
						<p>{'(Upto 2MB only)'}</p>
					</div>
				</label>
				<input
					ref={fileRef}
					type='file'
					id='fileInput'
					onChange={handleChange}
					name='image'
					required
					disabled={
						userPermission.disableUpdate ||
						(Formik.values.msds_link[0].is_downloadable === true ? false : true)
					}
				/>
				<Name>
					<h4>Click to Upload Lastest File</h4>
					<ButtonZone htmlFor='fileInput'>
						<Button>Upload</Button>
					</ButtonZone>
				</Name>
			</DropFile>
		</MsdsBox>
	);
};

export default Msds;
const MsdsBox = styled.div`
	height: 340px;
	width: 600px;
	padding: 20px;
	margin: 40px;
	display: flex;
	align-items: center;
	border: 4px dotted #39a9cb;
	position: relative;
`;
const Info = styled.p`
	font-weight: 500;
	font-size: 0.8rem;
	text-align: center;
`;
const InfoTag = styled.p`
	font-weight: 500;
	font-size: 0.65rem;
`;
const DownLoad = styled.div`
	width: 200px;
	display: flex;
	align-items: center;
	flex-direction: column;
`;
const DropFile = styled.div`
	filter: ${({ IsActive }) =>
		IsActive === 'Active' ? 'grayscale(0)' : 'grayscale(1)'};
	label {
		div {
			width: 100%;
			height: 140px;
			position: relative;
			cursor: pointer;
			border-radius: 5px;
		}
		p {
			letter-spacing: 1px;
			font-size: 0.7rem;
			color: #9c9c9c;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, -50%);
			text-transform: capitalize;
			white-space: nowrap;
		}
		img {
			width: 90px;
			object-fit: contain;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
		h6 {
			letter-spacing: 1px;
			font-size: 0.7rem;
			color: #9c9c9c;
			position: absolute;
			top: 10px;
			left: 50%;
			font-weight: 400;
			transform: translate(-50%, -50%);
			text-transform: capitalize;
			white-space: nowrap;
		}
	}
	input[type='file'] {
		opacity: 0;
		pointer-events: none;
	}
`;
const Done = styled(MdDone)`
	font-size: 3rem;
	transform: translate(-50%, -50%);
	color: #11ff7c;
	position: absolute;
	top: -10%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
const Name = styled.div`
	font-size: 0.65rem;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	height: 80px;
	h4 {
		font-weight: 500;
		font-size: 0.9rem;
	}
`;
const CheckboxS = styled.div`
	display: flex;
`;
const ButtonZone = styled.label``;
const Button = styled.h2`
	width: 80px;
	height: 30px;
	font-size: 0.8rem;
	font-weight: 500;
	text-transform: uppercase;
	background-color: #3c4baf;
	display: grid;
	place-items: center;
	border-radius: 5px;
	color: #fff;
	padding: 5px;
	cursor: pointer;
`;
