import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useData } from '../../../Context/DataProvider';
import Warning from '../../Reuseable/Warning';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

const Regulatory = ({ Formik }) => {
	const [value, setValue] = useState();
	const { productData } = useData();
	const Product = JSON.parse(productData);
	const Newproduct = Product.regulatory ? Product.regulatory : [''];
	const [waring, setWaring] = useState('');
	const { userPermission } = userAuthorization();

	//---------------------
	const handlerRegulatory = (e, Name, idx) => {
		if (e) {
			value.push(Name);
			const newArr = value.filter((item) => {
				return item!==''
			})
			Formik.setFieldValue('regulatory', newArr);
		} else {
			// value[idx] = '';
			const newArr = value.filter((item) => {
				return item !== Name;
			});
			setValue(newArr);
			Formik.setFieldValue('regulatory', newArr);
		}
	};
	//--------------------
	useMemo(() => {
		if (JSON.stringify(value) === JSON.stringify(Newproduct)) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(value), Formik.values.regulatory]);
	//--------------------
	useEffect(() => {
		setValue(Formik.values.regulatory ? Formik.values.regulatory : ['']);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Formik.values.regulatory]);
	return (
		<RegulatoryBox>
			{waring && <Warning message={waring} />}
			<RegulatoryBody>
				<FormControlLabel
					value='start'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('EU-REACH') : false}
							lable='EU-REACH'
							name='EU-REACH'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'EU-REACH', 0)
							}
						/>
					}
					label={<Name>EU-REACH</Name>}
					labelPlacement='top'
				/>
				<FormControlLabel
					value='starts'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('TSCA (US)') : false}
							lable='TSCA (US)'
							name='TSCA (US)'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'TSCA (US)', 1)
							}
						/>
					}
					label={<Name>TSCA (US)</Name>}
					labelPlacement='top'
				/>
				<FormControlLabel
					value='start'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('DSL (Canada)') : false}
							lable='DSL (Canada)'
							name='DSL (Canada)'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'DSL (Canada)', 2)
							}
						/>
					}
					label={<Name>DSL (Canada)</Name>}
					labelPlacement='top'
				/>
				<FormControlLabel
					value='start'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('Korea-REACH') : false}
							lable='Korea-REACH'
							name='Korea-REACH'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'Korea-REACH', 3)
							}
						/>
					}
					label={<Name>Korea-REACH</Name>}
					labelPlacement='top'
				/>
				<FormControlLabel
					value='start'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('Australia-NICNAS') : false}
							lable='Australia-NICNAS'
							name='Australia-NICNAS'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'Australia-NICNAS', 4)
							}
						/>
					}
					label={<Name>Australia-NICNAS</Name>}
					labelPlacement='top'
				/>
				<FormControlLabel
					value='start'
					control={
						<Checkbox
							color='primary'
							checked={value ? value.includes('China-REACH') : false}
							lable='China-REACH'
							name='China-REACH'
							disabled={userPermission.disableUpdate}
							onChange={(e) =>
								handlerRegulatory(e.target.checked, 'China-REACH', 5)
							}
						/>
					}
					label={<Name>China-REACH</Name>}
					labelPlacement='top'
				/>
			</RegulatoryBody>
		</RegulatoryBox>
	);
};

export default Regulatory;
const RegulatoryBox = styled.div`
	height: 200px;
	width: 800px;
	padding: 20px;
	margin: 40px;
	display: grid;
	place-items: center;
	border: 4px dotted #39a9cb;
	position: relative;
`;

const RegulatoryBody = styled.div`
	position: relative;
`;
const Name = styled.p`
	font-size: 0.8rem;
	font-weight: 500;
`;
