import { Switch, Route, Redirect } from 'react-router-dom';
import TableEdit from './Pages/_pages/_PORTFOLIO_BRANDS/TableEdit';
import Home from './Pages/Main_Pages/Home';
import Login from './Pages/Main_Pages/Login';
import { Map } from './Pages/Main_Pages/Map';
import Product from './Pages/Main_Pages/Product';
import ProtectedRoute from './ProtectedRoute';
import Portfolio_Table from './Pages/Main_Pages/Portfolio_Table';
import Brands from './Pages/Main_Pages/Brands';
import General from './Pages/Main_Pages/General';
import GeneralEdit from './Pages/_pages/_GENERAL_Media/GeneralEdit';
import Analytics_SubPages from './Pages/Sub_Pages/Analytics_SubPages';
import Profile from './Pages/Main_Pages/Profile';
function App() {
	return (
		<>
			<Switch>
				<Route path='/login' component={Login} />
				<ProtectedRoute exact path='/map' Component={Map} />
				<ProtectedRoute exact path='/edit/:type/:uid' Component={TableEdit} />
				<ProtectedRoute
					exact
					path='/type/:type/:uid/:subTab'
					Component={Product}
				/>
				<ProtectedRoute
					exact
					path='/portfolio/:subCategory/:subName'
					Component={Portfolio_Table}
				/>
				<ProtectedRoute exact path='/brands/:subName' Component={Brands} />
				<ProtectedRoute
					exact
					path='/general/:subCategory/:subName'
					Component={General}
				/>
				<ProtectedRoute exact path='/profile' Component={Profile} />
				{/* -------------------------------------------------------------- */}
				<ProtectedRoute
					exact
					path='/analytics/:page'
					Component={Analytics_SubPages}
				/>
				{/* //----Event Edit is in the _pages folder its a sub component on media- */}
				<ProtectedRoute
					exact
					path='/general/:subName/edit/:uid'
					Component={GeneralEdit}
				/>
				{/* ///------------------------------------------------ */}
				<ProtectedRoute path='/:mainCategory' Component={Home} exact />
				<Route path='*'>
					<Redirect to='/analytics' />
				</Route>
			</Switch>
		</>
	);
}

export default App;
