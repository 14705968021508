/* eslint-disable array-callback-return */
import { useState } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import styled from 'styled-components';
import { concatedArr } from '../../Components/worldMapComponent/TestData';
import { Markers } from './ProductWorldMap';

const HomeWorldMap = () => {
	let test = ['d1', 'm1', 'a1'];
	const [checkList, setCheckList] = useState(test);
	const geoUrl =
		'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

	const showToolTip = (evt, name) => {
		let tooltip = document.getElementById('tooltip');
		tooltip.innerHTML = name;
		tooltip.style.display = 'block';
		tooltip.style.left = evt.pageX + 10 + 'px';
		tooltip.style.top = evt.pageY + -30 + 'px';
	};
	const hideToolTip = () => {
		let tooltip = document.getElementById('tooltip');
		tooltip.style.display = 'none';
	};
	const filteredArr = concatedArr.filter((item) => {
		return checkList.includes(item.id);
	});

	const changeHandler = (e) => {
		if (e.target.checked === true) {
			setCheckList((prev) => {
				return [...prev, e.target.value];
			});
		} else {
			setCheckList((prevWorks) => {
				const updateWork = [...prevWorks];
				const updateList = updateWork.filter((value) => {
					return value !== e.target.value;
				});
				return updateList;
			});
		}
	};

	return (
		<>
			<MapContainer>
				<div id='tooltip'></div>
				<CheckBoxHolder>
					<input
						type='checkbox'
						id='manufacturing-plants'
						name='manufacturing-plants'
						value='m1'
						defaultChecked='true'
						onChange={changeHandler}
					/>
					<label htmlFor='manufacturing-plants'>Manufacturing plants</label>
					<input
						type='checkbox'
						id='Distrubated-regions'
						name='Distrubated-regions'
						value='d1'
						defaultChecked='true'
						onChange={changeHandler}
					/>
					<label htmlFor='Distrubated-regions'>Distributor office</label>

					<input
						type='checkbox'
						id='sales-regions'
						name='sales-regions'
						value='a1'
						defaultChecked='true'
						onChange={changeHandler}
					/>
					<label htmlFor='sales-regions'>Sales regions</label>
				</CheckBoxHolder>
				<ComposableMap
					projection={'geoEquirectangular'}
					width={1050}
					height={500}>
					<Geographies geography={geoUrl}>
						{({ geographies }) =>
							geographies.map((geo) => {
								const d = filteredArr.find((s) => {
									return s.alpha3 === geo.properties.ISO_A3 && s.id === 'a1';
								});

								return (
									<Geography
										key={geo.rsmKey}
										geography={geo}
										onMouseOver={(evt) => {
											d && showToolTip(evt, d.region);
										}}
										onMouseOut={hideToolTip}
										fill={d ? '#f1bc36' : '#D5DBDB'}
									/>
								);
							})
						}
					</Geographies>

					{filteredArr.map((item, i) => {
						if (item.id !== 'a1') {
							return (
								<Markers
									color={item.id === 'd1' ? '#2ca507' : '#54AACA'}
									key={i}
									coordinates={[item.longitude, item.latitude]}>
									<circle
										r='14'
										fill='transparent'
										stroke='transparent'
										className='outter_circel'
										strokeWidth='0.5'></circle>
									<circle
										r='6'
										stroke='black'
										strokeWidth='0.5'
										className='inner_circel'
										onMouseOver={(evt) => showToolTip(evt, item.country)}
										onMouseOut={hideToolTip}
									/>
								</Markers>
							);
						}
					})}
				</ComposableMap>
			</MapContainer>
		</>
	);
};

const MapContainer = styled.div`
	width: 75%;
	height: 90%;
	margin: 0 0 80px 120px;
`;

const CheckBoxHolder = styled.form`
	position: absolute;
	bottom: 2%;
	left: 0%;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	width: 50%;
	height: 50px;
	user-select: none;
`;

export default HomeWorldMap;
