import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import storage from 'local-storage-fallback';
import { useHistory } from 'react-router-dom';
const Login = () => {
	const { getToken } = Api_Helper();
	const [isError, setIsError] = useState(false);
	const history = useHistory();
	const name = useRef();
	const password = useRef();
	const tokenHandler = async (e) => {
		e.preventDefault();
		const Name = name.current.value;
		const Password = password.current.value;
		setIsError(false);
		try {
			const response = await getToken(Name, Password);
			name.current.value = '';
			password.current.value = '';
			storage.setItem('ProfileData', JSON.stringify(response.data));
			storage.setItem('Token', response.data.token);
			storage.setItem('Authority', response.data.groups[0]);
			history.push('/analytics');
		} catch (error) {
			console.log('yo error');
			setIsError(true);
		}
	};
	return (
		<LoginConatiner>
			<Form onSubmit={tokenHandler}>
				<Header>
					<img src='/Assets/logo.jpg' alt='logo' />
					<h3>Admin Console</h3>
				</Header>
				<Inputs message={isError}>
					<input ref={name} type='text' placeholder='User Name' required />
					<input
						ref={password}
						type='password'
						placeholder='Password'
						required
						autoComplete='true'
					/>
					<button type='submit'>Login</button>
				</Inputs>
			</Form>
		</LoginConatiner>
	);
};

export default Login;
const LoginConatiner = styled.div`
	width: 100%;
	height: 100vh;
	display: grid;
	place-items: center;
`;
const Form = styled.form`
	width: 350px;
	height: 420px;
	border-radius: 10px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`;
const Header = styled.div`
	width: 100%;
	height: 150px;
	background: rgb(255, 207, 101);
	background: linear-gradient(221deg, rgba(255, 207, 101, 1) 20%, #ffb511 44%);
	position: relative;
	display: grid;
	place-items: center;
	img {
		position: absolute;
		width: 120px;
		object-fit: contain;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	h3 {
		color: #333333;
		font-size: 1.4rem;
		margin-top: 20px;
	}
`;
const Inputs = styled.div`
	height: 220px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	input {
		width: 80%;
		height: 40px;
		border: 1px solid ${({ message }) => (message ? 'red' : '#d3d3d3')};
		outline: none;
		border-radius: 5px;
		padding: 5px;
		font-size: 1rem;
	}
	button {
		width: 150px;
		height: 30px;
		outline: none;
		background-color: #00a9c9;
		border: none;
		font-size: 0.9rem;
		font-weight: 500;
		cursor: pointer;
		color: #ffffff;
	}
`;
