import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import TableConatiner from '../../Components/PortfolioComponent/Table/TableConatiner';
import queryString from 'query-string';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import HomeHeader from '../../Components/HomeComponent/Header/HomeHeader';
const Portfolio_Table = () => {
	const Loction = useLocation();
	const query = Loction.pathname.split('/')[2];
	const { type, flag, tid, family, id } = queryString.parse(Loction.search);
	const { getTableDeatils, fetchData } = Api_Helper();
	//--------------------------------
	const Data = (type, flag, tid, family, id, query) => {
		if (flag === '0') {
			getTableDeatils(tid, type);
		} else {
			fetchData(type, query, family, id);
		}
	};
	//--------------------------------

	useEffect(() => {
		Data(type, flag, tid, family, id, query);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<PortfolioBody>
			<DashBoardContainer>
				<HomeHeader />
				<TableConatiner />
			</DashBoardContainer>
		</PortfolioBody>
	);
};

export default Portfolio_Table;
const PortfolioBody = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow: hidden;
`;
const DashBoardContainer = styled.section`
	width: 100%;
	height: 100%;
	margin-bottom: 40px;
`;
