import React from 'react';
import PropTypes from 'prop-types';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';
import { Portfolio_Data } from './Data/Portfolio_Data';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import { IoAddCircleSharp } from 'react-icons/io5';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsFileText } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import { Analytics_Data } from './Data/Analytics_Data';
import { General_Data } from './Data/General_Data';
import { useData } from '../../Context/DataProvider';
function TransitionComponent(props) {
	const style = useSpring({
		from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
		to: {
			opacity: props.in ? 1 : 0,
			transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
		},
	});

	return (
		<animated.div style={style}>
			<Collapse {...props} />
		</animated.div>
	);
}

TransitionComponent.propTypes = {
	/**
	 * Show the component; triggers the enter or exit states
	 */
	in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
	iconContainer: {
		'& .close': {
			opacity: 0.3,
		},
	},
	group: {
		marginLeft: 7,
		paddingLeft: 18,
		borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.5)}`,
	},
}))((props) => (
	<TreeItem {...props} TransitionComponent={TransitionComponent} />
));

const useStyles = makeStyles({
	root: {
		height: '100%',
		flexGrow: 1,
		maxWidth: 350,
	},
});

export default function TreeViewContainer() {
	//-----------------

	const { fetchData, getTableDeatils } = Api_Helper();
	const { setTableData, setTitle } = useData();
	//------
	const classes = useStyles();
	//-----------------------------
	const dataHandler = (type, query, id, tid, flag, subId, subName) => {
		if (!flag) {
			getTableDeatils(tid, type);
			setTableData((state) => ({
				...state,
				tableNavData: '',
			}));
		} else {
			fetchData(type, query, id, subId);
		}
	};

	//-------------------------------
	const HeaderTitleHandler = (title, subtitle) => {
		setTitle(() => ({
			title: title,
			subtitle: subtitle,
		}));
	};
	//--------------------------------
	return (
		<>
			<Tree
				className={classes.root}
				defaultExpanded={['0']}
				defaultCollapseIcon={<Minus />}
				defaultExpandIcon={<Add />}
				defaultEndIcon={<File />}>
				{/* //----------------------------------------------------------------// */}
				<TreeChild
					nodeId='0'
					label={
						<TreeMenuItem to='/analytics'>
							<h3>Analytics</h3>
						</TreeMenuItem>
					}>
					{Analytics_Data.map((data) => {
						return (
							<TreeChild
								key={data.id}
								nodeId={data.id + '0.5'}
								label={
									<MainCatogerys
										to={`/analytics/${data.link}`}
										onClick={() => HeaderTitleHandler('Analytics', data.name)}>
										<MainText>{data.name}</MainText>
									</MainCatogerys>
								}></TreeChild>
						);
					})}
				</TreeChild>
				{/* //--------------------------------------------------------------------// */}
				<TreeChild
					nodeId='0.1'
					label={
						<TreeMenuItem to='/portfolio?subname=chemistry'>
							<h3>Portfolio</h3>
						</TreeMenuItem>
					}>
					{Portfolio_Data.map((data, idx) => {
						return (
							<TreeChild
								key={idx}
								nodeId={`${data.id}`}
								label={<MainCatogery>{data.catogery}</MainCatogery>}>
								{data.flag && data.mainCatogery.length > 0
									? data.mainCatogery.map((item, idx) => {
											return (
												<TreeChild
													key={idx}
													nodeId={`${item.id}`}
													label={
														data.catogery === 'Brands' ? (
															<NavLinks to={`/${data.query}/${item.type}`}>
																<Main>{item.name}</Main>
															</NavLinks>
														) : (
															<Main>{item.name}</Main>
														)
													}>
													{item.subCatogery &&
														item.subCatogery.length > 0 &&
														item.subCatogery.map((subItem, subId) => {
															return (
																<NavLinks
																	to={`/portfolio/${data.query}/${
																		item.name
																	}?flag=${subItem.childFlag}&tid=${
																		subItem.tid || '000'
																	}&type=${item.type}&family=${
																		item.family_id
																	}&id=${subId}`}
																	key={subItem.id}>
																	<TreeChild
																		nodeId={subItem.id}
																		label={
																			<Tool
																				arrow
																				TransitionComponent={Zoom}
																				title={subItem.name}
																				placement='top'>
																				<SubItem>{subItem.name}</SubItem>
																			</Tool>
																		}
																		onClick={() =>
																			dataHandler(
																				item.type,
																				data.query,
																				item.family_id,
																				subItem.tid,
																				subItem.childFlag,
																				subId,
																				subItem.name,
																			)
																		}></TreeChild>
																</NavLinks>
															);
														})}
												</TreeChild>
											);
									  })
									: ''}
							</TreeChild>
						);
					})}
				</TreeChild>
				{/* //----------------------------------------------------------------------// */}
				<TreeChild
					nodeId='0.2'
					label={
						<TreeMenuItem to='/general'>
							<h3>General</h3>
						</TreeMenuItem>
					}>
					{General_Data.map((data, idx) => {
						return (
							<TreeChild
								key={idx + 0}
								nodeId={data.id + 'general'}
								label={<MainCatogery>{data.name}</MainCatogery>}>
								{data.flag && data.mainCatogery.length > 0
									? data.mainCatogery.map((item, idx) => {
											return (
												<TreeChild
													key={idx + 1}
													nodeId={item.id + data.name}
													label={
														<MainGeneral
															to={`/general/${data.name}/${item.link}`}
															onClick={() =>
																HeaderTitleHandler(data.name, item.name)
															}>
															<MainText>{item.name}</MainText>
														</MainGeneral>
													}>
													{item.subCatogery &&
														item.subCatogery.length > 0 &&
														item.subCatogery.map((subItem, idx) => {
															return (
																<TreeChild
																	key={idx + 2}
																	nodeId={subItem.id + data.name}
																	label={
																		<Tool
																			arrow
																			TransitionComponent={Zoom}
																			title={subItem.name}
																			placement='top'>
																			<SubItem>{subItem.name}</SubItem>
																		</Tool>
																	}></TreeChild>
															);
														})}
												</TreeChild>
											);
									  })
									: ''}
							</TreeChild>
						);
					})}
				</TreeChild>
				{/* //----------------------------------------------------------------------// */}
			</Tree>
		</>
	);
}
const MainText = styled.div``;
const TreeMenuItem = styled(Link)`
	text-decoration: none;
	color: #000000;
	h3 {
		font-size: 1.1rem;
	}
`;
const Tree = styled(TreeView)`
	width: 100%;
	& .MuiTreeItem-iconContainer {
		width: 22px;
		align-items: center;
	}
	& .MuiTreeItem-iconContainer svg {
		font-size: 22px;
	}
`;
const NavLinks = styled(Link)`
	text-decoration: none;
	color: #333333;
	width: 100%;
`;
const TreeChild = styled(StyledTreeItem)``;
const Add = styled(IoAddCircleSharp)`
	font-size: 5rem;
	color: #39a9cb;
`;
const Minus = styled(AiFillMinusCircle)`
	color: #39a9cb;
`;
const File = styled(BsFileText)`
	font-size: 18px;
	color: #39a9cb;
`;
const MainCatogery = styled.p`
	font-size: 0.9rem;
	text-decoration: none;
	font-weight: 550;
	text-transform: capitalize;
	color: #333333;
`;
const MainCatogerys = styled(Link)`
	font-size: 0.9rem;
	text-decoration: none;
	font-weight: 550;
	text-transform: capitalize;
	color: #333333;
`;
const Main = styled.p`
	font-size: 0.8rem;
	font-weight: 500;
	overflow: hidden;
	text-transform: capitalize;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 80%;
	text-decoration: none;
`;
const MainGeneral = styled(Link)`
	font-size: 0.8rem;
	font-weight: 500;
	overflow: hidden;
	text-transform: capitalize;
	text-overflow: ellipsis;
	white-space: nowrap;
	text-decoration: none;
	color: #333333;
`;
const SubItem = styled.p`
	font-size: 0.7rem;
	font-weight: 400;
	white-space: nowrap;
	overflow: hidden;
	width: 160px;
	text-transform: capitalize;
	text-overflow: ellipsis;
`;
const Tool = styled(Tooltip)``;
