/* eslint-disable jsx-a11y/anchor-is-valid */
import {
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { FaFileDownload } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import TextZone from '../../../Components/General/Collaborate/TextZone';
import BackButton from '../../../Components/Reuseable/BackButton';
import UpdateButton from '../../../Components/General/Media/MediaReuseable/UpdateButton';
import PopUp from '../../../Components/Reuseable/PopUp';
import { AiOutlineFileExcel } from 'react-icons/ai';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

const CollaborateEdit = () => {
	const { uid } = useParams();
	const [Data, setData] = useState('');
	const [formData, setFormData] = useState('');
	const [value, setValue] = useState('');
	const [popUp, setPopUp] = useState('');
	const { userPermission } = userAuthorization();

	//--------------------------------
	const collection_identifier = `forms/${uid}`;
	const data = '';
	const { getQuerys } = Api_Helper();
	const newArr = [];
	const Text = [
		'__v',
		'uid',
		'fileAttachment',
		'updatedAt',
		'formType',
		'formSubType',
	];

	//------------------------------------
	const getDetails = async () => {
		try {
			const nonPortfolioData = await getQuerys(
				'get',
				collection_identifier,
				data,
			);
			setValue(nonPortfolioData.data.communication_status || 'New');
			setFormData(nonPortfolioData.data);
			Object.keys(nonPortfolioData.data).forEach((item) => {
				!Text.includes(item) &&
					newArr.push({ item, value: nonPortfolioData.data[item] });
			});
			setData(newArr);
		} catch (e) {
			console.log(e);
		}
	};
	//-----------------------update----
	const updateHandler = async () => {
		const collection_identifier = `forms/${uid}`;
		try {
			const data = { document: { communication_status: value } };
			const Product = await getQuerys('post', collection_identifier, data);
			console.log(Product);
			setPopUp('Updated');
		} catch (e) {
			console.log(e);
		}
	};

	//-----------------------------------
	useEffect(() => {
		uid && getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid]);
	return (
		<Styledcontanier>
			<CollaborateBox>
				<BackButton />
				{popUp && <PopUp state={[popUp, setPopUp]} />}
				<Title>{formData && formData.formSubType}</Title>
				<Details>
					{Data &&
						Data.map((item, idx) => {
							return (
								<TextZone
									key={idx}
									width='350px'
									label={item.item}
									value={item.value}
								/>
							);
						})}
				</Details>
				<File>
					<DropBox>
						<FormControl component='fieldset'>
							<TextLabel>Communication status :</TextLabel>
							<RadioGroup
								row
								aria-label='gender'
								name='controlled-radio-buttons-group'
								value={value}
								onChange={(e) => setValue(e.target.value)}>
								<FormControlLabel
									value='New'
									control={
										<Radio
											size='small'
											color='primary'
											disabled={userPermission.disableUpdate}
										/>
									}
									label={<p>New</p>}
								/>
								<FormControlLabel
									value='Open'
									control={
										<Radio
											size='small'
											color='primary'
											disabled={userPermission.disableUpdate}
										/>
									}
									label={<p>Open</p>}
								/>
								<FormControlLabel
									value='Close'
									control={
										<Radio
											size='small'
											color='primary'
											disabled={userPermission.disableUpdate}
										/>
									}
									label={<p>Close</p>}
								/>
							</RadioGroup>
						</FormControl>
					</DropBox>
					{formData && formData.fileAttachment ? (
						<a
							href={
								formData && formData.fileAttachment
									? formData.fileAttachment
									: ''
							}
							download>
							<FaFileDownload />
						</a>
					) : (
						// eslint-disable-next-line jsx-a11y/anchor-has-content
						<a aria-disabled>
							{' '}
							<AiOutlineFileExcel />
						</a>
					)}
				</File>
				<Button>
					<UpdateButton onClick={updateHandler} />
				</Button>
			</CollaborateBox>
		</Styledcontanier>
	);
};

export default CollaborateEdit;
const TextLabel = styled.h3`
	font-size: 0.7rem;
	font-weight: 600;
	margin: 0 10px;
	text-transform: capitalize;
`;
const Button = styled.div`
	width: 100%;
	display: grid;
	place-items: center;
	margin-top: 20px;
`;
const Styledcontanier = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	place-items: center;
	padding: 30px;
	margin-bottom: 40px;
`;
const Title = styled.h3`
	text-align: center;
	text-transform: capitalize;
	position: relative;
	height: 50px;
	&::after {
		content: '';
		width: 50px;
		height: 3px;
		background-color: #ffb511;
		position: absolute;
		bottom: 15px;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;
const CollaborateBox = styled.div`
	width: 80%;
	min-height: 500px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	padding: 30px;
	position: relative;
`;
const Details = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;
const File = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	width: 100%;
	a {
		width: 45px;
		height: 45px;
		background-color: #303f9f;
		color: #fff;
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-decoration: none;
		border-radius: 50%;
		a:hover {
			box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
				rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
		}
	}
`;
const DropBox = styled.div`
	p {
		font-size: 0.9rem;
	}
`;
