import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import FeaturesInput from './FeaturesInput';
import Button from '@material-ui/core/Button';
import Warning from '../../Reuseable/Warning';
import { MdAdd } from 'react-icons/md';
import { useData } from '../../../Context/DataProvider';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
const Features = ({ Data }) => {
	const { productData } = useData();
	const Product = JSON.parse(productData);

	//----------------------------------------------
	const [value, setValue] = useState('');
	const [waring, setWaring] = useState('');
	const { userPermission } = userAuthorization();

	//---------------------------
	const handleFeatures = (idx, value) => {
		const Description = Data.values.description;
		Description[idx] = value;
		Data.setFieldValue('description', Description);
	};
	//-------------------------
	const addForms = () => {
		const feilds = [...value];
		feilds.push('');
		setValue(feilds);
		Data.setFieldValue('description', feilds);
		var element = document.querySelector('.parent');
		element.scrollTop = element.scrollHeight;
	};
	///-----------------------
	const removeFroms = (name) => {
		const feilds = [...value];
		var newValue = feilds.indexOf(name);
		feilds.splice(newValue, 1);
		Data.setFieldValue('description', feilds);
	};
	//-------------------------------------
	const warning = () => {
	
		setValue(Data.values.description);
		if (
			JSON.stringify(Data.values.description) ===
			JSON.stringify(Product.description)
		) {
			setWaring('');
		} else {
			setWaring('Input field changed');
		}
	}
	//----------------------------
	useEffect(() => {
		Data && Product && warning();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Data.values.description, Product.description]);
	return (
		<AreaSection className={'parent'}>
			{waring && <Warning message={waring} top='-1px' right='15px' />}
			<ItemName>
				<h2>Features & Benefits</h2>
			</ItemName>
			{value &&
				value.map((item, idx) => {
					return (
						<FeaturesInput
							key={idx}
							value={item}
							onChange={(e) => handleFeatures(idx, e.target.value)}
							removehandler={removeFroms}
							name={item}
						/>
					);
				})}
			<Add
				variant='contained'
				size='small'
				color='primary'
				disabled={userPermission.disableAdd}
				onClick={addForms}
				style={{
					borderRadius: '50%',
					fontSize: '1.2rem',
					maxWidth: '40px',
					maxHeight: '40px',
					minWidth: '40px',
					minHeight: '40px',
				}}>
				<MdAdd />
			</Add>
		</AreaSection>
	);
};

export default Features;
const AreaSection = styled.fieldset`
	width: 100%;
	margin: 10px 0;
	border: 2px dotted #666;
	overflow-y: scroll;
	height: 400px;
	overflow-y: scroll;
	padding: 30px 10px;
	position: relative;
	&::-webkit-scrollbar-track {
		box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: #f5f5f5;
	}
	&::-webkit-scrollbar {
		width: 6px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #eea300;
		border-radius: 2px;
		background-image: -webkit-gradient(
			linear,
			0 0,
			0 100%,
			color-stop(0.5, rgba(255, 255, 255, 0.2)),
			color-stop(0.5, transparent),
			to(transparent)
		);
	}
`;
const ItemName = styled.legend`
	position: relative;
	height: 30px;
	h2 {
		text-transform: capitalize;
		text-align: left;
		font-size: 1rem;
	}
	&::after {
		content: '';
		width: 100px;
		height: 3px;
		background-color: #ffb511;
		position: absolute;
		bottom: 5px;
		left: 0;
	}
	&::before {
		content: '';
		width: 3px;
		height: 3px;
		background-color: #ffb511;
		border-radius: 50%;
		position: absolute;
		bottom: 5px;
		left: 105px;
	}
`;
const Add = styled(Button)`
	float: right;
`;
