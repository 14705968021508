import React, { useState } from 'react';
import styled from 'styled-components';

import Input from './Input';
import { Button } from './LocationCard';

const Form = () => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [phone, setPhone] = useState('');
	const [quantity, setQuantity] = useState('');
	const formSubmitHandler = (e) => {
		e.preventDefault();
		alert(`Name : ${name}
email : ${email}
Phone: ${phone}
Quantity:${quantity}`);
		setName('');
		setEmail('');
		setPhone('');
		setQuantity('');
	};
	return (
		<FormHolder onSubmit={formSubmitHandler}>
			<Input type='text' label='Your Name' state={[name, setName]} />
			<Input type='text' label='Your Email' state={[email, setEmail]} />
			<Input
				type='number'
				label='Your Phone Number'
				state={[phone, setPhone]}
			/>
			<Input
				type='number'
				label='Quantity of samples'
				state={[quantity, setQuantity]}
			/>
			<Button>Request</Button>
		</FormHolder>
	);
};
const FormHolder = styled.form`
	width: 400px;
	height: 380px;
	background: #ffffff;
	transition: all 0.8s ease;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	padding:10px;
`;
export default Form;
