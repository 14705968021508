import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import {
	OPPORTUNITIES_COLUMN,
	OPPORTUNITIES_HEADER,
} from '../../Components/General/Data/GeneralData';
import TableSection from '../../Components/General/Table/TableSection';
import { useData } from '../../Context/DataProvider';

const Opportunities = () => {
	const { subName } = useParams();
	const { pathname } = useLocation();
	const subCatogery = pathname.split('/')[2];
	const [data, setData] = useState('');
	const { setTableData } = useData();
	//------------------------
	const collection_identifier = 'forms';
	const Data = {
		query: { formType: subCatogery, formSubType: subName },
	};
	const { getQuerys } = Api_Helper();
	const getDetails = async () => {
		const nonPrtfolioData = await getQuerys(
			'patch',
			collection_identifier,
			Data,
		);
		setData(nonPrtfolioData);
		setTableData((state) => ({
			...state,
			tableNameData: `${subCatogery}(${subName})`,
		}));
	};
	useEffect(() => {
		subName && getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subName]);
	return (
		<>
			<TableSection
				Columu={OPPORTUNITIES_COLUMN}
				Data={data.data}
				CSV={OPPORTUNITIES_HEADER}
				type='opportunities'
			/>
		</>
	);
};

export default Opportunities;
