import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import styled from 'styled-components';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import HomeHeader from '../../Components/HomeComponent/Header/HomeHeader';
import TableConatiner from '../../Components/BrandsComponent/Table/TableConatiner';
import { useData } from '../../Context/DataProvider';
import SyncLoader from 'react-spinners/SyncLoader';
const Brands = () => {
	const { subName } = useParams();
	const { getQuerys } = Api_Helper();
	const { setTitle } = useData();
	//--------------------------------
	const [BrandData, setBrandData] = useState('');
	//-----------
	const Data = async (subName) => {
		try {
			setBrandData('');
			setTitle(() => ({
				title: 'Brands',
				subtitle: subName,
			}));
			const data = { query: { brand_name: subName } };
			const ProductData = await getQuerys('patch', 'product', data);
			const newProductData = ProductData.data.map((item) => {
				return { type: 'product', ...item };
			});
			// setBrandData([newProductData]);
			const SystemData = await getQuerys('patch', 'system', data);
			const newSystemData = SystemData.data.map((item) => {
				return { type: 'system', ...item };
			});
			setBrandData([...newSystemData, ...newProductData]);
		} catch (e) {
			console.log(e);
		}
	};

	//--------------------------------
	useEffect(() => {
		Data(subName);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subName]);
	console.log(BrandData);
	return (
		<PortfolioBody>
			<DashBoardContainer>
				<HomeHeader />
				{BrandData ? (
					<TableConatiner tableData={BrandData} />
				) : (
					<Spinner>
						<SyncLoader color={'#f0852dc7'} loading={true} size={10} />
					</Spinner>
				)}
			</DashBoardContainer>
		</PortfolioBody>
	);
};

export default Brands;
const PortfolioBody = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow: hidden;
`;
const DashBoardContainer = styled.section`
	width: 100%;
	height: 100%;
	margin-bottom: 40px;
`;
const Spinner = styled.div`
	width: 100%;
	height: calc(100vh - 200px);
	background-color: #f0f0f0;
	display: grid;
	place-items: center;
`;
