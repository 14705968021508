import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import moment from 'moment';
import queryString from 'query-string';
import { MdClose } from 'react-icons/md';
import PopUp from '../../../Components/Reuseable/PopUp';
import MediaInput from '../../../Components/General/Media/_INPUT/MediaInput';
import { FaCamera } from 'react-icons/fa';
import UpdateButton from '../../../Components/General/Media/MediaReuseable/UpdateButton';
import ResetButton from '../../../Components/General/Media/MediaReuseable/ResetButton';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Warning from '../../../Components/Reuseable/Warning';
import Modal from '../../../Components/General/Media/MediaReuseable/Modal';
import { DeleteButton } from '../../../Components/General/Media/MediaReuseable/DeleteButton';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

//-------------------
const EventEdit = () => {
	const { getQuerys, getUrl, validation } = Api_Helper();
	const { uid } = useParams();
	const Loction = useLocation();
	const { tag } = queryString.parse(Loction.search);
	const imageFile = useRef();
	const [Image, setImage] = useState(null);
	const [popUp, setPopUp] = useState('');
	const [waring, setWaring] = useState('');
	//---------------------------
	const [data, setData] = useState();
	const type = ['png', 'jpg', 'webp', 'jpeg'];
	const [isOpen, setIsOpen] = useState('');
	const { userPermission } = userAuthorization();
	const history = useHistory();

	///--------------------------------Validaition-------------------
	const FormSchema = Yup.object({
		category: Yup.string()
			.max(20, 'Must be 20 characters or less')
			.required('Required'),
		name: Yup.string()
			.max(40, 'Must be 40 characters or less ')
			.required('Required'),
		location: Yup.string()
			.max(20, 'Must be 20 characters or less')
			.required('Required'),
		plain_date: Yup.date().required('Required'),
	});
	//------------------------------Formik--------
	const formik = useFormik({
		initialValues: {
			category: '',
			name: '',
			location: '',
			plain_date: '',
			image: [],
			description: '',
			date: '',
			registration_open: true,
			url: '',
		},
		onSubmit: () => {
			updateHandler();
		},
		validationSchema: FormSchema,
	});
	//-------------------------
	const fetchProduct = async () => {
		try {
			if (tag === '1') {
				const Query = { query: { u_id: uid } };
				const Details = await getQuerys('patch', 'event', Query);
				const Data = Details.data[0];
				setData(Data);
				//------
				formik.resetForm({
					values: {
						category: Data.category,
						name: Data.name,
						location: Data.location,
						plain_date: Data.plain_date,
						image: Data.image,
						description: Data.description,
						date: Data.date,
						registration_open: Data.registration_open,
						url: Data.url,
					},
				});
			} else {
				formik.resetForm({
					values: {
						category: '',
						name: '',
						location: '',
						plain_date: '',
						image: [],
						description: '',
						date: '',
						registration_open: true,
						url: '',
					},
				});
				imageFile.current.value = '';
			}
		} catch (e) {
			console.log(e);
		}
	};
	//-----------------Image----------------
	const handleChange = (e) => {
		if (
			e.target.files[0] &&
			type.includes(e.target.files[0].type.split('/')[1].toLowerCase())
		) {
			if (e.target.files[0].size / 1024 / 1024 < 5) {
				setImage(e.target.files[0]);
				tag === '1' && setWaring('Image Changed');

				const src1 = URL.createObjectURL(e.target.files[0]);
				const imageLink = formik.values.image;
				imageLink[0] = src1;
				formik.setFieldValue('image', imageLink);
			} else {
				setPopUp('Image Should Less than 5MB');
			}
		} else {
			setPopUp('Please upload only png ,jpg or jpeg files');
		}
	};
	//-----------------update---------------------
	const updateHandler = async () => {
		const collection_identifier = tag === '1' ? `event/${uid}` : 'event';
		try {
			if (tag === '1' ? waring : true) {
				if (Image) {
					const name = Image.name;
					await validation(Image, 550, 550);
					const url = await getUrl(Image, name, 'event/');
					const imageLink = formik.values.image;
					imageLink[0] = url.data.url;
					formik.setFieldValue('image', imageLink);
					const data = { document: formik.values };
					console.log(data);
					const Product = await getQuerys('post', collection_identifier, data);
					console.log(Product);
					setPopUp('Updated');
					fetchProduct();
				} else {
					if (formik.values.image[0]) {
						const data = { document: formik.values };
						const Product = await getQuerys(
							'post',
							collection_identifier,
							data,
						);
						console.log(Product);
						setPopUp('Updated');
						fetchProduct();
					} else {
						setPopUp('Image is Not Set');
					}
				}
				imageFile.current.value = '';
				setWaring('');
			} else {
				setPopUp('Nothing to Update');
			}
		} catch (error) {
			console.log(error);
			if (error.response) {
				console.log(error.response.data);
				setPopUp('The Image width height should be 550 X 550');
			} else {
				setPopUp('Sorry Try Again later');
			}
		}
	};
	//-----------------------------
	const resetHandler = () => {
		fetchProduct();
		imageFile.current.value = '';
	};
	//---------------------
	const waringHandler = () => {
		if (
			data.category === formik.values.category &&
			data.name === formik.values.name &&
			data.location === formik.values.location &&
			data.registration_open === formik.values.registration_open &&
			data.plain_date === formik.values.plain_date
		) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
	};
	//-----------------------
	useMemo(() => {
		uid && tag && fetchProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, tag]);
	//-------------------------
	useEffect(() => {
		data && formik.values && waringHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, formik.values]);
	return (
		<>
			{popUp && <PopUp state={[popUp, setPopUp]} />}
			<StyledConatiner>
				{isOpen && (
					<Modal uid={isOpen} type='event' state={setIsOpen} subName='Event' />
				)}
				<EventItems>
					<ImageSection>
						<img
							src={formik.values.image[0] || ''}
							onError={(e) => {
								e.target.src = '/Assets/loading.png';
							}}
							alt='pic'
							name='image'
							onBlur={formik.handleBlur}
						/>
						<input
							ref={imageFile}
							type='file'
							id='file'
							name='file'
							accept='image/*'
							required
							disabled={userPermission.disableUpdate}
							onChange={handleChange}
						/>
						<label htmlFor='file'>
							<FaCamera />
						</label>
					</ImageSection>
					<EventDetails>
						{waring && <Warning message={waring} top='10px' right='20px' />}
						<MediaInput
							onChange={formik.handleChange}
							value={formik.values.category || ''}
							lable='category'
							name='category'
							onBlur={formik.handleBlur}
							errorData={formik}
							width='150px'
						/>
						<MediaInput
							onChange={formik.handleChange}
							value={formik.values.name || ''}
							lable='name'
							name='name'
							onBlur={formik.handleBlur}
							errorData={formik}
						/>
						<InputBox>
							<MediaInput
								onChange={formik.handleChange}
								value={formik.values.location || ''}
								lable='location'
								name='location'
								onBlur={formik.handleBlur}
								errorData={formik}
								width='250px'
							/>{' '}
							<span> </span>{' '}
							<Date>
								<input
									type='date'
									value={
										formik.values.plain_date === ''
											? ''
											: moment(formik.values.plain_date).format('YYYY-MM-DD')
									}
									onChange={formik.handleChange}
									name='plain_date'
									lable='plain_date'
									placeholder='dd-mm-yyyy'
									disabled={userPermission.disableUpdate}
									min={moment().format('DD-MM-YYYY')}
								/>
								{formik.errors.plain_date && formik.touched.plain_date ? (
									<Error>{formik.errors.plain_date}</Error>
								) : null}
							</Date>
						</InputBox>
						<RegisterBox>
							<FormControlLabel
								value='start'
								control={
									<Checkbox
										color='primary'
										checked={Boolean(formik.values.registration_open)}
										lable='registration_open'
										name='registration_open'
										onChange={formik.handleChange}
										disabled={userPermission.disableUpdate}
									/>
								}
								label={<p>Registration open</p>}
								labelPlacement='start'
							/>
							<MediaInput
								onChange={formik.handleChange}
								value={formik.values.url || ''}
								lable='url'
								name='url'
								onBlur={formik.handleBlur}
								errorData={formik}
								width='400px'
								required
								disabled={!Boolean(formik.values.registration_open)}
							/>
						</RegisterBox>
					</EventDetails>
					<ButtonZone onClick={() => history.push('/general/media/event')}>
						<p>
							<MdClose />
						</p>
					</ButtonZone>
					<ButtonsBox>
						<div>
							<ResetButton
								onClick={resetHandler}
								disabled={waring === '' ? true : false}
							/>
							<UpdateButton onClick={formik.handleSubmit} />
						</div>
						{tag === '1' && <DeleteButton onClick={() => setIsOpen(uid)} />}
					</ButtonsBox>
				</EventItems>
			</StyledConatiner>
		</>
	);
};

export default EventEdit;
const ButtonZone = styled.button`
	background-color: #21b6ae;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	position: absolute;
	right: 5px;
	top: ${({ bottom }) => (bottom ? bottom : '5px')};
	transition: all 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25);
	cursor: pointer;
	p {
		color: #fff;
		font-size: 1.2rem;
		display: grid;
		place-items: center;
	}
	&:hover {
		box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
			rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
	}
`;

const StyledConatiner = styled.div`
	width: 100%;
	height: 80vh;
	display: flex;
	place-items: center;
	justify-content: center;
	padding: 20px;
`;
const EventItems = styled.form`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding: 35px;
	height: 100%;
	background-color: #fff;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`;
const ImageSection = styled.div`
	left: 5%;
	width: 35%;
	height: 40vh;
	border: 3px dotted rgb(0, 169, 201);
	position: relative;
	img {
		width: 90%;
		height: 90%;
		transform: translateX(50px) translateY(10px);
		object-fit: cover;
	}
	input {
		display: none;
	}
	label {
		position: absolute;
		width: 90%;
		height: 90%;
		top: 10px;
		left: 50px;
		color: #ffb511;
		display: grid;
		place-items: center;
		font-size: 2rem;
		background-color: #00000034;
		&:hover {
			background-color: #00000060;
			cursor: pointer;
		}
	}
`;
const EventDetails = styled.div`
	width: 55%;
	height: 90%;
	padding: 2rem 2rem 3rem 4rem;
	border-radius: 0.5rem;
	border: 3px dotted #ffb511;
	position: relative;
`;
const InputBox = styled.div`
	display: flex;
	span {
		width: 20px;
	}
`;
const Date = styled.div`
	input[type='date'] {
		padding: 5px;
		font-size: 12px;
		border: 1px solid #c7c2c2;
		outline: none;
		text-transform: uppercase;
		border-radius: 3px;
		width: 150px;
		height: 40px;
	}
`;
const ButtonsBox = styled.div`
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: absolute;
	bottom: 10px;
`;

const RegisterBox = styled.div``;
const Error = styled.p`
	font-size: 0.6rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
	height: 12px;
`;
