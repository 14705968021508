export const COLLABORATE_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'firstName', accessor: 'firstName' },
	{ Header: 'lastName', accessor: 'lastName' },
	{ Header: 'mobileNumber', accessor: 'mobileNumber' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];
export const OPPORTUNITIES_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'firstName', accessor: 'firstName' },
	{ Header: 'lastName', accessor: 'lastName' },
	{ Header: 'mobileNumber', accessor: 'mobileNumber' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];

export const MEDIA_EVENT_COLUMN = [
	{ Header: 'Image', accessor: 'image' },
	{ Header: 'name', accessor: 'name' },
	{ Header: 'Date', accessor: 'plain_date' },
	{ Header: 'location', accessor: 'location' },
	{ Header: 'category', accessor: 'category' },
	{ Header: 'Action', accessor: '' },
];
export const MEDIA_ARTICLE_COLUMN = [
	{ Header: 'title', accessor: 'title' },
	{ Header: 'description', accessor: 'description' },
	{ Header: 'Action', accessor: '' },
];
export const MEDIA_TESTIMONIAL_COLUMN = [
	{ Header: 'Image', accessor: 'image' },
	{ Header: 'category', accessor: 'category' },
	{ Header: 'testimony', accessor: 'testimony' },
	{ Header: 'attestant', accessor: 'attestant' },
	{ Header: 'Action', accessor: '' },
];
export const MEDIA_SOCIALMEDIA_COLUMN = [
	{ Header: 'title', accessor: 'title' },
	{ Header: 'description', accessor: 'description' },
	{ Header: 'Platform', accessor: 'category' },
	{ Header: 'Action', accessor: '' },
];
export const ENQUIRY_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'firstName', accessor: 'firstName' },
	{ Header: 'lastName', accessor: 'lastName' },
	{ Header: 'country', accessor: 'country' },
	{ Header: 'mobileNumber', accessor: 'mobileNumber' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];
export const REQUEST_TDS_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'Name', accessor: 'name' },
	{ Header: 'country', accessor: 'country' },
	{ Header: 'Entity', accessor: 'entity' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];
export const REQUEST_MSDS_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'Name', accessor: 'name' },
	{ Header: 'country', accessor: 'country' },
	{ Header: 'Entity', accessor: 'entity' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];
export const SAMPLE_REQUEST_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'Name', accessor: 'name' },
	{ Header: 'country', accessor: 'country' },
	{ Header: 'Entity', accessor: 'entity' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'status', accessor: '' },
];

// export const CONTACT_US_COLUMN = [
// 	{ Header: 'Ref Id', accessor: 'uid' },
// 	{ Header: 'Date', accessor: 'createdAt' },
// 	{ Header: 'Name', accessor: 'name' },
// 	{ Header: 'country', accessor: 'country' },
// 	{ Header: 'Entity', accessor: 'entity' },
// 	{ Header: 'email', accessor: 'email' },
// 	{ Header: 'status', accessor: '' },
// ];

export const CONTACT_US_COLUMN = [
	{ Header: 'Ref Id', accessor: 'uid' },
	{ Header: 'Date', accessor: 'createdAt' },
	{ Header: 'FirstName', accessor: 'firstName' },
	{ Header: 'LastName', accessor: 'lastName' },
	{ Header: 'ConnectFor', accessor: 'connectFor' },
	{ Header: 'email', accessor: 'email' },
	{ Header: 'Company', accessor: 'companyName' },
	{ Header: 'Designation', accessor: 'designation' },
	{ Header: 'status', accessor: '' },
];



//----------------------------------------------------------------------
export const COLLABORATE_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date / Time', key: 'createdAt' },
	{ label: 'firstName', key: 'firstName' },
	{ label: 'lastName', key: 'lastName' },
	{ label: 'mobileNumber', key: 'mobileNumber' },
	{ label: 'email', key: 'email' },
	{ label: 'Designation', key: 'formSubType' },
	{ label: 'file', key: 'fileAttachment' },
];
export const OPPORTUNITIES_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date / Time', key: 'createdAt' },
	{ label: 'firstName', key: 'firstName' },
	{ label: 'lastName', key: 'lastName' },
	{ label: 'mobileNumber', key: 'mobileNumber' },
	{ label: 'email', key: 'email' },
	{ label: 'Designation', key: 'formSubType' },
	{ label: 'Company', key: 'companyDetails' },
	{ label: 'file', key: 'fileAttachment' },
];
//------------------------request-------------------------------------------
export const ENQUIRY_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'FirstName', key: 'firstName' },
	{ label: 'LastName', key: 'lastName' },
	{ label: 'Country', key: 'country' },
	{ label: 'MobileNumber', key: 'mobileNumber' },
	{ label: 'Email', key: 'email' },
	{ label: 'Department', key: 'department' },
	{ label: 'Company', key: 'companyName' },
	{ label: 'Designation', key: 'designation' },
	{ label: 'Enquiry For', key: 'markets' },
	{ label: 'Communication Status', key: 'communication_status' },
];
export const REQUEST_TDS_HEADER = [
    { label: 'Ref Id', key: 'uid' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'Name', key: 'name' },
	{ label: 'Country', key: 'country' },
	{ label: 'Entity', key: 'entity' },
	{ label: 'Email', key: 'email' },
	{ label: 'Department', key: 'department' },
	{ label: 'Company', key: 'companyName' },
	{ label: 'Communication Status', key: 'communication_status' },
];
export const REQUEST_MSDS_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'Name', key: 'name' },
	{ label: 'Country', key: 'country' },
	{ label: 'Entity', key: 'entity' },
	{ label: 'Email', key: 'email' },
	{ label: 'Department', key: 'department' },
	{ label: 'Company', key: 'companyName' },
	{ label: 'Communication Status', key: 'communication_status' },
];
export const SAMPLE_REQUEST_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'Name', key: 'name' },
	{ label: 'Country', key: 'country' },
	{ label: 'Entity', key: 'entity' },
	{ label: 'Email', key: 'email' },
	{ label: 'Department', key: 'department' },
	{ label: 'Company', key: 'companyName' },
	{ label: 'Communication Status', key: 'communication_status' },
];

export const CONATACT_US_HEADER = [
	{ label: 'Ref Id', key: 'uid' },
	{ label: 'Date', key: 'createdAt' },
	{ label: 'FirstName', key: 'firstName' },
	{ label: 'LastName', key: 'lastName' },
	{ label: 'ConnectFor', key: 'connectFor' },
	{ label: 'Email', key: 'email' },
	{ label: 'Company', key: 'companyName' },
	{ label: 'Designation', key: 'designation' },
	{ label: 'Communication Status', key: 'communication_status' },
];
