import React, { useEffect, useState } from 'react';
import { useData } from '../../../Context/DataProvider';
import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components';
import { BasicTable } from '../../Reuseable/Table/BasicTable';
const TableConatiner = () => {
	const { tableData } = useData();
	console.log(tableData);
	//--------------------------
	const [column, setColumn] = useState('');
	const [data, setData] = useState('');
	//----------------------------
	const COLUMN = [];
	const DATA = [];
	//---------------------------------
	const getTableDeatils = (table) => {
		COLUMN.push({
			Header: 'Product Name',
			accessor: 'Product Name',
		});
		Object.keys(table && table.length > 0 && table[0].table).forEach((key) => {
			var newStr = key.replace(/_/g, ' ').toLocaleLowerCase();
			COLUMN.push({
				Header: newStr,
				accessor: newStr,
			});
		});
		COLUMN.push({
			Header: 'Action',
			accessor: 'Action',
		});

		setColumn(COLUMN);
		//------------------------------------------
		table.forEach((data) => {
			const objArray = Object.keys(data.table);
			const objWant = {};
			objArray.map((item) => {
				return (objWant[item && item.replace(/_/g, ' ').toLocaleLowerCase()] =
					item === 'Feature'
						? data.table[item].value.substring(0, 30) + '...'
						: data.table[item].value); ///------------lowercase------------
			});
			///------------for other purpose in table and update------------------------------
			objWant['Product Name'] = data.system_name || data.product_name;
			objWant.uuid = data.u_id;
			objWant.type = data.type;
			DATA.push(objWant);
		});
		setData(DATA);
	};

	//-------------------------useEffect---------------------
	useEffect(() => {
		tableData.table && getTableDeatils(tableData.table);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tableData.table]);

	return (
		<>
			{column && data ? (
				<BasicTable columns={column} data={data}  />
			) : (
				<Spinner>
					<SyncLoader color={'#f0852dc7'} loading={true} size={10} />
				</Spinner>
			)}
		</>
	);
};

export default TableConatiner;
const Spinner = styled.div`
	width: 100%;
	height: calc(100vh - 200px);
	background-color: #f0f0f0;
	display: grid;
	place-items: center;
`;
