import styled from 'styled-components';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

const ApplicationInput = ({
	removeHandler,
	width,
	name,
	errorData,
	id,
	lable,
	...prop
}) => {
	const { userPermission } = userAuthorization();

	return (
		<>
			<InputBox>
				<InputZone
					type='text'
					id={id}
					autocomplete='off'
					required
					readOnly={userPermission.disableInput}
					{...prop}
					Width={width}
				/>
				{errorData.errors.application &&
				errorData.errors.application[id] &&
				errorData.errors.application[id][name] ? (
					<Error>{errorData.errors.application[id][name]}</Error>
				) : (
					<Error></Error>
				)}
			</InputBox>
		</>
	);
};

export default ApplicationInput;
const InputBox = styled.div`
	position: relative;
	display: flex;
	align-items: center;
`;

const InputZone = styled.input`
	display: block;
	width: ${({ Width }) => (Width ? Width : '220px')};
	height: 30px;
	background: transparent;
	border: solid 1px #ccc;
	transition: all 0.3s ease;
	padding: 0 15px;
	outline: none;
	&:focus {
		border-color: #39a9cb;
	}
`;

const Error = styled.span`
	font-size: 0.55rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
	position: absolute;
	bottom: -18px;
`;
