import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useLocation, useParams } from 'react-router-dom';
import queryString from 'query-string';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import BackButton from '../../../Components/Reuseable/BackButton';
import MediaInput from '../../../Components/General/Media/_INPUT/MediaInput';
import UpdateButton from '../../../Components/General/Media/MediaReuseable/UpdateButton';
import ResetButton from '../../../Components/General/Media/MediaReuseable/ResetButton';
import PopUp from '../../../Components/Reuseable/PopUp';
import Warning from '../../../Components/Reuseable/Warning';
import Modal from '../../../Components/General/Media/MediaReuseable/Modal';
import { DeleteButton } from '../../../Components/General/Media/MediaReuseable/DeleteButton';
//-------------------
const ArticleEdit = () => {
	const { uid } = useParams();
	const Loction = useLocation();
	const { tag } = queryString.parse(Loction.search);
	const { getQuerys } = Api_Helper();
	//---------------------------
	const [data, setData] = useState();
	const [waring, setWaring] = useState('');
	const [popUp, setPopUp] = useState('');
	const [isOpen, setIsOpen] = useState('');
	//-------------
	const FormSchema = Yup.object({
		title: Yup.string()
			.min(20, 'Must be 20 characters or more')
			.max(100, 'Must be 100 characters or less')
			.required('Required'),
		description: Yup.string()
			.min(50, 'Must be 50 characters or more')
			.max(300, 'Must be 100 characters or less')
			.required('Required'),
		url: Yup.string().required('Required'),
	});
	//------------------------------Formik--------
	const formik = useFormik({
		initialValues: {
			title: '',
			description: '',
			url: '',
		},
		validationSchema: FormSchema,
		onSubmit: () => {
			updateHandler();
		},
	});
	//-------------------------
	const fetchProduct = async () => {
		if (tag === '1') {
			const Query = { query: { u_id: uid } };
			const Details = await getQuerys('patch', 'article', Query);
			const Data = Details.data[0];
			setData(Data);
			formik.resetForm({
				values: {
					title: Data.title,
					description: Data.description,
					url: Data.url,
				},
			});
		} else {
			formik.resetForm({
				values: {
					title: '',
					description: '',
					url: '',
				},
			});
		}
	};
	//-----------------------------------------------
	const updateHandler = async () => {
		const collection_identifier = tag === '1' ? `article/${uid}` : 'article';
		try {
			if (tag === '1' ? waring : true) {
				const data = { document: formik.values };
				const Product = await getQuerys('post', collection_identifier, data);
				console.log(Product);
				setPopUp('Updated');
				fetchProduct();
			} else {
				setPopUp('Nothing to Update');
			}
		} catch (e) {
			console.log(e);
		}
	};
	//---------------------
	const waringHandler = () => {
		if (
			data.title === formik.values.title &&
			data.description === formik.values.description &&
			data.url === formik.values.url
		) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
	};

	//-------------------------
	useEffect(() => {
		fetchProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, tag]);
	//-------------------------
	useEffect(() => {
		data && formik.values && waringHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, formik.values]);
	return (
		<StyledConatiner>
			{isOpen && (
				<Modal
					uid={isOpen}
					type='article'
					state={setIsOpen}
					subName='Article'
				/>
			)}
			{waring && <Warning message={waring} top='10px' right='20px' />}
			{popUp && <PopUp state={[popUp, setPopUp]} />}
			<ArticleBox>
				<ArticleImage>
					<img src='/Assets/article.webp' alt='article' />
				</ArticleImage>
				<ArticleInputs>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.title || ''}
						lable='title'
						name='title'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='60%'
					/>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.url || ''}
						lable='url'
						name='url'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='80%'
					/>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.description || ''}
						lable='description'
						name='description'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='100%'
					/>
					<ButtonsBox>
						<div>
							<ResetButton
								onClick={fetchProduct}
								disabled={waring === '' ? true : false}
							/>
							<UpdateButton onClick={formik.handleSubmit} />
						</div>
						{tag === '1' && <DeleteButton onClick={() => setIsOpen(uid)} />}
					</ButtonsBox>
				</ArticleInputs>
				<BackButton />
			</ArticleBox>
		</StyledConatiner>
	);
};

export default ArticleEdit;
const StyledConatiner = styled.div`
	width: 100%;
	min-height: calc(100vh - 110px);
	display: grid;
	place-items: center;
	position: relative;
`;
const ArticleBox = styled.div`
	position: relative;
	width: 100%;
	max-width: 800px;
	height: 300px;
	background-color: #f6f6f6;
	padding: 20px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	display: flex;
	align-items: center;
`;
const ArticleImage = styled.div`
	width: 30%;
	height: 100%;
	display: grid;
	place-items: center;
	img {
		width: 120px;
		object-fit: contain;
	}
`;
const ArticleInputs = styled.div`
	width: 100%;
	height: 100%;
`;
const ButtonsBox = styled.div`
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
