import React from 'react';
import { NavLink, Route, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Menu_Data } from '../Data/Product_sectionMenu';
import TDS from '../Tds/TDS';
import TableSection from '../Table/TableSection';
import Msds from '../Msds/Msds';
import Regulatory from '../Regulatory/Regulatory';
import Availability from '../Availability/Availability';
///--------------Properties main value for the section-----------------------
const SectionContainer = ({ Data }) => {
	const {  type } = useParams();
	return (
		<>
			{Data && (
				<StyledContainer>
					<SectionMenu>
						{Menu_Data.map((data, idx) => {
							return (
								<MenuItem to={data.link} key={idx} activeClassName='active'>
									{data.Name}
								</MenuItem>
							);
						})}
					</SectionMenu>
					<SectionBody>
						<Info>
							{type === 'system'
								? Data.values.heading
								: Data.values.brand_name + Data.values.product_name}
						</Info>
						<Route path='/type/:type/:uid/properties'>
							{Data &&
								Data.values.properties &&
								Data.values.properties.length > 0 && (
									<TableSection Formik={Data} />
								)}
						</Route>
						<Route path='/type/:type/:uid/tds'>
							{Data &&
								Data.values.tds_link &&
								Data.values.tds_link.length > 0 && <TDS Formik={Data} />}
						</Route>
						<Route path='/type/:type/:uid/msds'>
							{Data &&
								Data.values.msds_link &&
								Data.values.msds_link.length > 0 && <Msds Formik={Data} />}
						</Route>
						<Route path='/type/:type/:uid/regulatory'>
							{Data && <Regulatory Formik={Data} />}
						</Route>
						<Route path='/type/:type/:uid/region'>
							<Availability/> </Route>
					</SectionBody>
				</StyledContainer>
			)}
		</>
	);
};

export default SectionContainer;
const StyledContainer = styled.div`
	width: 100%;
	background-color: #f7f7f7;
	display: flex;
	flex-direction: column;
`;
const SectionMenu = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;
const MenuItem = styled(NavLink)`
	width: 100%;
	background-color: #6d6e71;
	border-radius: 20px 20px 0 0;
	height: 50px;
	display: grid;
	place-items: center;
	transition-duration: 0.6s;
	color: #ffffff;
	font-weight: 500;
	margin: 1px;
	text-decoration: none;
	&.active {
		background-color: #ffb511;
	}
`;
const SectionBody = styled.div`
	width: 100%;
	height: 100%;
	min-height: 300px;
	display: grid;
	place-items: center;
`;
const Info = styled.p`
	font-weight: 500;
	font-size: 1.4rem;
	margin: 10px 0;
	float: left;
`;