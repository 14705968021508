import React from 'react';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import { MdDelete } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
const FeaturesInput = ({ removehandler, name, ...props }) => {
	//--------------
	const { userPermission } = userAuthorization();

	return (
		<>
			<InputBox>
				<InputZone
					type='text'
					autocomplete='off'
					{...props}
					readOnly={userPermission.disableInput}
				/>

				<Tooltip
					arrow
					TransitionComponent={Zoom}
					title={'Delete'}
					placement='top'>
					<>
						<IconButton
							aria-label='delete'
							className='icon'
							disabled={userPermission.disableDelete}
							onClick={() => removehandler(name)}>
							<MdDelete />
						</IconButton>
					</>
				</Tooltip>
			</InputBox>
		</>
	);
};

export default FeaturesInput;
const InputBox = styled.div`
	position: relative;
	margin-bottom: 10px;
	display: flex;
	align-items: center;
	& .icon {
		height: 10px;
		font-size: 0.95rem;
		color: #dd2121;
	}
`;
const InputZone = styled.input`
	display: block;
	width: 100%;
	height: 30px;
	background: #ffffff;
	border: none;
	transition: all 0.3s ease;
	padding: 0 15px;
	outline: none;
	&:focus {
		border-color: #00c3ff;
	}
`;

/* const Error = styled.p`
	font-size: 0.6rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
`;
 */
