export const General_Data = [
	/* {
		id: '1',
		name: 'innovations',
		flag: 1,
		mainCatogery: [
			{ id: '1.1', name: 'Bio - Source' },
			{ id: '1.2', name: 'Recyclamine' },
			{ id: '1.3', name: 'Waterborne' },
			{ id: '1.4', name: 'BPA-NI' },
		],
	}, */
	{
		id: '2',
		name: 'collaborate',
		flag: 1,
		mainCatogery: [
			{
				id: '2.1',
				name: 'researcher',
				link: 'researcher',
			},
			{
				id: '2.2',
				name: 'university',
				link: 'university',
			},
			{
				id: '2.3',
				name: 'established-company',
				link: 'established-company',
			},
			{ id: '2.4', name: 'start-up', link: 'start-up' },
			{
				id: '2.5',
				name: 'social-non-profit',
				link: 'social-non-profit',
			},
			{
				id: '2.6',
				name: 'radiant-challenge',
				link: 'radiant-challenge',
			},
		],
	},

	{
		id: ' 4',
		name: 'opportunities',
		flag: 1,
		mainCatogery: [
			{ id: '4.1', name: 'employment', link: 'employment' },
			{ id: '4.2', name: 'distribution', link: 'distribution' },
			{ id: '4.3', name: 'suppliers', link: 'suppliers' },
			{ id: '4.4', name: 'logistics', link: 'logistics' },
		],
	},
	{
		id: '5',
		name: 'media',
		flag: 1,
		mainCatogery: [
			{ id: '5.1', name: 'Events', link: 'event' },
			{ id: '5.2', name: 'articles', link: 'article' },
			{ id: '5.3', name: 'testimonials', link: 'testimonial' },
			{ id: ' 5.4', name: 'social-media', link: 'socialmedia' },
		],
	},
	{
		id: '6',
		name: 'connect',
		flag: 1,
		mainCatogery: [
			{
				id: '6.1',
				name: 'TDS',
				link: 'tds-request',
			},
			{
				id: '6.2',
				name: 'MSDS',
				link: 'msds-request',
			},
			{
				id: '6.3',
				name: 'Sample Request',
				link: 'sample-request',
			},
			{
				id: '6.4',
				name: 'Enquiry ',
				link: 'enquiry',
			},
			{
				id: '6.5',
				name: 'Contact Us',
				link: 'contact-us',
			},
		],
	},
];
