import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { MdClose } from 'react-icons/md';
import { FiAlertTriangle } from 'react-icons/fi';
import Api_Helper from '../../../../Adapters/Api/Api_Helper';
const Modal = ({ handler, state, subName, uid, type }) => {
	//--------------------------------
	const { getQuerys } = Api_Helper();
	//-------------------------------
	const Delete = async () => {
		const collection_identifier = `${type}/${uid}`;
		try {
			await getQuerys('delete', collection_identifier, '');
			state('');
			window.history.back();
		} catch (e) {
			console.log(e);
		}
	};
	return (
		<ModaleBox>
			<ConformBox>
				<TextClose>
					<p>Are you sure you want to do this?</p>
					<MdClose style={{ cursor: 'pointer' }} onClick={() => state('')} />
				</TextClose>
				<TextWaring>
					<p>
						<FiAlertTriangle size={18} style={{ margin: '0 5px' }} />
						This is extremely important.
					</p>
				</TextWaring>
				<TextLowerZone>
					<Text>
						We will immediately delete all of your details of this Event
					</Text>
					<Button variant='contained' color='secondary' onClick={Delete}>
						Delete this {subName}
					</Button>
				</TextLowerZone>
			</ConformBox>
		</ModaleBox>
	);
};

export default Modal;
const ModaleBox = styled.div`
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	background-color: #33333371;
	z-index: 6;
	display: grid;
	place-items: center;
`;
const ConformBox = styled.div`
	width: 320px;
	height: 400px;
	background-color: #f6f6f6;
`;
const TextClose = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: 'Raleway', sans-serif;
	width: 100%;
	height: 60px;
	padding: 20px;

	p {
		font-size: 14px;
		font-weight: 600;
		color: #2e3236;
	}
`;

const TextWaring = styled(TextClose)`
	background-color: #ffe4e7;
	border-top: 1px solid #db989d;
	border-bottom: 1px solid #db989d;
	padding: 20px;

	P {
		color: #7a5e60;
		font-size: 12px;
		display: flex;
		align-items: center;
	}
`;
const TextLowerZone = styled.div`
	padding: 10px;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	height: 300px;
`;
const Text = styled.p`
	font-family: 'Roboto', sans-serif;
	font-size: 0.7rem;
	line-height: 20px;
	letter-spacing: 1px;
	color: #585b5e;
	@media (max-width: 260px) {
		font-size: 0.5rem;
	}
`;
