import storage from 'local-storage-fallback';

const userAuthorization = () => {
	const userPermission = {
		disableUpdate: true,
		disableReset: true,
		disableDelete: true,
		disableInput: true,
		disableAdd: true,
	};
	const Authority = storage.getItem('Authority').toLocaleLowerCase();

	//--------------------------
	switch (Authority) {
		case 'viewer': {
			userPermission.disableUpdate = true;
			userPermission.disableReset = true;
			userPermission.disableDelete = true;
			userPermission.disableInput = true;
			userPermission.disableAdd = true;
			break;
		}
		case 'developer': {
			userPermission.disableUpdate = false;
			userPermission.disableReset = false;
			userPermission.disableDelete = false;
			userPermission.disableInput = false;
			userPermission.disableAdd = false;
			break;
		}
		case 'power': {
			userPermission.disableUpdate = false;
			userPermission.disableReset = false;
			userPermission.disableDelete = false;
			userPermission.disableInput = false;
			userPermission.disableAdd = false;
			break;
		}
		case 'admin': {
			userPermission.disableUpdate = false;
			userPermission.disableReset = false;
			userPermission.disableDelete = false;
			userPermission.disableInput = false;
			userPermission.disableAdd = false;
			break;
		}
		case 'editor': {
			userPermission.disableUpdate = false;
			userPermission.disableReset = false;
			userPermission.disableDelete = true;
			userPermission.disableInput = false;
			userPermission.disableAdd = true;
			break;
		}
		default: {
			userPermission.disableUpdate = true;
			userPermission.disableReset = true;
			userPermission.disableDelete = true;
			userPermission.disableInput = true;
			userPermission.disableAdd = true;
			break;
		}
	}
	//------------------

	return { userPermission };
};

export default userAuthorization;
