import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../Input/Input';
import Warning from '../../Reuseable/Warning';
import { useData } from '../../../Context/DataProvider';


const System = ({ Data }) => {
	const { productData} = useData();
	const Product = JSON.parse(productData);
	//------------------------------------------
	const [waring, setWaring] = useState('');
	//---------------------------------
	useEffect(() => {
		if (
			Data.values.system_type === Product.system_type &&
			Data.values.recommended_processes === Product.recommended_processes
		) {
			setWaring('');
		} else {
			setWaring('Values Changed');
		}
	}, [
		Data.values.recommended_processes,
		Data.values.system_type,
		Product.recommended_processes,
		Product.system_type,
	]);
	return (
		<>
			<Inputs>
				{waring && <Warning message={waring} />}
				<Input
					onChange={Data.handleChange}
					value={Data.values.system_type || ''}
					lable='system_type'
					name='system_type'
					onBlur={Data.handleBlur}
					errorData={Data}
				/>

				<Input
					onChange={Data.handleChange}
					value={Data.values.recommended_processes || ''}
					onBlur={Data.handleBlur}
					lable='recommended_processes'
					name='recommended_processes'
					errorData={Data}
				/>
			</Inputs>
		</>
	);
};

export default System;
const Inputs = styled.div`
	width: 100%;
	padding: 20px;
	border: 2px dotted #666666;
	height: 200px;
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
`;
