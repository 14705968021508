import storage from 'local-storage-fallback';
import React from 'react';
import styled from 'styled-components';
import BackButton from '../../Components/Reuseable/BackButton';
import { FcInfo } from 'react-icons/fc';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
const Profile = () => {
	const Data = storage.getItem('ProfileData');
	const ProfileData = JSON.parse(Data);

	return (
		<StyledContainer>
			<div className='wrapper'>
				<BackButton />
				<div className='left'>
					<img src='/Assets/Profile.png' alt='avater' width='100' />
					<h4>{ProfileData.username}</h4>
				</div>
				<div className='right'>
					<div className='info'>
						<h3>Information</h3>
						<div className='info_data'>
							<div className='data'>
								<h4>First Name</h4>
								<p>{ProfileData.firstname}</p>
							</div>
							<div className='data'>
								<h4>Last Name</h4>
								<p>{ProfileData.lastname}</p>
							</div>
						</div>
						<div className='info_data'>
							<div className='data'>
								<h4>Email</h4>
								<p>{ProfileData.email}</p>
							</div>
						</div>
					</div>

					<div className='projects'>
						<h3>Account</h3>
						<div className='projects_data'>
							<div className='data'>
								<h4>Active</h4>
								<p>{ProfileData.isActive ? 'true' : 'Deactive'}</p>
							</div>
							<div className='data'>
								<h4>LastLogin</h4>
								<p>{ProfileData.lastLogin.split('T')[0]}</p>
							</div>
						</div>
						<div className='projects_data'>
							<div className='data'>
								<h4>Role</h4>
								<Group>
									<p>{ProfileData.groups[0]} </p>
									<Tooltip
										arrow
										TransitionComponent={Zoom}
										title={
											ProfileData.groups[0] === 'developer'
												? 'All Opertions are Allowed'
												: ProfileData.groups[0] === 'viewer'
												? 'Only view is Allowed No update or Delete is Allowed'
												: ProfileData.groups[0] === 'editor'
												? 'Only Update is Allowed No Delete is Granted'
												: ProfileData.groups[0] === 'admin'
												? 'All Opertions are Allowed'
												: ProfileData.groups[0] === 'power '
												? 'All Opertions are Allowed'
												: 'Not Authorized'
										}
										placement='right'>
										<Icon>
											<FcInfo />
										</Icon>
									</Tooltip>
								</Group>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledContainer>
	);
};

export default Profile;
const StyledContainer = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	display: grid;
	place-items: center;
	.wrapper {
		width: 650px;
		height: 450px;
		display: flex;
		box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.08);
		position: relative;
	}

	.wrapper .left {
		width: 35%;
		background: linear-gradient(to right, #01a9ac, #01dbdf);
		padding: 30px 25px;
		border-top-left-radius: 5px;
		border-bottom-left-radius: 5px;
		text-align: center;
		color: #fff;
	}

	.wrapper .left img {
		border-radius: 5px;
		margin-bottom: 10px;
	}

	.wrapper .left h4 {
		margin-bottom: 10px;
	}

	.wrapper .left p {
		font-size: 12px;
	}

	.wrapper .right {
		width: 65%;
		background: #fff;
		padding: 30px 25px;
		border-top-right-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.wrapper .right .info,
	.wrapper .right .projects {
		margin-bottom: 25px;
	}

	.wrapper .right .info h3,
	.wrapper .right .projects h3 {
		margin-bottom: 15px;
		padding-bottom: 5px;
		border-bottom: 1px solid #e0e0e0;
		color: #353c4e;
		text-transform: uppercase;
		text-align: left;
		font-size: 1rem;
	}
	.info_data {
		margin-bottom: 15px;
	}
	.wrapper .right .info_data,
	.wrapper .right .projects_data {
		display: flex;
		justify-content: space-between;
	}

	.wrapper .right .info_data .data,
	.wrapper .right .projects_data .data {
		width: 45%;
	}

	.wrapper .right .info_data .data h4,
	.wrapper .right .projects_data .data h4 {
		color: #353c4e;
		margin-bottom: 5px;
		font-size: 0.9rem;
	}

	.wrapper .right .info_data .data p,
	.wrapper .right .projects_data .data p {
		font-size: 13px;
		margin-bottom: 10px;
		color: #808386;
	}

	.wrapper .social_media ul {
		display: flex;
	}

	.wrapper .social_media ul li {
		width: 45px;
		height: 45px;
		background: linear-gradient(to right, #01a9ac, #01dbdf);
		margin-right: 10px;
		border-radius: 5px;
		text-align: center;
		line-height: 45px;
	}

	.wrapper .social_media ul li a {
		color: #fff;
		display: block;
		font-size: 18px;
	}
`;
const Group = styled.div`
	display: flex;
	align-items: center;
`;
const Icon = styled.div`
	margin-left: 10px;
	display: grid;
	place-items: center;
	margin-bottom: 10px;
`;
