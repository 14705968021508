import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Table from './Table';


const TableSection = ({ Formik }) => {
	const [values, setValues] = useState('');
	//-------------------------------
	const handleProperties = (mainIdx, item, value) => {
		const properties = Formik.values.properties;
		properties[mainIdx][item] = value;
		Formik.setFieldValue('properties', properties);
	};

	//---------------
	useEffect(() => {
		setValues(Formik.values.properties);
	}, [Formik.values.properties]);
	return (
		<>
			{values &&
				values.map((data, idx) => {
					return (
						<TableConatiner key={idx}>
							<Table
								Data={data && data}
								handleChange={handleProperties}
								mainIdx={idx}
							/>
						</TableConatiner>
					);
				})}
		</>
	);
};

export default TableSection;
const TableConatiner = styled.div`
	width: 100%;
	height: 100%;
`;

