import React, { useEffect, useState } from 'react';
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import styled from 'styled-components';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useData } from '../../../Context/DataProvider';

const TableBar = ({ Data }) => {
	const { tableData, setTableData } = useData();
	const { getTableDeatils } = Api_Helper();
	const [isDrop, setIsDrop] = useState(false);
	const [mainTitle, setMainTitle] = useState('');

	const tableHandler = (data) => {
		//---------------------fetch Table function-----------------------
		getTableDeatils(data.tid, data.type);
		//---------------------------Title-----------------------)-
		setTableData((state) => ({
			...state,
			tableNameData: data.name,
			tableActive: data.name,
		}));
		setMainTitle(data.name);
	};
	useEffect(() => {
		/* setSubcategory(Data.category); */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Data]);
	return (
		<BarBox>
			{tableData.tableNavData && (
				<SideBox>
					{Data && Data.length < 6 ? (
						Data.map((data, idx) => {
							return (
								<Item
									key={idx}
									onClick={() => tableHandler(data)}
									state={tableData.tableActive === data.name}
									id={data.id}>
									<p>{data.name.substring(0, 25) + '..'}</p>
								</Item>
							);
						})
					) : (
						<DropBox>
							<Title onClick={() => setIsDrop(!isDrop)}>
								<p>
									{(mainTitle || (Data && Data[0].name)).substring(0, 30) +
										'...'}
								</p>
								{isDrop ? <RiArrowDropDownLine /> : <RiArrowDropUpLine />}
							</Title>
							{isDrop && (
								<Drop>
									{Data &&
										Data.map((data, idx) => {
											return (
												<DropItem
													key={idx}
													onClick={() => {
														tableHandler(data);
														setIsDrop(false);
													}}>
													<p>{data.name}</p>
												</DropItem>
											);
										})}
								</Drop>
							)}
						</DropBox>
					)}
				</SideBox>
			)}
		</BarBox>
	);
};

export default TableBar;
const DropItem = styled.div`
	width: 280px;
	height: 50px;
	padding: 10px;
	background-color: #ffffff;
	transition-duration: 0.8s;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	z-index: 6;
	p {
		font-size: 0.8rem;
		text-align: right;
	}
	&:hover {
		background-color: #dfdfdf;
	}
`;

const BarBox = styled.div`
	width: 100%;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-top: 30px;
`;

const SideBox = styled.div`
	width: calc(100% - 300px);
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;

export const Item = styled.div`
	background-color: ${({ state }) => (state ? '#379dbd' : '#39a9cb8d')};
	width: 160px;
	height: 100%;
	display: grid;
	place-items: center;
	border-radius: 10px 45px 0 0;
	cursor: pointer;

	p {
		font-size: 0.65rem;
		text-transform: capitalize;
		color: #fafafa;
		text-align: center;
		overflow: hidden;
		text-overflow: ellipsis;
	}
`;
const DropBox = styled.div`
	position: relative;
	display: flex;
	width: 280px;
	border-radius: 2px;
	position: relative;
`;
const Title = styled.div`
	width: 100%;
	height: 35px;
	background-color: #379dbd;
	color: #ffffff;
	display: flex;
	align-items: center;
	padding: 10px 20px;
	border-radius: 8px 8px 0 0;
	justify-content: space-between;
	cursor: pointer;
	p {
		font-size: 0.8rem;
	}
`;
const Drop = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	position: absolute;
	top: 50px;
	left: 0;
	box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
		rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
`;
