import React, { useEffect } from 'react';
import { Route } from 'react-router';
import styled from 'styled-components';
import HomeHeader from '../../Components/HomeComponent/Header/HomeHeader';
import { useData } from '../../Context/DataProvider';
import Analytics from '../Sub_Pages/Analytics';
import Portfolio from './Portfolio';

const Home = () => {
	const { setTitle } = useData();
	useEffect(() => {
		setTitle(() => ({
			title: '',
			subtitle: '',
		}));
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<HomeBody>
			<HomeHeader />
			<Route path='/portfolio' exact>
				<Portfolio/>
			</Route>
			<Route path='/analytics' exact>
				<Analytics />
			</Route>
		</HomeBody>
	);
};

export default Home;
const HomeBody = styled.div`
	width: 100%;
	height: 100%;
	padding: 20px;
	overflow-x: hidden;
`;
