import React from 'react';
import {
	useTable,
	useSortBy,
	useGlobalFilter,
	usePagination,
} from 'react-table';
import styled from 'styled-components';
//----------------------
import { GlobalFilter } from './GlobalFilter';
import { BiCheckboxSquare } from 'react-icons/bi';

//-----------------------------
import {
	MdLastPage,
	MdFirstPage,
	MdKeyboardArrowLeft,
	MdKeyboardArrowRight,
	MdKeyboardArrowUp,
	MdKeyboardArrowDown,
} from 'react-icons/md';
import moment from 'moment';
import { Link } from 'react-router-dom';
//----------------
export const BasicTable = ({
	columns,
	data,
	setValue = '',
	CSV_HEADER,
	tableName,
}) => {
	//------------------tableName------------

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		canPreviousPage,
		canNextPage,
		pageOptions,
		prepareRow,
		state,
		gotoPage,
		pageCount,
		setPageSize,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				sortBy: [
					{
						id: 'columnId',
						desc: false,
					},
				],
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	);
	const { globalFilter } = state;
	const { pageIndex, pageSize } = state;
	return (
		<>
			<GlobalFilter
				filter={globalFilter}
				setFilter={setGlobalFilter}
				CSV_Data={data}
				CSV_Header={CSV_HEADER}
			/>
			<Table {...getTableProps()}>
				<thead>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							<th>sl</th>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps(column.getSortByToggleProps())}>
									{column.render('Header')}
									<ThIcon>
										{column.isSorted ? (
											column.isSortedDesc ? (
												<MdKeyboardArrowDown />
											) : (
												<MdKeyboardArrowUp />
											)
										) : (
											''
										)}
									</ThIcon>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, idx) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								<td style={{ textAlign: 'center' }}>
									{idx + 1 + pageIndex * 10}
								</td>
								{row.cells.map((cell, idx) => {
									return (
										<td {...cell.getCellProps()} key={idx}>
											{/* //------ Its A common table so based on the header value its being adjusted--------- */}
											{cell.column.Header === 'Ref Id' ? (
												<NavLink
													to={`/general/${tableName}/edit/${cell.row.original.uid}`}>
													{cell.row.original.uid}
												</NavLink>
											) : cell.column.Header === 'status' ? (
												<Icons
													Color={
														cell.row.original.communication_status === 'New'
															? '#0805af'
															: cell.row.original.communication_status ===
															  'Open'
															? '#ECCA43'
															: cell.row.original.communication_status ===
															  'Close'
															? '#51CB93'
															: '#0805af'
													}>
													<BiCheckboxSquare />
												</Icons>
											) : cell.column.Header === 'Date' ? (
												<IconBox>
													{moment(cell.row.original.createdAt).format(
														'DD-MMM-YYYY',
													)}
												</IconBox>
											) : cell.value === undefined || cell.value === '' ? (
												'---'
											) : (
												cell.render('Cell')
											)}
											{/* ------------till here------- */}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</Table>
			<PageBox>
				<Page>
					<Buttons>
						<LastButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
							<MdFirstPage />
						</LastButton>{' '}
						<ChangeButton
							onClick={() => previousPage()}
							disabled={!canPreviousPage}>
							<MdKeyboardArrowLeft />
						</ChangeButton>{' '}
						<ChangeButton onClick={() => nextPage()} disabled={!canNextPage}>
							<MdKeyboardArrowRight />
						</ChangeButton>{' '}
						<LastButton
							onClick={() => gotoPage(pageCount - 1)}
							disabled={!canNextPage}>
							<MdLastPage />
						</LastButton>{' '}
					</Buttons>
					<PageInfo>
						<PageNo>
							Page{' '}
							<strong>
								{pageIndex + 1} of {pageOptions.length}
							</strong>{' '}
						</PageNo>
						|
						<GotoPage>
							Go to page:{' '}
							<input
								type='number'
								defaultValue={pageIndex + 1}
								onChange={(e) => {
									const pageNumber = e.target.value
										? Number(e.target.value) - 1
										: 0;
									gotoPage(pageNumber);
								}}
								style={{ width: '50px' }}
							/>
						</GotoPage>{' '}
					</PageInfo>
					<select
						value={pageSize}
						onChange={(e) => setPageSize(Number(e.target.value))}>
						{[10, 25, 50].map((pageSize) => (
							<option key={pageSize} value={pageSize}>
								Show {pageSize}
							</option>
						))}
					</select>
				</Page>
			</PageBox>
		</>
	);
};
const ThIcon = styled.span`
	font-size: 1rem;
	position: absolute;
`;
const IconBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-around;
`;
const NavLink = styled(Link)``;
const Icons = styled.p`
	color: ${({ Color }) => (Color ? Color : '')};
	font-size: 1.2rem;
	width: 100%;
	display: grid;
	place-items: center;
`;

const PageBox = styled.div`
	width: 100%;
	display: grid;
	place-items: center;
`;
const Page = styled.div`
	width: 100%;
	padding: 10px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	button {
		font-size: 0.8rem;
	}
`;
const LastButton = styled.button`
	outline: none;
	width: 25px;
	height: 25px;
	color: #ffb511;
	cursor: pointer;
	position: relative;
	svg {
		font-size: 1.2rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	border-radius: 2px;
	border: none;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 2px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	&:disabled,
	&[disable] {
		background-color: rgb(255, 255, 255);
		cursor: not-allowed;
	}
`;
const ChangeButton = styled(LastButton)`
	width: 35px;
	color: #3aa9ca;
`;
const Buttons = styled.div``;
const PageInfo = styled.div``;
const PageNo = styled.span`
	font-size: 0.9rem;
	letter-spacing: 1px;
`;
const GotoPage = styled(PageNo)`
	padding-left: 2px;
	input {
		outline: none;
		border: 1px solid #3aa9ca;
		padding: 2px;
		border-radius: 2px;
	}
`;
const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	& td,
	& th {
		border: 1px solid #ddd;
		padding: 8px;
		min-width: 55px;
		max-width: 180px;
		overflow: hidden;
	}
	& th {
		text-transform: capitalize;
	}
	tr {
		font-size: 0.8rem;

		&:hover {
			background-color: #ddd;
		}
		&:nth-child(even) {
			background-color: #ebebeb;
			&:hover {
				background-color: #ddd;
			}
		}
	}
	th {
		padding-top: 12px;
		padding-bottom: 12px;
		text-align: center;
		background-color: #39a9cb;
		color: white;
	}
`;
