import React from 'react';
import styled from 'styled-components';

const TextZone = ({ label, width,height, value }) => {
	return (
		<TextBox Width={width} Height={height}>
			<TextLabel>{label} :</TextLabel>
			<Text>{value}</Text>
		</TextBox>
	);
};

export default TextZone;
const TextBox = styled.fieldset`
	width: ${({ Width }) => (Width ? Width : '280px')};
	min-height: ${({ Height }) => (Height ? Height : '45px')};
	margin-bottom: 10px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	flex-direction: column;
	background-color: #ebe7e7;
	border: 0.2px solid #aaaaaacc;
`;
const TextLabel = styled.legend`
	font-size: 0.7rem;
	font-weight: 600;
	margin: 0 10px;
	text-transform: capitalize;
`;
const Text = styled.p`
	padding: 8px 10px;
	font-size: 0.9rem;
`;
