import React, { useEffect, useState } from 'react';
import { useParams, Route } from 'react-router-dom';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import { useData } from '../../Context/DataProvider';
import Article from '../_pages/_GENERAL_Media/Article';
import Event from '../_pages/_GENERAL_Media/Event';
import Socialmedia from '../_pages/_GENERAL_Media/Socialmedia';
import Testimonial from '../_pages/_GENERAL_Media/Testimonial';
const Media = () => {
	const { subName } = useParams();
	const [data, setData] = useState('');
	const { setTableData } = useData();
	//------------------------
	const collection_identifier = subName;
	const Data = '';
	const { getQuerys } = Api_Helper();
	const getDetails = async () => {
		try {
			setData('');
			const nonPortfolioData = await getQuerys(
				'patch',
				collection_identifier,
				Data,
			);
			setData(nonPortfolioData);
			setTableData((state) => ({
				...state,
				tableNameData: `${subName}`,
			}));
		} catch (e) {
			console.log(e);
		}
	};
	//--------------------------------
	useEffect(() => {
		subName && getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subName]);
	return (
		<>
			<Route path='/general/media/event'>
				<Event Data={data} />
			</Route>
			<Route path='/general/media/article'>
				<Article Data={data} />
			</Route>
			<Route path='/general/media/testimonial'>
				<Testimonial Data={data} />
			</Route>
			<Route path='/general/media/socialmedia'>
				<Socialmedia Data={data} />
			</Route>
		</>
	);
};

export default Media;
