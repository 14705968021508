import React from 'react'
import styled from 'styled-components';
import { FaInfoCircle } from 'react-icons/fa';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
const Warning = ({ message,top,right,left}) => {
	return (
		<>
			<WaringIcon Top={top} Right={right} >
				<Tooltip
					arrow
					TransitionComponent={Zoom}
					title={message}
					placement='top'>
					<p>
						<FaInfoCircle />
					</p>
				</Tooltip>
			</WaringIcon>
		</>
	);
};

export default Warning;
const WaringIcon = styled.div`
	color: #ffb511;
	position: absolute;
	font-size: 1.8rem;
	top: ${({ Top }) => (Top ? Top : '3px')};
	right: ${({ Right }) => (Right ? Right : '13px')};
	width: 20px;
	z-index: 4;
`;