import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ScaleLoader from 'react-spinners/ScaleLoader';

///------------------------

const ACQUISITIONS = () => {
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		window.scrollTo(0, 0);
		setTimeout(() => {
			setLoading(false);
		}, [4000]);
		return () => {
			clearInterval();
		};
	}, []);
	return (
		<>
			<AnalyticsConatiner>
				{!loading && (
					<Frame
						title='report'
						aria-invalid='false'
						src='https://datastudio.google.com/embed/reporting/7899cb9b-f502-4cbd-9a91-b7cfba0d2831/page/ncb3B'
						frameborder='0'
						allowfullscreen></Frame>
				)}
				<Loding>
					<ScaleLoader color={'#f8b115'} loading={loading} size={50} />
				</Loding>
			</AnalyticsConatiner>
		</>
	);
};

export default ACQUISITIONS;
const Frame = styled.iframe`
	margin-top: 20px;
	width: 100%;
	min-height: 190vh;
	position: relative;
`;
const AnalyticsConatiner = styled.div`
	width: 100%;
	height: 190vh;
`;

const Loding = styled.div`
	position: absolute;
	top: 50%;
	left: 60%;
	transform: translate(-50%, -50%);
`;
