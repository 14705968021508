import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import TableModalInput from './TableEditInput';
import Button from '@material-ui/core/Button';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useParams } from 'react-router-dom';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
import BackButton from '../BackButton';
import PopUp from '../PopUp';
import { useState } from 'react';
const TableEditModal = ({ Data, fetch }) => {
	const DATA = [];
	const { productQuerys } = Api_Helper();
	const { uid, type } = useParams();
	const { userPermission } = userAuthorization();
	const [popUp, setPopUp] = useState('');
	//-------------------------
	const formik = useFormik({
		initialValues: {
			table: [],
		},
		onSubmit: (values) => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	//-------------------------------------------------
	const updateTable = async () => {
		const table = formik.values.table;
		const newArr = {};
		table.forEach((item) => {
			newArr[item.keyName] = delete['keyName'] && { ...item };
		});
		//-----------------------
		const uploadData = { table: newArr };
		const Product = await productQuerys(type, uid, 'post', uploadData);
		console.log(Product);
		setPopUp('Updated');
		//-----------for the keyname issues----------------------
		fetch();
	};
	//--------------------------------------------------
	const handleTableChange = (idx, key, value) => {
		const table = formik.values.table;
		table[idx][key] = value;
		formik.setFieldValue('table', table);
	};
	///-----------------------------------------
	useEffect(() => {
		const objArray = Object.keys(Data.table);
		const Arr = [];

		objArray.map((keyName) => {
			return Arr.push({ keyName, ...[Data.table][0][keyName] });
		});
		///------------for other purpose in table and update------------------------------
		DATA.push(Arr);
		formik.resetForm({
			values: {
				table: DATA[0],
			},
			errors: { name: 'Something special' },
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Data]);
	//---------------------------------------------------------------------------
	//-----------------------------------------------------------------------
	return (
		<Forms
			onSubmit={(e) => {
				e.preventDefault();
				updateTable();
			}}>
			{popUp && <PopUp state={[popUp, setPopUp]} />}
			<BackButton Bottom='10px' />
			<Title>{Data.system_name || Data.product_name}</Title>
			{formik.values &&
				formik.values.table.map((data, idx) => {
					return (
						<InputBox key={idx}>
							<TableModalInput
								value={data.value}
								onChange={(e) => {
									handleTableChange(idx, 'value', e.target.value);
								}}
								lable={data.keyName}
								width='350px'
								required
							/>
							<TableModalInput
								value={data.note}
								onChange={(e) => {
									handleTableChange(idx, 'note', e.target.value);
								}}
								lable='Note'
							/>
						</InputBox>
					);
				})}
			<Buttons>
				<Save
					variant='contained'
					type='submit'
					disabled={userPermission.disableUpdate}
					style={{
						backgroundColor: '#ffcc00',
						fontSize: '0.9rem',
						color: '#333',
					}}>
					Update
				</Save>
			</Buttons>
		</Forms>
	);
};

export default TableEditModal;

const Forms = styled.form`
	width: 70%;
	min-height: 90%;
	background-color: #ffffff;
	padding: 20px;
	border-radius: 3px;
	margin-bottom: 40px;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	position: relative;
`;
const Title = styled.h3``;
const InputBox = styled.div`
	margin-top: 20px;
	display: flex;
	align-items: center;
	justify-content: space-around;
`;
const Buttons = styled.div`
	width: 100%;
	display: grid;
	place-items: center;
`;
const Save = styled(Button)`
	height: 35px;
	width: 150px;
`;
