import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import Application from '../../Components/ProductComponent/Appliction/Application';
import SectionContainer from '../../Components/ProductComponent/Section/SectionContainer';
import { useFormik } from 'formik';
import System from '../../Components/ProductComponent/System/System';
import * as Yup from 'yup';
import { useData } from '../../Context/DataProvider';
import Features from '../../Components/ProductComponent/Features/Features';
import ImageBox from '../../Components/ProductComponent/ImageSection/Image';
import PopUp from '../../Components/Reuseable/PopUp';
import Button from '@material-ui/core/Button';
import { RiUploadCloudLine } from 'react-icons/ri';
import { BiReset } from 'react-icons/bi';
import BarLoader from 'react-spinners/BarLoader';
import userAuthorization from '../../Adapters/Functions/userAuthorization';
const Product = () => {
	const [isLoading, setIsLoading] = useState(true);
	const { productQuerys } = Api_Helper();
	const { uid, type } = useParams();
	const { setProductData, popUp, setPopUp, error } = useData();
	const { userPermission } = userAuthorization();
	///--------------------------------Validaition-------------------
	const FormSchema = Yup.object(
		type === 'product'
			? {
					system_type: Yup.string()
						.min(10, 'Must be 10 characters or More')
						.max(100, 'Must be 100 characters or less'),
					recommended_processes: Yup.string().max(
						100,
						'Must be 100 characters or less ',
					),
					product_image: Yup.string().required('Required'),
					application: Yup.array().of(
						Yup.object().shape({
							image: Yup.string().required(' Required'),
							description: Yup.string()
								.max(50, 'Must be 50 characters or less')
								.required('Required'),
						}),
					),
			  }
			: {
					system_type: Yup.string()
						.min(10, 'Must be 10 characters or More')
						.max(100, 'Must be 100 characters or less'),
					recommended_processes: Yup.string().max(
						100,
						'Must be 100 characters or less ',
					),
					system_image: Yup.string().required('Required'),
					application: Yup.array().of(
						Yup.object().shape({
							image: Yup.string().required('Required'),
							description: Yup.string()
								.max(50, 'Must be 50 characters or less')
								.required('Required'),
						}),
					),
			  },
	);
	///----------------------------------formmik------------------------

	const formik = useFormik({
		initialValues: {},
		validationSchema: FormSchema,
		onSubmit: () => {
			updateHandler();
		},
	});
	//-----------------------------Feature----checks--------------------------
	const NoneEmpty = (arr) => {
		for (var i = 0; i < arr.length; i++) {
			if (arr[i] === '') return false;
		}
		return true;
	};
	//------------------------------Table_check-------------------------------
	const NoneEmptyTable = (arr) => {
		for (var i = 0; i < arr.length; i++) {
			const newArr = Object.values(arr[i]);
			for (var j = 0; j < newArr.length; j++) {
				if (newArr[j].heading === '') return false;
			}
		}
		return true;
	};
	//-----------------update--Product----------------------
	const updateHandler = async () => {
		try {
			if (error) {
				setPopUp(error);
			} else {
				if (NoneEmpty(formik.values.description)) {
					if (NoneEmptyTable(formik.values.properties)) {
						const Product = await productQuerys(
							type,
							uid,
							'post',
							formik.values,
						);
						console.log(Product);
						setPopUp('Updated Successful');
						///-----------------to get latest details to removw the warings ------
						FetchProduct();
					} else {
					setPopUp('Properties Table feature field cannot be empty');
					}
				} else {
					setPopUp('Feature field cannot be empty');
				}
			}
		} catch (e) {
			setPopUp('Not Allowed');
		}
	};
	//--------------------------
	const setFormik = (Data) => {
		formik.resetForm(
			type === 'system'
				? {
						values: {
							heading: Data.heading,
							system_type: Data.system_type,
							system_image: Data.system_image,
							description: Data.description,
							recommended_processes: Data.recommended_processes,
							application: Data.application,
							properties: Data.properties,
							tds_link: Data.tds_link,
							msds_link: Data.msds_link,
							regulatory: Data.regulatory,
						},
						errors: { name: 'Something special' },
				  }
				: {
						values: {
							brand_name: Data.brand_name,
							product_name: Data.product_name,
							product_image: Data.product_image,
							system_type: Data.system_type,
							description: Data.description,
							recommended_processes: Data.recommended_processes,
							application: Data.application,
							properties: Data.properties,
							tds_link: Data.tds_link,
							msds_link: Data.msds_link,
							regulatory: Data.regulatory,
						},
						errors: { name: 'Something special' },
				  },
		);
		setIsLoading(false);
	};

	// ///-----------------------------------------------------
	const FetchProduct = async () => {
		try {
			const method = 'get';
			const Response = await productQuerys(type, uid, method);
			setProductData(JSON.stringify(Response.data));
			const Data = Response.data;

			//---------------------reset Formmik-------------------
			setFormik(Data);
		} catch (e) {
			console.log(e);
		}
	};

	//---------
	useEffect(() => {
		FetchProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid]);

	return (
		<>
			{!isLoading ? (
				<ProductContainer>
					<Title>
						<TitleInputs>
							<TitleHead>
								<h1>
									{type === 'system'
										? formik.values.heading
										: formik.values.brand_name + formik.values.product_name}
								</h1>
							</TitleHead>
							{type === 'system' && <System Data={formik} />}
							<Features Data={formik} />
						</TitleInputs>
						<ImageBox Data={formik} />
					</Title>

					{type === 'product' && (
						<Details>
							<Application Data={formik} />
						</Details>
					)}
					{/*--------------------------------------- sections ------------------------------*/}
					<SectionContainer Data={formik} />
					{/* --------------------- */}
					{popUp && <PopUp state={[popUp, setPopUp]} />}
					<Buttons>
						<Button
							variant='contained'
							size='medium'
							color='primary'
							disabled={userPermission.disableUpdate}
							style={{
								marginRight: '20px',
							}}
							onClick={formik.handleSubmit}>
							Update{' '}
							<span>
								<RiUploadCloudLine />
							</span>
						</Button>
						<Button
							variant='outlined'
							size='medium'
							color='primary'
							disabled={userPermission.disableReset}
							style={{
								background: '#fff',
							}}
							onClick={() => {
								FetchProduct();
								setPopUp('Product Reseted');
							}}>
							Reset{' '}
							<span>
								<BiReset />
							</span>
						</Button>
					</Buttons>
				</ProductContainer>
			) : (
				<LoadingBox>
					<BarLoader color={'#F5A623'} loading={true} size={150} />
				</LoadingBox>
			)}
		</>
	);
};

export default Product;
const ProductContainer = styled.div`
	width: 100%;
	min-height: 100%;
	padding: 5px 20px;
	display: flex;
	align-items: center;
	flex-direction: column;
	margin-bottom: 40px;
`;
const Title = styled.div`
	width: 100%;
	height: fit-content;
	display: flex;
	justify-content: space-between;
`;

const TitleInputs = styled.div`
	width: 45%;
`;

const Details = styled.div`
	width: 100%;
	margin: 20px 0;
`;
const ItemName = styled.div`
	position: relative;
	width: 100%;
	height: 50px;
	h2 {
		text-transform: capitalize;
		text-align: left;
		font-size: 1.1rem;
	}
	&::after {
		content: '';
		width: 100px;
		height: 3px;
		background-color: #ffb511;
		position: absolute;
		bottom: 15px;
		left: 0;
	}
	&::before {
		content: '';
		width: 3px;
		height: 3px;
		background-color: #ffb511;
		border-radius: 50%;
		position: absolute;
		bottom: 15px;
		left: 105px;
	}
`;
const TitleHead = styled(ItemName)`
	height: 60px;
	width: 100%;
	margin-bottom: 20px;
	padding: 10px 0;
	h1 {
		width: 500px;
		overflow: hidden;
		font-size: 1.3rem;
		font-weight: bold;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
`;
const Buttons = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;
	position: sticky;
	bottom: 40px;
	span {
		margin-left: 5px;
		font-size: 0.9rem;
		display: flex;
		place-items: center;
		z-index: 5;
	}
`;
const LoadingBox = styled.div`
	width: 100%;
	height: 60vh;
	display: grid;
	place-items: center;
`;
