import { Button } from '@material-ui/core';
import React from 'react'
import styled from 'styled-components';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

const ResetButton = ({ ...prop }) => {
	const { userPermission } = userAuthorization();
	
    return (
			<Save
				variant='outlined'
				color='secondary'
				disabled={userPermission.disableReset}
				style={{
					fontSize: '0.9rem',
					marginRight: '10px',
				}}
				{...prop}>
				Reset
			</Save>
		);
}

export default ResetButton
const Save = styled(Button)`
	height: 35px;
`;