import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import storage from 'local-storage-fallback';
import { BsPeopleCircle, BsPersonDash } from 'react-icons/bs';
import { IoPersonAddOutline, IoPersonAddSharp } from 'react-icons/io5';
import { VscHome, VscTools } from 'react-icons/vsc';
import { MdRadioButtonUnchecked } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
const NavBar = () => {
	const [isOpen, setOpen] = useState(false);
	const history = useHistory();

	const logoutHandler = () => {
		storage.clear('Token');
		history.push('/login');
	};
	//-----------------------
	const Data = storage.getItem('ProfileData');
	const ProfileData = JSON.parse(Data);
	//---------------------
	const model = useRef();
	const dropHandler = () => {
		document.body.addEventListener('click', (e) => {
			if (e.target.id==='drop_menu') {
				setOpen(true);
			} else {
				setOpen(false);
			}
		});
	};
	useEffect(() => {
		dropHandler();
	}, []);
	//--------------
	return (
		<>
			<Nav>
				<LogoHolder to='/'>
					<img src='/Assets/logo.jpg' alt='logo' />
				</LogoHolder>
				<h1>Admin Console</h1>
				<NavMenuHolder>
					<Tooltip
						arrow
						TransitionComponent={Zoom}
						title={'Home'}
						placement='top'>
						<Icon>
							<VscHome onClick={() => history.push('/')} />
						</Icon>
					</Tooltip>
					<Tooltip
						arrow
						TransitionComponent={Zoom}
						title={'Tools'}
						placement='top'>
						<Icon>
							<a
								href='https://app.abg-am.com'
								target='_blank'
								rel='noreferrer noopener'
								aria-label='Close'>
								<VscTools />
							</a>
						</Icon>
					</Tooltip>

					<Avatar
						onClick={() => {
							setOpen(!isOpen);
						}}
						id='drop_menu'>
						{isOpen && <Round id='drop_menu' />}
						{ProfileData ? (
							ProfileData.groups[0] === 'developer' ? (
								<IoPersonAddOutline  />
							) : ProfileData.groups[0] === 'viewer' ? (
								<BsPeopleCircle  />
							) : ProfileData.groups[0] === 'editor' ? (
								<IoPersonAddSharp  />
							) : ProfileData.groups[0] === 'admin' ? (
								<IoPersonAddOutline />
							) : ProfileData.groups[0] === 'power' ? (
								<IoPersonAddOutline  />
							) : (
								<BsPersonDash id='drop_menu' />
							)
						) : (
							''
						)}
					</Avatar>
				</NavMenuHolder>
			</Nav>
			<DropMenu Open={isOpen} ref={model} id='drop_menu'>
				<Item
					to='/profile'
					onClick={() => {
						setOpen(!isOpen);
					}}>
					Profile
				</Item>
				<Item
					to=''
					onClick={() => {
						setOpen(!isOpen);
					}}>
					Setting
				</Item>
				<Item onClick={logoutHandler} to='/login'>
					Logout
				</Item>
			</DropMenu>
		</>
	);
};

export default NavBar;
const Nav = styled.nav`
	width: 100%;
	height: 70px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #379dbd;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	z-index: 5;
	padding: 0 10px;
	position: sticky;
	top: 0;
	h1 {
		font-weight: 600;
		color: #ffffff;
		letter-spacing: 1px;
		font-size: 2.1rem;
	}
`;
const LogoHolder = styled(Link)`
	width: 160px;
	height: 100%;
	img {
		width: 100px;
		object-fit: contain;
		padding: 5px;
	}
`;
const NavMenuHolder = styled.div`
	width: 160px;
	height: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	font-size: 1.3rem;
	color: #000000;
	a {
		display: grid;
		place-items: center;
	}
	svg {
		color: #ffffff;
	}
`;
const Icon = styled.div`
	display: grid;
	place-items: center;
	cursor: pointer;
`;
const DropMenu = styled.div`
	position: fixed;
	top: 70px;
	right: 0;
	width: 200px;
	background-color: #ffffff;
	z-index: 4;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-direction: column;
	transition: transform 0.8s ease;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	transform: ${(prop) => (prop.Open ? 'translateY(0%)' : 'translateY(-102%)')};
`;
const Item = styled(Link)`
	width: 100%;
	height: 50px;
	display: flex;
	align-items: flex-start;
	border-bottom: 1px solid #0303031d;
	cursor: pointer;
	padding: 10px;
	text-decoration: none;
	color: #333;
	&:hover {
		background-color: #f3f2f2;
	}
	&:last-child {
		border: none;
	}
`;
const Avatar = styled.div`
	position: relative;
	display: grid;
	place-items: center;
	cursor: pointer;
	img{
		object-fit: contain;
		width: 25px;
	}
	&::after{
		content: '';
		position: absolute;
		width:50px;
		height:50px;
	}
	
`;
const Round = styled(MdRadioButtonUnchecked)`
	color: #ffd207;
	font-size: 2.8rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
`;
