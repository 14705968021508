import React, { useState } from 'react';
import styled from 'styled-components';
// import { useData } from '../../../Context/DataProvider';
import ApplicationInput from './ApplicationInput';
/* import { useParams } from 'react-router';
import Api_Helper from '../../../Adapters/Api/Api_Helper'; */
import { FaEye, /* FaSave */ } from 'react-icons/fa';
import { RiCloseCircleLine } from 'react-icons/ri';
import { MdDelete } from 'react-icons/md';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
import { Button } from '@material-ui/core';
const ApplicationItem = ({ Formik, Item, idx, setState }) => {
	// const { setPopUp } = useData();
/* 	const { uid, type } = useParams();
	const { productQuerys } = Api_Helper(); */
	const [pop, setPop] = useState(false);
	const { userPermission } = userAuthorization();

	//--------------------------------
	const removeFroms = (name) => {
		const feilds = Formik.values.application;
		feilds.splice(name, 1);
		Formik.setFieldValue('application', feilds);
	};
	//-------------------------
	const handleApplicationChange = (idx, value) => {
		const applications = Formik.values.application;
		applications[idx] = value;
		Formik.setFieldValue('application', applications);
	};

	//----------------------------
/* 	const imageUpload = async () => {
		try {
			const Data = { application: Formik.values.application };
			const Product = await productQuerys(type, uid, 'post', Data);
			console.log(Product);
			setPopUp('Updated');
		} catch (error) {
			console.log(error);
			setPopUp('Updated');
		}
	}; */

	return (
		<>
			<AppliactionBox>
				<p>{idx + 1 + '. '}</p>
				<ImageBox htmlFor='Application_file'>
					<img
						src={Item.image}
						alt='app'
						onError={(e) => {
							e.target.src = '/Assets/loading.png';
						}}
					/>
					<View onClick={() => setPop(true)}>
						<FaEye />
					</View>
				</ImageBox>
				<ApplictionInputZone>
					<ApplicationInput
						lable='description'
						name='description'
						id={idx}
						onBlur={Item.handleBlur}
						value={Item.description}
						onChange={(e) =>
							handleApplicationChange(idx, {
								...Item,
								description: e.target.value,
							})
						}
						width='280px'
						errorData={Formik}
					/>
					<ApplicationInput
						lable='image'
						name='image'
						id={idx}
						value={Item.image}
						onBlur={Item.handleBlur}
						onChange={(e) =>
							handleApplicationChange(idx, {
								...Item,
								image: e.target.value,
							})
						}
						width='280px'
						errorData={Formik}
					/>
				</ApplictionInputZone>
				<IconsBox>
					{/* <Tooltip
						arrow
						TransitionComponent={Zoom}
						title={'Save'}
						placement='top'>
						<Button
							variant='contained'
							size='small'
							color='primary'
							disabled={userPermission.disableUpdate}
							onClick={() => imageUpload()}
							style={{
								borderRadius: '50%',
								fontSize: '1.2rem',
								maxWidth: '33px',
								maxHeight: '33px',
								minWidth: '33px',
								minHeight: '33px',
							}}>
							<FaSave />
						</Button>
					</Tooltip> */}
					<Tooltip
						arrow
						TransitionComponent={Zoom}
						title={'Delete'}
						placement='top'>
						<Button
							variant='contained'
							size='small'
							color='primary'
							disabled={userPermission.disableDelete}
							onClick={() => removeFroms(idx)}
							style={{
								borderRadius: '50%',
								fontSize: '1.2rem',
								maxWidth: '33px',
								maxHeight: '33px',
								minWidth: '33px',
								minHeight: '33px',
							}}>
							<MdDelete />
						</Button>
					</Tooltip>
				</IconsBox>
			</AppliactionBox>
			{pop && (
				<ImagePreview onClick={() => setPop(false)}>
					<img
						src={Item.image}
						alt='pic'
						onError={(e) => {
							e.target.src = '/Assets/imageHolder.svg';
						}}
					/>
					<Close>
						<RiCloseCircleLine />
					</Close>
				</ImagePreview>
			)}
		</>
	);
};

export default ApplicationItem;
const ImageBox = styled.label`
	width: 90px;
	height: 80px;
	cursor: pointer;
	position: relative;
	border-radius: 15px;
	padding: 5px;
	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
		border-radius: 15px;
		filter: grayscale(0.6);
	}
`;
const AppliactionBox = styled.div`
	width: 480px;
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		font-size: 0.7rem;
	}
`;
const ApplictionInputZone = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	height: 90px;
	justify-content: space-around;
`;
const View = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	color: #ffb511;
`;
const ImagePreview = styled.div`
	position: fixed;
	min-width: 100vw;
	height: 100vh;
	top: 0;
	left: 0;
	background-color: #333333ef;
	z-index: 55;
	display: grid;
	place-items: center;
	img {
		width: 550px;
		object-fit: contain;
		height: 600px;
	}
`;
const Close = styled.div`
	position: absolute;
	top: 10px;
	right: 20%;
	font-size: 2.5rem;
	color: #ffb511;
	cursor: pointer;
`;
const IconsBox = styled.div`
	width: 85px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	& .icon {
		color: #ffffff;
		font-size: 0.8rem;
		cursor: pointer;
		width: 30px;
		height: 30px;
		background-color: #3f51b5;
		border-radius: 50%;
		display: grid;
		place-items: center;
	}
`;
