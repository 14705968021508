import axios from 'axios';
import { useData } from '../../Context/DataProvider';
import storage from 'local-storage-fallback';
const Api_Helper = () => {
	const { setTableData, Url, setTitle } = useData();

	//----------------------------------------------

	//--------------getting  the token from loaclStorage-------------------
	const Auth_Token = storage.getItem('Token');

	///----------------------------SideMenu Common Fetch---------------------------------

	const fetchData = (type, querys, id, subId) => {
		try {
			setTableData((state) => ({ ...state, tableNavData: '', table: '' }));
			axios({
				method: 'patch',
				url: `${Url}/get-details/${querys}`,
				headers: {
					Authorization: `Token ${Auth_Token}`,
					'Content-Type': 'application/json',
				},
				data: { query: { family_id: id } },
			}).then((response) => {
				if (response.data.length > 0) {
					const CategoryItem = response.data[0].category[subId].category;
					const newCategory = CategoryItem.map((item) => {
						return { type, ...item };
					});
					getTableDeatils(
						response.data[0].category[subId].category[0].tid,
						type,
					);
					setTableData((state) => ({
						...state,
						tableNavData: newCategory,
						tableActive: response.data[0].category[subId].category[0].name,
					}));
				} else {
					console.log('error');
					console.log(response);
				}
			});
		} catch (error) {
			console.log(error);
		}
	};

	//---------------------------Table----------------------------------------------------------------------
	const getTableDeatils = async (tid, type) => {
		try {
			const response = await axios({
				method: 'patch',
				url: `${Url}/get-details/${type}`,
				headers: {
					Authorization: `Token ${Auth_Token}`,
					'Content-Type': 'application/json',
				},
				data: { query: { table_reference: tid } },
			});
			if (response.data.length > 0) {
				const CategoryItem = response.data;
				const newCategory = CategoryItem.map((item) => {
					return { type, ...item };
				});
				setTitle(() => ({
					title: newCategory[0].family_name,
					subtitle: newCategory[0].category_name,
				}));
				setTableData((state) => ({
					...state,
					table: newCategory,
					tableNameData:
						newCategory[0].subcategory_name || newCategory[0].category_name,
				}));
			} else {
				console.log('error');
				console.log(response);
			}
		} catch (error) {
			console.log(error);
		}
	};

	//--------------------------LoginToken----------------------------

	const getToken = (Name, Password) => {
		return axios({
			method: 'post',
			url: `${Url}/auth-api/`,
			headers: {
				'Content-Type': 'application/json',
			},
			data: { username: Name, password: Password },
		});
	};
	///-------------------Product----Details-----get--and---post---------------
	const productQuerys = (type, id, method, data) => {
		var Data = JSON.stringify({
			document: data,
		});
		return axios({
			method: method,
			url: `${Url}/get-details/${type}/${id}`,
			headers: {
				Authorization: `Token ${Auth_Token}`,
				'Content-Type': 'application/json',
			},
			data: Data,
		});
	};

	//-----------------------------------General--get--post--------------------------------
	const getQuerys = (method, collection_identifier, Data) => {
		return axios({
			method: method,
			url: `${Url}/get-details/${collection_identifier}`,
			headers: {
				Authorization: `Token ${Auth_Token}`,
				'Content-Type': 'application/json',
			},
			data: Data,
		});
	};
	///------------------------------Image S3 Url Api----------------------------
	const getUrl = (file, name, path) => {
		let data = new FormData();
		data.append('file', file);
		data.append('name', name);
		data.append('location', path);

		return axios({
			method: 'put',
			url: `${Url}/put-object/abg-public`,
			headers: {
				Authorization: `Token ${Auth_Token}`,
				'Content-Type': 'application/json',
			},
			data: data,
		});
	};

	//---------------------ImageVaildation--------------------
	const validation = (file, width, height) => {
		var data = new FormData();
		data.append('image', file);
		data.append('width', width);
		data.append('height', height);
		data.append('file_size', '2000');
		data.append('image_url', '');
		return axios({
			method: 'post',
			url: `https://dev.abg-am.com/api/v1/image_validation/`,
			headers: {
				Authorization: `Token ${Auth_Token}`,
				'Content-Type': 'application/json',
			},
			data: data,
		});
	};
	//------------------------
	return {
		getTableDeatils,
		getToken,
		productQuerys,
		fetchData,
		getUrl,
		getQuerys,
		validation,
	};
};

export default Api_Helper;
