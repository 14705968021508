import React from 'react';
import { MdClose } from 'react-icons/md';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
const BackButton = ({ Bottom }) => {

	const history = useHistory();
	return (
		<>
			<ButtonZone onClick={() => history.goBack()} bottom={Bottom}>
				<p>
					<MdClose />
				</p>
			</ButtonZone>
		</>
	);
};

export default BackButton;
const ButtonZone = styled.button`
	background-color: #21b6ae;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	outline: none;
	position: absolute;
	right: 5px;
	top: ${({ bottom }) => (bottom ? bottom : '5px')};
	transition: all 0.5s cubic-bezier(0.25, 0.75, 0.5, 1.25);
	cursor: pointer;
	p {
		color: #fff;
		font-size: 1.2rem;
		display: grid;
		place-items: center;
	}
	&:hover {
		box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px,
			rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
	}
`;
