import React from 'react';
import { BasicTable } from './BasicTable';
import SyncLoader from 'react-spinners/SyncLoader';
import styled from 'styled-components';

const TableSection = ({ Columu, Data, state = '', CSV ,type}) => {
	return (
		<>
			{ Columu && Data ? (
				<BasicTable
					columns={ Columu }
					data={ Data }
					setValue={ state }
					CSV_HEADER={ CSV }
					tableName={type}
				/>
			) : (
				<Spinner>
					<SyncLoader color={'#f0852dc7'} loading={true} size={10} />
				</Spinner>
			)}
		</>
	);
};

export default TableSection;
const Spinner = styled.div`
	width: 100%;
	height: calc(100vh - 200px);
	background-color: #f0f0f0;
	display: grid;
	place-items: center;
`;
