import React from 'react';
import { Route } from 'react-router-dom';
import styled from 'styled-components';
import Collaborate from '../Sub_Pages/Collaborate';
import Opportunities from '../Sub_Pages/Opportunities';
import Innovations from '../Sub_Pages/Innovations';
import Media from '../Sub_Pages/Media';
import HomeHeader from '../../Components/HomeComponent/Header/HomeHeader';
import Request from '../Sub_Pages/Request';


const General = () => {
	return (
		<GeneralBody>
			<HomeHeader />
			<GeneralMain>
				<Route path='/general/collaborate/:subName'>
					<Collaborate />
				</Route>
				<Route path='/general/opportunities/:subName'>
					<Opportunities />
				</Route>
				<Route path='/general/innovations/:subName'>
					<Innovations />
				</Route>
				<Route path='/general/media/:subName'>
					<Media />
				</Route>
				
				<Route path='/general/connect/:subName'>
					<Request/>
				</Route>
			</GeneralMain>
		</GeneralBody>
	);
};

export default General;
const GeneralBody = styled.div`
	height: 100%;
	width: 100%;
	padding: 20px;
`;
const GeneralMain = styled.div`
	height: 100%;
	width: 100%;
	margin-top: 30px;
`;
