import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import TableInput from './TableInput';
import { useData } from '../../../Context/DataProvider';
import Warning from '../../Reuseable/Warning';
//--------------------------------------------
const Table = ({ Data, handleChange, mainIdx }) => {
	const { productData } = useData();
	const Product = JSON.parse(productData);
	const [newData, setNewData] = useState('');
	const [waring, setWaring] = useState('');
	//--------------------
	const ArrData = Object.fromEntries(
		Object.entries(Data).filter((e) => e[0] !== 'table_name'),
	);
	//-----------------------
	const tableHandler = () => {
		[ArrData].forEach((data) => {
			const objArray = Object.keys(data);
			const Arr = [];
			objArray.map((item, idx) => {
				return (
					item !== 'table_name' && Arr.push({ item, ...[ArrData][0][item] })
				);
			});
			setNewData(Arr);
		});
	};
	//-------------------------------------
	const warning = () => {
		if (JSON.stringify(Data) === JSON.stringify(Product.properties[mainIdx])) {
			setWaring('');
		} else {
			setWaring('Input field changed');

		}
	};
	//---------------------------------
	useEffect(() => {
		Data && tableHandler();
		Data && Product && warning();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(Data)]);
	return (
		<Form>
			{waring && <Warning message={waring} top='5px' right='18px' />}
			<h3>{Data && Data.table_name}</h3>
			{Data && ArrData && (
				<TableBox>
					<TableHead>
						<tr>
							<th>Feature</th>
							<th>Value</th>
							<th>Unit</th>
							<th>Test Method</th>
							<th>Note</th>
						</tr>
					</TableHead>

					{Data && newData && (
						<TableBody>
							{newData.map(function (item, idx) {
								return (
									<tr key={idx}>
										<td>
											<TableInput
												value={item.heading || ''}
												onChange={(e) => {
													handleChange(mainIdx, item.item, {
														heading: e.target.value,
														note: item.note || '',
														unit: item.unit || '',
														value: item.value || '',
														method: item.method || '',
													});
												} }
												id={mainIdx + item.heading}
												name={item.heading}
											/>
										</td>
										<td>
											<TableInput
												value={item.value || ''}
												name={item.heading}
												onChange={(e) => {
													handleChange(mainIdx, item.item, {
														heading: item.heading || '',
														value: e.target.value,
														unit: item.unit || '',
														note: item.note || '',
														method: item.method || '',
													});
												}}
											/>
										</td>
										<td>
											<TableInput
												value={item.unit || ''}
												name={item.heading}
												onChange={(e) => {
													handleChange(mainIdx, item.item, {
														heading: item.heading || '',
														value: item.value,
														unit: e.target.value,
														note: item.note || '',
														method: item.method || '',
													});
												}}
											/>
										</td>
										<td>
											<TableInput
												value={item.method || ''}
												name={item.heading}
												onChange={(e) => {
													handleChange(mainIdx, item.item, {
														heading: item.heading || '',
														value: item.value,
														unit: item.unit || '',
														note: item.note || '',
														method: e.target.value,
													});
												}}
											/>
										</td>
										<td>
											<TableInput
												value={item.note || ''}
												name={item.note}
												onChange={(e) => {
													handleChange(mainIdx, item.item, {
														heading: item.heading || '',
														value: item.value,
														unit: item.unit || '',
														note: e.target.value,
														method: item.method || '',
													});
												}}
											/>
										</td>
									</tr>
								);
							})}
						</TableBody>
					)}
				</TableBox>
			)}
		</Form>
	);
};

export default Table;
const Form = styled.form`
	width: 100%;
	margin-bottom: 10px;
	h3 {
		font-weight: 600;
		padding: 10px 0;
	}
	background-color: #ffffff;
	border: 2px dotted #333;
	padding: 20px;
	position: relative;
`;
const TableBox = styled.table`
	width: 100%;
	th,
	td {
		padding: 0.5em;
		vertical-align: middle;
		text-align: center;
	}
`;
const TableHead = styled.thead`
	overflow: hidden;
	height: 35px;
	th {
		background-color: #39a9cb;
		font-weight: normal;
		text-align: center;
		color: white;
		padding: 5px;
		min-width: 150px;
	}
`;
const TableBody = styled.tbody`
	overflow: auto;
	tr {
		margin-bottom: 5em;
		max-height: 5px;
		text-align: center;
	}
	td {
		text-align: center;
	}
`;
