import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { AiOutlineCloseSquare } from 'react-icons/ai';
const Message = ['Updated Successful', 'Image Updated', 'Updated', 'UpLoaded'];

const PopUp = ({ state }) => {
	const [message, setMessage] = state;
	useEffect(() => {
		setTimeout(() => {
			setMessage();
		}, [3000]);
		return () => {
			clearInterval();
		};
	}, [setMessage]);
	return (
		<>
			<ErrorContainer onClick={() => setMessage('')} message={message}>
				<p>{message}</p>
				<Close size={25} style={{ color: '#ffffff' }} />
			</ErrorContainer>
		</>
	);
};

export default PopUp;
const MoveIn = keyframes`
from{
    transform:translateX(100%);

}to{
    transform:translateX(0%);
}
`;
const ErrorContainer = styled.div`
	width: 235px;
	min-width: 300px;
	max-width: 330px;
	min-height: 40px;
	background-color: ${(prop) =>
		Message.includes(prop.message) ? '#28DF99' : '#F8B115'};
	display: flex;
	align-items: center;
	justify-content: space-around;
	border-radius: 5px;
	position: fixed;
	bottom: 55px;
	right: 5px;
	padding: 5px;
	cursor: pointer;
	animation: 0.8s ${MoveIn} cubic-bezier(0.51, 0.92, 0.24, 1.15);
	z-index: 6;

	p {
		color: #000000;
		letter-spacing: 1px;
		font-size: 0.7rem;
		font-weight: bold;
		width: 290px;
		margin-left: 5px;
		font-weight: 600;
	}
`;
const Close = styled(AiOutlineCloseSquare)``;
