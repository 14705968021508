export const availability = {
	name: 'Availabel regions',
	type: 'availability',
	color: '#cc22eeeb',
	location: [
		{
			country: 'Bahrain',
			alpha2: 'BH',
			alpha3: 'BHR',
			numeric: 48,
			latitude: 26,
			longitude: 50.55,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Cyprus',
			alpha2: 'CY',
			alpha3: 'CYP',
			numeric: 196,
			latitude: 35,
			longitude: 33,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Egypt',
			alpha2: 'EG',
			alpha3: 'EGY',
			numeric: 818,
			latitude: 27,
			longitude: 30,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Iran',
			alpha2: 'IR',
			alpha3: 'IRN',
			numeric: 364,
			latitude: 32,
			longitude: 53,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Iraq',
			alpha2: 'IQ',
			alpha3: 'IRQ',
			numeric: 368,
			latitude: 33,
			longitude: 44,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Israel',
			alpha2: 'IL',
			alpha3: 'ISR',
			numeric: 376,
			latitude: 31.5,
			longitude: 34.75,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Jordan',
			alpha2: 'JO',
			alpha3: 'JOR',
			numeric: 400,
			latitude: 31,
			longitude: 36,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Kuwait',
			alpha2: 'KW',
			alpha3: 'KWT',
			numeric: 414,
			latitude: 29.3375,
			longitude: 47.6581,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Lebanon',
			alpha2: 'LB',
			alpha3: 'LBN',
			numeric: 422,
			latitude: 33.8333,
			longitude: 35.8333,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Oman',
			alpha2: 'OM',
			alpha3: 'OMN',
			numeric: 512,
			latitude: 21,
			longitude: 57,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Qatar',
			alpha2: 'QA',
			alpha3: 'QAT',
			numeric: 634,
			latitude: 25.5,
			longitude: 51.25,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Saudi Arabia',
			alpha2: 'SA',
			alpha3: 'SAU',
			numeric: 682,
			latitude: 25,
			longitude: 45,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Syrian',
			alpha2: 'SY',
			alpha3: 'SYR',
			numeric: 760,
			latitude: 35,
			longitude: 38,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Turkey',
			alpha2: 'TR',
			alpha3: 'TUR',
			numeric: 792,
			latitude: 39,
			longitude: 35,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'United Arab Emirates',
			alpha2: 'AE',
			alpha3: 'ARE',
			numeric: 784,
			latitude: 24,
			longitude: 54,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'Yemen',
			alpha2: 'YE',
			alpha3: 'YEM',
			numeric: 887,
			latitude: 15,
			longitude: 48,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			country: 'India',
			alpha2: 'IN',
			alpha3: 'IND',
			numeric: 356,
			latitude: 20,
			longitude: 77,
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			name: 'Algeria',

			alpha3: 'DZA',
			'country-code': '012',
			'iso_3166-2': 'ISO 3166-2:DZ',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Angola',

			alpha3: 'AGO',
			'country-code': '024',
			'iso_3166-2': 'ISO 3166-2:AO',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Benin',
			'alpha-2': 'BJ',
			alpha3: 'BEN',
			'country-code': '204',
			'iso_3166-2': 'ISO 3166-2:BJ',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Botswana',
			'alpha-2': 'BW',
			alpha3: 'BWA',
			'country-code': '072',
			'iso_3166-2': 'ISO 3166-2:BW',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Southern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '018',
			id: 'a1',
		},
		{
			name: 'British Indian Ocean Territory',
			'alpha-2': 'IO',
			alpha3: 'IOT',
			'country-code': '086',
			'iso_3166-2': 'ISO 3166-2:IO',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Burkina Faso',
			'alpha-2': 'BF',
			alpha3: 'BFA',
			'country-code': '854',
			'iso_3166-2': 'ISO 3166-2:BF',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Burundi',
			'alpha-2': 'BI',
			alpha3: 'BDI',
			'country-code': '108',
			'iso_3166-2': 'ISO 3166-2:BI',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Cabo Verde',
			'alpha-2': 'CV',
			alpha3: 'CPV',
			'country-code': '132',
			'iso_3166-2': 'ISO 3166-2:CV',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Cameroon',
			'alpha-2': 'CM',
			alpha3: 'CMR',
			'country-code': '120',
			'iso_3166-2': 'ISO 3166-2:CM',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Central African Republic',
			'alpha-2': 'CF',
			alpha3: 'CAF',
			'country-code': '140',
			'iso_3166-2': 'ISO 3166-2:CF',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Chad',
			'alpha-2': 'TD',
			alpha3: 'TCD',
			'country-code': '148',
			'iso_3166-2': 'ISO 3166-2:TD',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Comoros',
			'alpha-2': 'KM',
			alpha3: 'COM',
			'country-code': '174',
			'iso_3166-2': 'ISO 3166-2:KM',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Congo',
			'alpha-2': 'CG',
			alpha3: 'COG',
			'country-code': '178',
			'iso_3166-2': 'ISO 3166-2:CG',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Congo, Democratic Republic of the',
			'alpha-2': 'CD',
			alpha3: 'COD',
			'country-code': '180',
			'iso_3166-2': 'ISO 3166-2:CD',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: "Côte d'Ivoire",
			'alpha-2': 'CI',
			alpha3: 'CIV',
			'country-code': '384',
			'iso_3166-2': 'ISO 3166-2:CI',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Djibouti',
			'alpha-2': 'DJ',
			alpha3: 'DJI',
			'country-code': '262',
			'iso_3166-2': 'ISO 3166-2:DJ',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Egypt',
			'alpha-2': 'EG',
			alpha3: 'EGY',
			'country-code': '818',
			'iso_3166-2': 'ISO 3166-2:EG',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Equatorial Guinea',
			'alpha-2': 'GQ',
			alpha3: 'GNQ',
			'country-code': '226',
			'iso_3166-2': 'ISO 3166-2:GQ',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Eritrea',
			'alpha-2': 'ER',
			alpha3: 'ERI',
			'country-code': '232',
			'iso_3166-2': 'ISO 3166-2:ER',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Eswatini',
			'alpha-2': 'SZ',
			alpha3: 'SWZ',
			'country-code': '748',
			'iso_3166-2': 'ISO 3166-2:SZ',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Southern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '018',
			id: 'a1',
		},
		{
			name: 'Ethiopia',
			'alpha-2': 'ET',
			alpha3: 'ETH',
			'country-code': '231',
			'iso_3166-2': 'ISO 3166-2:ET',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'French Southern Territories',
			'alpha-2': 'TF',
			alpha3: 'ATF',
			'country-code': '260',
			'iso_3166-2': 'ISO 3166-2:TF',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Gabon',
			'alpha-2': 'GA',
			alpha3: 'GAB',
			'country-code': '266',
			'iso_3166-2': 'ISO 3166-2:GA',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Gambia',
			'alpha-2': 'GM',
			alpha3: 'GMB',
			'country-code': '270',
			'iso_3166-2': 'ISO 3166-2:GM',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Ghana',
			'alpha-2': 'GH',
			alpha3: 'GHA',
			'country-code': '288',
			'iso_3166-2': 'ISO 3166-2:GH',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Guinea',
			'alpha-2': 'GN',
			alpha3: 'GIN',
			'country-code': '324',
			'iso_3166-2': 'ISO 3166-2:GN',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Guinea-Bissau',
			'alpha-2': 'GW',
			alpha3: 'GNB',
			'country-code': '624',
			'iso_3166-2': 'ISO 3166-2:GW',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Kenya',
			'alpha-2': 'KE',
			alpha3: 'KEN',
			'country-code': '404',
			'iso_3166-2': 'ISO 3166-2:KE',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Lesotho',
			'alpha-2': 'LS',
			alpha3: 'LSO',
			'country-code': '426',
			'iso_3166-2': 'ISO 3166-2:LS',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Southern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '018',
			id: 'a1',
		},
		{
			name: 'Liberia',
			'alpha-2': 'LR',
			alpha3: 'LBR',
			'country-code': '430',
			'iso_3166-2': 'ISO 3166-2:LR',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Libya',
			'alpha-2': 'LY',
			alpha3: 'LBY',
			'country-code': '434',
			'iso_3166-2': 'ISO 3166-2:LY',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Madagascar',
			'alpha-2': 'MG',
			alpha3: 'MDG',
			'country-code': '450',
			'iso_3166-2': 'ISO 3166-2:MG',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Malawi',
			'alpha-2': 'MW',
			alpha3: 'MWI',
			'country-code': '454',
			'iso_3166-2': 'ISO 3166-2:MW',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Mali',
			'alpha-2': 'ML',
			alpha3: 'MLI',
			'country-code': '466',
			'iso_3166-2': 'ISO 3166-2:ML',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Mauritania',
			'alpha-2': 'MR',
			alpha3: 'MRT',
			'country-code': '478',
			'iso_3166-2': 'ISO 3166-2:MR',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Mauritius',
			'alpha-2': 'MU',
			alpha3: 'MUS',
			'country-code': '480',
			'iso_3166-2': 'ISO 3166-2:MU',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Mayotte',
			'alpha-2': 'YT',
			alpha3: 'MYT',
			'country-code': '175',
			'iso_3166-2': 'ISO 3166-2:YT',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Morocco',
			'alpha-2': 'MA',
			alpha3: 'MAR',
			'country-code': '504',
			'iso_3166-2': 'ISO 3166-2:MA',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Mozambique',
			'alpha-2': 'MZ',
			alpha3: 'MOZ',
			'country-code': '508',
			'iso_3166-2': 'ISO 3166-2:MZ',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Namibia',
			'alpha-2': 'NA',
			alpha3: 'NAM',
			'country-code': '516',
			'iso_3166-2': 'ISO 3166-2:NA',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Southern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '018',
			id: 'a1',
		},
		{
			name: 'Niger',
			'alpha-2': 'NE',
			alpha3: 'NER',
			'country-code': '562',
			'iso_3166-2': 'ISO 3166-2:NE',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Nigeria',
			'alpha-2': 'NG',
			alpha3: 'NGA',
			'country-code': '566',
			'iso_3166-2': 'ISO 3166-2:NG',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Réunion',
			'alpha-2': 'RE',
			alpha3: 'REU',
			'country-code': '638',
			'iso_3166-2': 'ISO 3166-2:RE',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Rwanda',
			'alpha-2': 'RW',
			alpha3: 'RWA',
			'country-code': '646',
			'iso_3166-2': 'ISO 3166-2:RW',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Saint Helena, Ascension and Tristan da Cunha',
			'alpha-2': 'SH',
			alpha3: 'SHN',
			'country-code': '654',
			'iso_3166-2': 'ISO 3166-2:SH',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Sao Tome and Principe',
			'alpha-2': 'ST',
			alpha3: 'STP',
			'country-code': '678',
			'iso_3166-2': 'ISO 3166-2:ST',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Middle Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '017',
			id: 'a1',
		},
		{
			name: 'Senegal',
			'alpha-2': 'SN',
			alpha3: 'SEN',
			'country-code': '686',
			'iso_3166-2': 'ISO 3166-2:SN',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Seychelles',
			'alpha-2': 'SC',
			alpha3: 'SYC',
			'country-code': '690',
			'iso_3166-2': 'ISO 3166-2:SC',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Sierra Leone',
			'alpha-2': 'SL',
			alpha3: 'SLE',
			'country-code': '694',
			'iso_3166-2': 'ISO 3166-2:SL',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Western Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '011',
			id: 'a1',
		},
		{
			name: 'Somalia',
			'alpha-2': 'SO',
			alpha3: 'SOM',
			'country-code': '706',
			'iso_3166-2': 'ISO 3166-2:SO',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'South Africa',
			'alpha-2': 'ZA',
			alpha3: 'ZAF',
			'country-code': '710',
			'iso_3166-2': 'ISO 3166-2:ZA',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Southern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '018',
			id: 'a1',
		},
		{
			name: 'South Sudan',
			'alpha-2': 'SS',
			alpha3: 'SSD',
			'country-code': '728',
			'iso_3166-2': 'ISO 3166-2:SS',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Sudan',
			'alpha-2': 'SD',
			alpha3: 'SDN',
			'country-code': '729',
			'iso_3166-2': 'ISO 3166-2:SD',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Tanzania, United Republic of',
			'alpha-2': 'TZ',
			alpha3: 'TZA',
			'country-code': '834',
			'iso_3166-2': 'ISO 3166-2:TZ',
			region: 'IMEA Region',

			id: 'a1',
		},
		{
			name: 'Togo',
			alpha3: 'TGO',
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			name: 'Tunisia',
			alpha3: 'TUN',
			region: 'IMEA Region',
			id: 'a1',
		},
		{
			name: 'Uganda',
			'alpha-2': 'UG',
			alpha3: 'UGA',
			'country-code': '800',
			'iso_3166-2': 'ISO 3166-2:UG',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Western Sahara',
			'alpha-2': 'EH',
			alpha3: 'ESH',
			'country-code': '732',
			'iso_3166-2': 'ISO 3166-2:EH',
			region: 'IMEA Region',
			'sub-region': 'Northern Africa',
			'intermediate-region': '',
			'region-code': '002',
			'sub-region-code': '015',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Zambia',
			'alpha-2': 'ZM',
			alpha3: 'ZMB',
			'country-code': '894',
			'iso_3166-2': 'ISO 3166-2:ZM',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Zimbabwe',
			'alpha-2': 'ZW',
			alpha3: 'ZWE',
			'country-code': '716',
			'iso_3166-2': 'ISO 3166-2:ZW',
			region: 'IMEA Region',
			'sub-region': 'Sub-Saharan Africa',
			'intermediate-region': 'Eastern Africa',
			'region-code': '002',
			'sub-region-code': '202',
			'intermediate-region-code': '014',
			id: 'a1',
		},
		{
			name: 'Brunei Darussalam',
			'alpha-2': 'BN',
			alpha3: 'BRN',
			'country-code': '096',
			'iso_3166-2': 'ISO 3166-2:BN',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Cambodia',
			'alpha-2': 'KH',
			alpha3: 'KHM',
			'country-code': '116',
			'iso_3166-2': 'ISO 3166-2:KH',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Indonesia',
			'alpha-2': 'ID',
			alpha3: 'IDN',
			'country-code': '360',
			'iso_3166-2': 'ISO 3166-2:ID',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: "Lao People's Democratic Republic",
			'alpha-2': 'LA',
			alpha3: 'LAO',
			'country-code': '418',
			'iso_3166-2': 'ISO 3166-2:LA',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Malaysia',
			'alpha-2': 'MY',
			alpha3: 'MYS',
			'country-code': '458',
			'iso_3166-2': 'ISO 3166-2:MY',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Myanmar',
			'alpha-2': 'MM',
			alpha3: 'MMR',
			'country-code': '104',
			'iso_3166-2': 'ISO 3166-2:MM',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Philippines',
			'alpha-2': 'PH',
			alpha3: 'PHL',
			'country-code': '608',
			'iso_3166-2': 'ISO 3166-2:PH',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Singapore',
			'alpha-2': 'SG',
			alpha3: 'SGP',
			'country-code': '702',
			'iso_3166-2': 'ISO 3166-2:SG',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Thailand',
			'alpha-2': 'TH',
			alpha3: 'THA',
			'country-code': '764',
			'iso_3166-2': 'ISO 3166-2:TH',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Timor-Leste',
			'alpha-2': 'TL',
			alpha3: 'TLS',
			'country-code': '626',
			'iso_3166-2': 'ISO 3166-2:TL',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Viet Nam',
			'alpha-2': 'VN',
			alpha3: 'VNM',
			'country-code': '704',
			'iso_3166-2': 'ISO 3166-2:VN',
			region: 'SEA Region',
			subregion: 'South-eastern Asia',
			'intermediate-region': '',
			'region-code': '142',
			'sub-region-code': '035',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			country: 'China',
			region: 'CHINA Region',
			alpha2: 'CN',
			alpha3: 'CHN',
			numeric: 156,
			latitude: 35,
			longitude: 105,
			id: 'a1',
		},
		{
			country: 'Korea',
			region: 'KOREA Region',
			alpha2: 'KP',
			alpha3: 'PRK',
			numeric: 408,
			latitude: 40,
			longitude: 127,
			id: 'a1',
		},
		{
			country: 'Korea',
			region: 'KOREA Region',
			alpha2: 'KR',
			alpha3: 'KOR',
			numeric: 410,
			latitude: 37,
			longitude: 127.5,
			id: 'a1',
		},
		{
			country: 'United States',
			region: 'USA Region',
			alpha2: 'US',
			alpha3: 'USA',
			numeric: 840,
			latitude: 38,
			longitude: -97,
			id: 'a1',
		},
		{
			country: 'Canada',
			region: 'CANADA Region',
			alpha2: 'CA',
			alpha3: 'CAN',
			numeric: 124,
			latitude: 60,
			longitude: -95,
			id:'a1',
		},
		{
			name: 'Åland Islands',
			'alpha-2': 'AX',
			alpha3: 'ALA',
			'country-code': '248',
			'iso_3166-2': 'ISO 3166-2:AX',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Albania',
			'alpha-2': 'AL',
			alpha3: 'ALB',
			'country-code': '008',
			'iso_3166-2': 'ISO 3166-2:AL',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Andorra',
			'alpha-2': 'AD',
			alpha3: 'AND',
			'country-code': '020',
			'iso_3166-2': 'ISO 3166-2:AD',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Austria',
			'alpha-2': 'AT',
			alpha3: 'AUT',
			'country-code': '040',
			'iso_3166-2': 'ISO 3166-2:AT',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Belarus',
			'alpha-2': 'BY',
			alpha3: 'BLR',
			'country-code': '112',
			'iso_3166-2': 'ISO 3166-2:BY',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Belgium',
			'alpha-2': 'BE',
			alpha3: 'BEL',
			'country-code': '056',
			'iso_3166-2': 'ISO 3166-2:BE',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Bosnia and Herzegovina',
			'alpha-2': 'BA',
			alpha3: 'BIH',
			'country-code': '070',
			'iso_3166-2': 'ISO 3166-2:BA',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Bulgaria',
			'alpha-2': 'BG',
			alpha3: 'BGR',
			'country-code': '100',
			'iso_3166-2': 'ISO 3166-2:BG',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Croatia',
			'alpha-2': 'HR',
			alpha3: 'HRV',
			'country-code': '191',
			'iso_3166-2': 'ISO 3166-2:HR',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Czechia',
			'alpha-2': 'CZ',
			alpha3: 'CZE',
			'country-code': '203',
			'iso_3166-2': 'ISO 3166-2:CZ',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Denmark',
			'alpha-2': 'DK',
			alpha3: 'DNK',
			'country-code': '208',
			'iso_3166-2': 'ISO 3166-2:DK',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Estonia',
			'alpha-2': 'EE',
			alpha3: 'EST',
			'country-code': '233',
			'iso_3166-2': 'ISO 3166-2:EE',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Faroe Islands',
			'alpha-2': 'FO',
			alpha3: 'FRO',
			'country-code': '234',
			'iso_3166-2': 'ISO 3166-2:FO',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Finland',
			'alpha-2': 'FI',
			alpha3: 'FIN',
			'country-code': '246',
			'iso_3166-2': 'ISO 3166-2:FI',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		
		{
			name: 'Germany',
			'alpha-2': 'DE',
			alpha3: 'DEU',
			'country-code': '276',
			'iso_3166-2': 'ISO 3166-2:DE',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Gibraltar',
			'alpha-2': 'GI',
			alpha3: 'GIB',
			'country-code': '292',
			'iso_3166-2': 'ISO 3166-2:GI',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Greece',
			'alpha-2': 'GR',
			alpha3: 'GRC',
			'country-code': '300',
			'iso_3166-2': 'ISO 3166-2:GR',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Guernsey',
			'alpha-2': 'GG',
			alpha3: 'GGY',
			'country-code': '831',
			'iso_3166-2': 'ISO 3166-2:GG',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': 'Channel Islands',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '830',
			id: 'a1',
		},
		{
			name: 'Holy See',
			'alpha-2': 'VA',
			alpha3: 'VAT',
			'country-code': '336',
			'iso_3166-2': 'ISO 3166-2:VA',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Hungary',
			'alpha-2': 'HU',
			alpha3: 'HUN',
			'country-code': '348',
			'iso_3166-2': 'ISO 3166-2:HU',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Iceland',
			'alpha-2': 'IS',
			alpha3: 'ISL',
			'country-code': '352',
			'iso_3166-2': 'ISO 3166-2:IS',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Ireland',
			'alpha-2': 'IE',
			alpha3: 'IRL',
			'country-code': '372',
			'iso_3166-2': 'ISO 3166-2:IE',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Isle of Man',
			'alpha-2': 'IM',
			alpha3: 'IMN',
			'country-code': '833',
			'iso_3166-2': 'ISO 3166-2:IM',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Italy',
			'alpha-2': 'IT',
			alpha3: 'ITA',
			'country-code': '380',
			'iso_3166-2': 'ISO 3166-2:IT',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Jersey',
			'alpha-2': 'JE',
			alpha3: 'JEY',
			'country-code': '832',
			'iso_3166-2': 'ISO 3166-2:JE',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': 'Channel Islands',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '830',
			id: 'a1',
		},
		{
			name: 'Latvia',
			'alpha-2': 'LV',
			alpha3: 'LVA',
			'country-code': '428',
			'iso_3166-2': 'ISO 3166-2:LV',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Liechtenstein',
			'alpha-2': 'LI',
			alpha3: 'LIE',
			'country-code': '438',
			'iso_3166-2': 'ISO 3166-2:LI',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Lithuania',
			'alpha-2': 'LT',
			alpha3: 'LTU',
			'country-code': '440',
			'iso_3166-2': 'ISO 3166-2:LT',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Luxembourg',
			'alpha-2': 'LU',
			alpha3: 'LUX',
			'country-code': '442',
			'iso_3166-2': 'ISO 3166-2:LU',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Malta',
			'alpha-2': 'MT',
			alpha3: 'MLT',
			'country-code': '470',
			'iso_3166-2': 'ISO 3166-2:MT',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Moldova, Republic of',
			'alpha-2': 'MD',
			alpha3: 'MDA',
			'country-code': '498',
			'iso_3166-2': 'ISO 3166-2:MD',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Monaco',
			'alpha-2': 'MC',
			alpha3: 'MCO',
			'country-code': '492',
			'iso_3166-2': 'ISO 3166-2:MC',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Montenegro',
			'alpha-2': 'ME',
			alpha3: 'MNE',
			'country-code': '499',
			'iso_3166-2': 'ISO 3166-2:ME',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Netherlands',
			'alpha-2': 'NL',
			alpha3: 'NLD',
			'country-code': '528',
			'iso_3166-2': 'ISO 3166-2:NL',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'North Macedonia',
			'alpha-2': 'MK',
			alpha3: 'MKD',
			'country-code': '807',
			'iso_3166-2': 'ISO 3166-2:MK',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Norway',
			'alpha-2': 'NO',
			alpha3: 'NOR',
			'country-code': '578',
			'iso_3166-2': 'ISO 3166-2:NO',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Poland',
			'alpha-2': 'PL',
			alpha3: 'POL',
			'country-code': '616',
			'iso_3166-2': 'ISO 3166-2:PL',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Portugal',
			'alpha-2': 'PT',
			alpha3: 'PRT',
			'country-code': '620',
			'iso_3166-2': 'ISO 3166-2:PT',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Romania',
			'alpha-2': 'RO',
			alpha3: 'ROU',
			'country-code': '642',
			'iso_3166-2': 'ISO 3166-2:RO',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Russian Federation',
			'alpha-2': 'RU',
			alpha3: 'RUS',
			'country-code': '643',
			'iso_3166-2': 'ISO 3166-2:RU',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'San Marino',
			'alpha-2': 'SM',
			alpha3: 'SMR',
			'country-code': '674',
			'iso_3166-2': 'ISO 3166-2:SM',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Serbia',
			'alpha-2': 'RS',
			alpha3: 'SRB',
			'country-code': '688',
			'iso_3166-2': 'ISO 3166-2:RS',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Slovakia',
			'alpha-2': 'SK',
			alpha3: 'SVK',
			'country-code': '703',
			'iso_3166-2': 'ISO 3166-2:SK',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Slovenia',
			'alpha-2': 'SI',
			alpha3: 'SVN',
			'country-code': '705',
			'iso_3166-2': 'ISO 3166-2:SI',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Spain',
			'alpha-2': 'ES',
			alpha3: 'ESP',
			'country-code': '724',
			'iso_3166-2': 'ISO 3166-2:ES',
			region: 'EU Region',
			subregion: 'Southern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '039',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Svalbard and Jan Mayen',
			'alpha-2': 'SJ',
			alpha3: 'SJM',
			'country-code': '744',
			'iso_3166-2': 'ISO 3166-2:SJ',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Sweden',
			'alpha-2': 'SE',
			alpha3: 'SWE',
			'country-code': '752',
			'iso_3166-2': 'ISO 3166-2:SE',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Switzerland',
			'alpha-2': 'CH',
			alpha3: 'CHE',
			'country-code': '756',
			'iso_3166-2': 'ISO 3166-2:CH',
			region: 'EU Region',
			subregion: 'Western Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '155',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'Ukraine',
			'alpha-2': 'UA',
			alpha3: 'UKR',
			'country-code': '804',
			'iso_3166-2': 'ISO 3166-2:UA',
			region: 'EU Region',
			subregion: 'Eastern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '151',
			'intermediate-region-code': '',
			id: 'a1',
		},
		{
			name: 'United Kingdom of Great Britain and Northern Ireland',
			'alpha-2': 'GB',
			alpha3: 'GBR',
			'country-code': '826',
			'iso_3166-2': 'ISO 3166-2:GB',
			region: 'EU Region',
			subregion: 'Northern Europe',
			'intermediate-region': '',
			'region-code': '150',
			'sub-region-code': '154',
			'intermediate-region-code': '',
			id: 'a1',
		},
	],
};

export const salesOffice = {
	name: 'Availabel regions',
	type: 'availability',
	color: '#cc22eeeb',
	location: [
		{
			country: 'Spain',
			alpha2: 'ES',
			alpha3: 'ESP',
			numeric: 724,
			latitude: 40,
			longitude: -4,
			id: 'd1',
		},
		{
			country: 'France',
			alpha2: 'FR',
			alpha3: 'FRA',
			numeric: 250,
			latitude: 46,
			longitude: 2,
			id: 'd1',
		},
		{
			country: 'Belgium',
			alpha2: 'BE',
			alpha3: 'BEL',
			numeric: 56,
			latitude: 50.8333,
			longitude: 4,
			id: 'd1',
		},
		{
			country: 'United Kingdom',
			alpha2: 'GB',
			alpha3: 'GBR',
			numeric: 826,
			latitude: 54,
			longitude: -2,
			id: 'd1',
		},
		{
			country: 'South Africa',
			alpha2: 'ZA',
			alpha3: 'ZAF',
			numeric: 710,
			latitude: -29,
			longitude: 24,
			id: 'd1',
		},
		{
			country: 'Romania',
			alpha2: 'RO',
			alpha3: 'ROU',
			numeric: 642,
			latitude: 46,
			longitude: 25,
			id: 'd1',
		},
		{
			country: 'Turkey',
			alpha2: 'TR',
			alpha3: 'TUR',
			numeric: 792,
			latitude: 39,
			longitude: 35,
			id: 'd1',
		},
		{
			country: 'Iran',
			alpha2: 'IR',
			alpha3: 'IRN',
			numeric: 364,
			latitude: 32,
			longitude: 53,
			id: 'd1',
		},
		{
			country: 'South Africa',
			alpha2: 'ZA',
			alpha3: 'ZAF',
			numeric: 710,
			latitude: -29,
			longitude: 24,
			id: 'd1',
		},
		{
			country: 'Pakistan',
			alpha2: 'PK',
			alpha3: 'PAK',
			numeric: 586,
			latitude: 30,
			longitude: 70,
			id: 'd1',
		},
		{
			country: 'India',
			alpha2: 'IN',
			alpha3: 'IND',
			numeric: 356,
			latitude: 23,
			longitude: 73,
			id: 'd1',
		},

		{
			country: 'Viet Nam',
			alpha2: 'VN',
			alpha3: 'VNM',
			numeric: 704,
			latitude: 16,
			longitude: 106,
			id: 'd1',
		},
		{
			country: 'Malaysia',
			alpha2: 'MY',
			alpha3: 'MYS',
			numeric: 458,
			latitude: 2.5,
			longitude: 112.5,
			id: 'd1',
		},
		{
			country: 'Indonesia',
			alpha2: 'ID',
			alpha3: 'IDN',
			numeric: 360,
			latitude: -5,
			longitude: 120,
			id: 'd1',
		},
		{
			country: 'Japan',
			alpha2: 'JP',
			alpha3: 'JPN',
			numeric: 392,
			latitude: 36,
			longitude: 138,
			id: 'd1',
		},
	],
};

export const manufacturingPlants = {
	name: 'Manufacturing',
	type: 'manufacturingPlants',
	color: '#2ca507',
	location: [
		{
			country: 'Thailand',
			alpha2: 'TH',
			alpha3: 'THA',
			numeric: 764,
			latitude: 15,
			longitude: 100,
			id: 'm1',
			officeName:
				'Aditya Birla Chemicals (Thailand) Ltd. - Advanced Materials (Thailand - Office)',
			address:
				'(00005): 888/167, Mahatun Plaza Building, 16th floor, Ploenchit Road, Lumpini, Pathumwan, Bangkok - 10330, Thailand',
			phone: '+66 (0) 2 2535031-33',
			email: 'am.th@adityabirla.com',
		},
		{
			country: 'India',
			alpha2: 'IN',
			alpha3: 'IND',
			numeric: 356,
			latitude: 20,
			longitude: 77,
			id: 'm1',

			officeName:
				'Grasim Industries Limited - Advanced Materials (India - Office)',
			address:
				'308/310, AhuraCentre B-Wing, 82, MahakaliCavesRoad, Andheri-East, Mumbai - 400093, Maharashtra, India',
			phone: '+91 22 6692 8180',
			email: 'abg.in@adityabirla.com',
		},
		{
			country: 'Germany',
			alpha2: 'DE',
			alpha3: 'DEU',
			numeric: 276,
			latitude: 51,
			longitude: 9,
			id: 'm1',
			officeName: 'CTP Advanced Materials GmbH',
			address: 'Stahlstr. 60, D-65428 Rüsselsheim, Deutschland',
			phone: '+49 (0) 6142-9185-0',
			email: 'abg.de@adityabirla.com',
		},
	],
};

const distrubutedNetwork = [
	{
		country: 'Spain',
		alpha2: 'ES',
		alpha3: 'ESP',
		numeric: 724,
		latitude: 40,
		longitude: -4,
		id: 'd1',
	},
	{
		country: 'France',
		alpha2: 'FR',
		alpha3: 'FRA',
		numeric: 250,
		latitude: 46,
		longitude: 2,
		id: 'd1',
	},
	{
		country: 'Belgium',
		alpha2: 'BE',
		alpha3: 'BEL',
		numeric: 56,
		latitude: 50.8333,
		longitude: 4,
		id: 'd1',
	},
	{
		country: 'United Kingdom',
		alpha2: 'GB',
		alpha3: 'GBR',
		numeric: 826,
		latitude: 54,
		longitude: -2,
		id: 'd1',
	},
	{
		country: 'South Africa',
		alpha2: 'ZA',
		alpha3: 'ZAF',
		numeric: 710,
		latitude: -29,
		longitude: 24,
		id: 'd1',
	},
	{
		country: 'Romania',
		alpha2: 'RO',
		alpha3: 'ROU',
		numeric: 642,
		latitude: 46,
		longitude: 25,
		id: 'd1',
	},
	{
		country: 'Turkey',
		alpha2: 'TR',
		alpha3: 'TUR',
		numeric: 792,
		latitude: 39,
		longitude: 35,
		id: 'd1',
	},
	{
		country: 'Iran',
		alpha2: 'IR',
		alpha3: 'IRN',
		numeric: 364,
		latitude: 32,
		longitude: 53,
		id: 'd1',
	},
	{
		country: 'South Africa',
		alpha2: 'ZA',
		alpha3: 'ZAF',
		numeric: 710,
		latitude: -29,
		longitude: 24,
		id: 'd1',
	},
	{
		country: 'Pakistan',
		alpha2: 'PK',
		alpha3: 'PAK',
		numeric: 586,
		latitude: 30,
		longitude: 70,
		id: 'd1',
	},
	{
		country: 'India',
		alpha2: 'IN',
		alpha3: 'IND',
		numeric: 356,
		latitude: 23,
		longitude: 73,
		id: 'd1',
	},

	{
		country: 'Viet Nam',
		alpha2: 'VN',
		alpha3: 'VNM',
		numeric: 704,
		latitude: 16,
		longitude: 106,
		id: 'd1',
	},
	{
		country: 'Malaysia',
		alpha2: 'MY',
		alpha3: 'MYS',
		numeric: 458,
		latitude: 2.5,
		longitude: 112.5,
		id: 'd1',
	},
	{
		country: 'Indonesia',
		alpha2: 'ID',
		alpha3: 'IDN',
		numeric: 360,
		latitude: -5,
		longitude: 120,
		id: 'd1',
	},
	{
		country: 'Japan',
		alpha2: 'JP',
		alpha3: 'JPN',
		numeric: 392,
		latitude: 36,
		longitude: 138,
		id: 'd1',
	},
];

export let concatedArr = manufacturingPlants.location.concat(
	distrubutedNetwork,
	availability.location,
);

// let d = a.concat(b, c);
