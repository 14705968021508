import React from 'react';
import styled from 'styled-components';
import CardItem from './CardItem';

const CardSection = ({ Data }) => {
	return (
		<>
			<CardsStyledSection>
				{Data &&
					Data.map((item, idx) => {
						return <CardItem key={idx} Data={item} />;
                    }) }
			</CardsStyledSection>
		</>
	);
};

export default CardSection;
const CardsStyledSection = styled.div`
	width: 100%;
	height: 180px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
`;
