import { Button } from '@material-ui/core';
import React from 'react'
import { MdAdd } from 'react-icons/md';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

const AddButton = ({ ...prop }) => {
	const { userPermission } = userAuthorization();
	
    return (
			<Link {...prop} style={{ textDecoration: 'none' }}>
				{' '}
				<ButtonBox
					variant='contained'
					size='small'
					color='primary'
					disabled={userPermission.disableAdd}
					style={{
						borderRadius: '50%',
						fontSize: '1.2rem',
						maxWidth: '50px',
						maxHeight: '50px',
						minWidth: '50px',
						minHeight: '50px',
						float: 'right',
					}}>
					<span>
						<MdAdd />
					</span>
				</ButtonBox>
			</Link>
		);
}

export default AddButton
const ButtonBox = styled(Button)`
	span {
		display: grid;
		place-items: center;
	}
`;