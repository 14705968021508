import React from 'react';
import styled from 'styled-components';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

const AreaEdit = ({ lable, name, errorData, ...prop }) => {
	const { userPermission } = userAuthorization();
	
	return (
		<>
			<InputBox>
				<InputZone
					id={lable}
					autocomplete='off'
					required
					{...prop}
					disabled={userPermission.disableInput}
				/>
				<Lable htmlFor={lable}>{lable}</Lable>
				{errorData.touched[name] && errorData.errors[name] ? (
					<Error>{errorData.errors[name]}</Error>
				) : (
					<Error></Error>
				)}
			</InputBox>
		</>
	);
};

export default AreaEdit;
const InputBox = styled.div`
	position: relative;
	margin-bottom: 15px;
	& textarea:focus + label,
	& textarea:valid + label {
		font-size: 11px;
		top: -5px;
	}
	& textarea:disabled + label {
		font-size: 11px;
		top: -5px;
	}
	& textarea:focus + label {
		color: #39a9cb;
	}
`;

const InputZone = styled.textarea`
	display: block;
	width: 100%;
	min-height: 150px;
	background: transparent;
	border: solid 1px #ccc;
	transition: all 0.3s ease;
	padding: 20px 15px;
	white-space: pre-wrap;
	resize: none;
    outline: none;
	&:focus {
		border-color: #39a9cb;
	}
`;
const Lable = styled.label`
	position: absolute;
	cursor: text;
	z-index: 2;
	top: 13px;
	left: 10px;
	font-size: 12px;
	font-weight: bold;
	background: #fff;
	padding: 0 10px;
	color: #999;
	transition: all 0.3s ease;
	text-transform: capitalize;
`;
const Error = styled.p`
	font-size: 0.6rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
	height: 12px;
`;
