import React from 'react';
import styled from 'styled-components';
import { MEDIA_SOCIALMEDIA_COLUMN } from '../../../Components/General/Data/GeneralData';
import AddButton from '../../../Components/General/Media/MediaReuseable/AddButton';
import TableSection from '../../../Components/General/Media/MediaTable/TableSection';

const Socialmedia = ({ Data }) => {
	return (
		<StyledContanier>
			<TableSection
				Columu={MEDIA_SOCIALMEDIA_COLUMN}
				Data={Data.data}
				subName='socialmedia'
			/>
			<AddButton to={`/general/socialmedia/edit/newarticle?tag=0`} />
		</StyledContanier>
	);
};

export default Socialmedia;
const StyledContanier = styled.div``;
