import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import DataProvider from "./Context/DataProvider";
import { MapProvider } from "./Context/MapProvider";
import { HashRouter as Router } from "react-router-dom";
ReactDOM.render(
  <Router>
    <DataProvider>
      <MapProvider>
        <App />
      </MapProvider>
    </DataProvider>
  </Router>,
  document.getElementById("root")
);
