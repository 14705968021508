import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import styled from 'styled-components';
import queryString from 'query-string';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import CardSection from '../../Components/PortfolioComponent/Card/CardSection';
import { card_Data } from '../../Components/PortfolioComponent/Data/PortFolioCardData';
import TableRowBox from '../../Components/PortfolioComponent/Table/TableRowBox';

const Portfolio = () => {
	const { getQuerys } = Api_Helper();
	const Loction = useLocation();
	const { subname } = queryString.parse(Loction.search);
	const [Data, setData] = useState();
	//----------------------------------------
	const fetechData = async () => {
		const Data = await getQuerys('get', subname, '');
		setData(Data.data);
    };
    useEffect(() => {
		fetechData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subname]);
	return (
		<PortfolioConatiner>
			<CardSection Data={card_Data} />
			<TabelContainer>
				<TableHead>
					<HeadItem>SL</HeadItem>
					<HeadItem>category</HeadItem>
					<HeadItem>subcategory</HeadItem>
					<HeadItem>Products</HeadItem>
				</TableHead>
				{Data && Data.length>0 &&
					Data.map((item, Idx) => {
						return <TableRowBox Id={Idx} key={Idx} data={item} />;
					})}
			</TabelContainer>
		</PortfolioConatiner>
	);
};

export default Portfolio;
const PortfolioConatiner = styled.div`
	min-height: 100%;
`;
const TabelContainer = styled.div`
	width: 100%;
	height: 300px;
	margin-bottom:80px;
	
`;
const TableHead = styled.div`
	width: 100%;
	height: 40px;
	background-color: #ffffff;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	display: grid;
	grid-template-columns: 1fr 1fr 2fr 1fr;
	place-items: center;
	padding: 0 10px;
	margin-bottom: 20px;
`;
const HeadItem = styled.div`
	width: 80%;
	text-align: left;
	font-weight: 500;
	text-transform: capitalize;
	font-size: 1rem;
`;
