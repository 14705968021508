import React from 'react';
import { Route } from 'react-router-dom';
import ACQUISITIONS from '../_pages/_ANALYTICS/ACQUISITIONS';
import HomeHeader from '../../Components/HomeComponent/Header/HomeHeader';
import styled from 'styled-components';
import AUDIENCE from '../_pages/_ANALYTICS/AUDIENCE';
import BEHAVIOR from '../_pages/_ANALYTICS/BEHAVIOR';

const Analytics_SubPages = () => {
	return (
		<StyledContainer>
			<HomeHeader />
			<Route path='/analytics/acquisitions' exact>
				<ACQUISITIONS />
			</Route>
            <Route path='/analytics/audience' exact>
                <AUDIENCE/>
            </Route>
            <Route path='/analytics/behavior' exact>
                <BEHAVIOR/>
            </Route>
		</StyledContainer>
	);
};

export default Analytics_SubPages;
const StyledContainer = styled.div`
	padding: 20px;
    width:100%;
    height:100%;
`;
