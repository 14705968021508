import React from 'react';
import styled from 'styled-components';
import { MEDIA_TESTIMONIAL_COLUMN } from '../../../Components/General/Data/GeneralData';
import AddButton from '../../../Components/General/Media/MediaReuseable/AddButton';
import TableSection from '../../../Components/General/Media/MediaTable/TableSection';


const Testimonial = ({ Data }) => {

	return (
		<StyledConatiner>
			<TableSection
				Columu={MEDIA_TESTIMONIAL_COLUMN}
				Data={Data.data}
				subName='testimonial'
			/>
			<AddButton to={`/general/testimonial/edit/newarticle?tag=0`} />
		</StyledConatiner>
	);
};

export default Testimonial;
const StyledConatiner = styled.div`
	width: 100%;
	height: 100%;
	position: relative;
`;
