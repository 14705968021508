import React, { useState } from 'react';
import styled from 'styled-components';
import { Item } from '../../Components/Reuseable/Table/TableBar';
import HomeWorldMap from '../Sub_Pages/HomeWorldMap';
import ProductWorldMap from '../Sub_Pages/ProductWorldMap';

export const Map = () => {
	const [mapTab, setMapTab] = useState('product');
	const showProductMapHandler = () => {
		setMapTab('product');
	};
	const showHomeMapHandler = () => {
		setMapTab('home');
	};
	return (
		<>
			<WorldMapContainer>
				<MapTabConatiner>
					<TabButtonHolder>
						<Productbtn
							active={mapTab === 'product'}
							onClick={showProductMapHandler}>
							Products
						</Productbtn>
						<HomeBtn active={mapTab === 'home'} onClick={showHomeMapHandler}>
							Home
						</HomeBtn>
					</TabButtonHolder>
					<Holder>
						{mapTab === 'product' && <ProductWorldMap />}
						{mapTab === 'home' && <HomeWorldMap />}
					</Holder>
				</MapTabConatiner>
			</WorldMapContainer>
		</>
	);
};
const WorldMapContainer = styled.div`
	width: 100%;
	min-height: 100%;
	background: #ffffff;
	padding: 20px 20px 2px 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;
`;
const TabButtonHolder = styled.div`
	width: 100%;
	height: 35px;
	display: flex;
	justify-content: flex-end;
	align-items: right;
	color: white;
`;
const MapTabConatiner = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	width: 100%;
	height: 85%;
`;
const Productbtn = styled(Item)`
	background: ${({ active }) => (active ? '#379dbd' : '#39a9cb8d')};
`;
const HomeBtn = styled(Productbtn)``;

const Holder = styled.div`
	width: 100%;
	height: calc(100vh - 200px);
	display: flex;
	justify-content: space-around;
	background: #f2f4f4;
	overflow: hidden;
	flex-direction: column;
	padding: 20px;
	position: relative;
	border: solid 1px #ececec;
`;
