import React from 'react';
import styled from 'styled-components';
// import NavHeader from './NavHeader';
// import { header_Data } from '../Data/HomeHeaderData';
import { useData } from '../../../Context/DataProvider';
import { HiOutlineArrowNarrowRight } from 'react-icons/hi';
import { useParams } from 'react-router-dom';
const HomeHeader = () => {
	const { title } = useData();
	const { mainCategory } = useParams();
	const Routes = ['portfolio', 'general', 'analytics'];
	const Title = Routes.includes(mainCategory);
	//------------------------------------

	return (
		<>
			<HeaderContainer>
				<MenuItem>
					{Title && (
						<HomeTitle>
							<h3>{mainCategory}</h3>
						</HomeTitle>
					)}
					{title.title ? (
						<TitleName>
							<h3>
								{title.title}
								{title.subtitle && (
									<SubTitle>
										{' '}
										<Icon>
											<HiOutlineArrowNarrowRight />
										</Icon>{' '}
										{` ${title.subtitle}`}{' '}
									</SubTitle>
								)}
							</h3>
						</TitleName>
					) : (
						''
					)}
				</MenuItem>
				{/* <NavHeader Data={header_Data && header_Data} /> */}
			</HeaderContainer>
		</>
	);
};

export default HomeHeader;
const HeaderContainer = styled.header`
	width: 100%;
	height: 55px;
	background-color: #ffffff;
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
	position: relative;
`;
const MenuItem = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	overflow: hidden;
	padding-left: 10px;
`;
const TitleName = styled.div`
	position: relative;
	h3 {
		text-transform: capitalize;
		text-align: left;
		font-size: 1.1rem;
		display: flex;
		align-items: center;
		white-space: nowrap;
	}
	&::after {
		content: '';
		width: 100px;
		height: 3px;
		background-color: #ffb511;
		position: absolute;
		bottom: -5px;
		left: 0;
	}
	&::before {
		content: '';
		width: 3px;
		height: 3px;
		background-color: #ffb511;
		border-radius: 50%;
		position: absolute;
		bottom: -5px;
		left: 105px;
	}
`;
const Icon = styled.div`
	display: grid;
	place-items: center;
	margin: 0 5px;
`;
const SubTitle = styled.span`
	font-size: 1.1rem;
	color: #333333;
	letter-spacing: 0.9px;
	display: flex;
	align-items: center;
`;
const HomeTitle = styled(TitleName)``;
