import React from 'react';
import styled from 'styled-components';

const Availability = () => {
	return (
		<>
			{' '}
			<Det>
				By default , all products are available in the following regions
				(IMEA ,SEA, CHINA, KOREA, EU, USA, CANADA) and customers can request
				sample respectively.
			</Det>
			{/* <Note>Glandular Region wise availability data is awaited.</Note> */}
		</>
	);
};

export default Availability;
/* const Note = styled.div`
	font-size: 0.9rem;
	font-weight: 500;
`; */
const Det = styled.div`
	width: 80%;
	text-align: center;
	font-size: 1rem;
`;
