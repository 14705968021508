import { useContext, useState } from 'react';
import {
	ComposableMap,
	Geographies,
	Geography,
	Marker,
} from 'react-simple-maps';
import styled, { keyframes } from 'styled-components';
import { MapContext } from '../../Context/MapProvider';
import { MenuData } from '../../Components/worldMapComponent/Data';

import LocationCard, {
	Button,
} from '../../Components/worldMapComponent/LocationCard';
import Buttons from '../../Components/worldMapComponent/Buttons';
import Form from '../../Components/worldMapComponent/Form';

const ProductWorldMap = () => {
	const { mapType, color, typeNames } = useContext(MapContext);
	const [type] = mapType;
	const [pinColor] = color;
	const [typeName] = typeNames;

	const [isForm, setIsForm] = useState(false);
	const [locationData, setLocationData] = useState('');
	const geoUrl =
		'https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json';

	const showToolTip = (evt, name) => {
		let tooltip = document.getElementById('tooltip');
		tooltip.innerHTML = name;
		tooltip.style.display = 'block';
		tooltip.style.left = evt.pageX + 10 + 'px';
		tooltip.style.top = evt.pageY + -30 + 'px';
	};
	const hideToolTip = () => {
		let tooltip = document.getElementById('tooltip');
		tooltip.style.display = 'none';
	};
	const showLoactionDetailsHandler = (evt, name) => {
		let test = document.getElementById('test');
	
		if (typeName === 'salesOffice') {
			test.style.display = 'none';
		} else {
			test.style.display = 'flex';
			hideToolTip();
		}
		if (evt.pageX < 500) {
			test.style.left = evt.pageX + -100 + 'px';
		} else {
			test.style.left = evt.pageX + -290 + 'px';
		}
		if (evt.pageY > 400) {
	
			test.style.top = evt.pageY + -215 + 'px';
		} else {
		
			test.style.top = evt.pageY + -115 + 'px';
		}
		setLocationData(name);
	};
	const hideLocationDetailsHandler = () => {
		let test = document.getElementById('test');
		test.style.display = 'none';
	};
	const showFormHandler = () => {
		setIsForm(true);
	};
	const hideAllCardHandler = (evt) => {
		let test = document.getElementById('test');
		if (evt.target.localName !== 'circle' && test.style.display === 'flex') {
			test.style.display = 'none';
		}
		isForm && setIsForm(false);
	};

	return (
		<>
			<ProductName>Epotec® EP 103 OP</ProductName>
			{isForm && <Form />}

			<CardHolder id='test' className='Card'>
				<Close onClick={hideLocationDetailsHandler}>X</Close>
				<LocationCard data={locationData} />
			</CardHolder>

			<MapContainer onClick={hideAllCardHandler}>
				<div id='tooltip'></div>
				<ComposableMap
					projection={'geoEquirectangular'}
					width={1050}
					height={500}>
					<Geographies geography={geoUrl}>
						{({ geographies }) =>
							geographies.map((geo) => {
								if (typeName === 'availability') {
									const d = type.find((s) => {
										return s.alpha3 === geo.properties.ISO_A3;
									});

									return (
										<Geography
											key={geo.rsmKey}
											geography={geo}
											onMouseOver={(evt) => {
												d && showToolTip(evt, d.region);
											}}
											onMouseOut={hideToolTip}
											fill={d ? '#88bbcced' : '#D5DBDB'}
										/>
									);
								} else {
									return (
										<Geography
											key={geo.rsmKey}
											geography={geo}
											fill='#cfdada'
										/>
									);
								}
							})
						}
					</Geographies>
					{typeName !== 'availability' &&
						type.map((item, i) => {
							return (
								<Markers
									key={i}
									coordinates={[item.longitude, item.latitude]}
									color={pinColor}>
									<circle
										r='14'
										fill='transparent'
										stroke='transparent'
										className='outter_circel'
										strokeWidth='0.5'
										onClick={(evt) =>
											showLoactionDetailsHandler(evt, item)
										}></circle>
									<circle
										r='6'
										stroke='black'
										strokeWidth='0.5'
										className='inner_circel'
										onMouseOver={(evt) => showToolTip(evt, item.country)}
										onMouseOut={hideToolTip}
										onClick={(evt) => showLoactionDetailsHandler(evt, item)}
									/>
								</Markers>
							);
						})}
				</ComposableMap>
			</MapContainer>
			<CategoryHolder>
				{MenuData.map((item, i) => {
					return <Buttons key={i} data={item} onClick={hideAllCardHandler} />;
				})}
				<Button onClick={showFormHandler}>Request Sample</Button>
			</CategoryHolder>
		</>
	);
};
const right = keyframes`
  from {
   right: -25%;
  }
  to {
	right: 0%;
  }
`;
const down = keyframes`
  from {
	opacity: 0;
  }
  to {
	opacity: 1;
  }
`;
const CardHolder = styled.section`
	animation: ${down} 0.3s linear;
	width: 400px;
	height: 250px;
	background: #ffffff;
	transition: all 0.8s ease;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: none;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	border-radius: 8px;
	box-shadow: 0 2px 7px 1px rgb(0 0 0 / 30%);
`;
const Close = styled.div`
	position: absolute;
	top: 1%;
	right: 3%;
	font-size: 0.7rem;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	color: #131313;
	font-weight: 600;
	cursor: pointer;
	display: grid;
	place-items: center;
	float: right;
	transition: all 0.5s ease;
	z-index: 555;
`;

const ProductName = styled.div`
	position: absolute;
	top: 4%;
	left: 2%;
	font-weight: 600;
	font-size: 1.2rem;
	color: rgba(0, 0, 0, 0.87);
	letter-spacing: 1px;
`;
const CategoryHolder = styled.div`
	animation: ${right} 0.8s linear;
	position: absolute;
	bottom: 25%;
	right: 0%;
	width: 250px;
	background: rgba(18, 18, 18, 0.8);
	box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
	backdrop-filter: blur(8.5px);
	-webkit-backdrop-filter: blur(8.5px);
	border: 1px solid rgba(255, 255, 255, 0.18);
	height: 50%;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 20px;
	align-items: flex-end;
	font-size: 1rem;
	border-radius: 2rem 0px 0px 2rem;
`;
const MapContainer = styled.div`
	width: 75%;
	height: 90%;
	margin-left: 80px;
`;

export const Markers = styled(Marker)`
	z-index: 55;

	:hover {
		& .outter_circel {
			fill:#44444499 ;
			transition: all 0.5s ease;
			
		}
	}
	& .inner_circel {
		fill: ${({ color }) => color};
		transition: all 0.5s ease;
		cursor: pointer;
		z-index: 55;
		:hover {
			fill: #bdb5b590;
			font-size: 1rem;
		}
	}
`;

export default ProductWorldMap;
