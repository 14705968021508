import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import ApplicationItem from './ApplicationItem';
import Warning from '../../Reuseable/Warning';
import { MdAdd } from 'react-icons/md';
import { useData } from '../../../Context/DataProvider';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';
const Application = ({ Data }) => {
	const { productData } = useData();
	const Product = JSON.parse(productData);

	//----------------------------------------------
	const [value, setValue] = useState('');
	const [waring, setWaring] = useState('');
	const { userPermission } = userAuthorization();

	//---------------------------
	const addForms = () => {
		const feilds = [...value];
		feilds.push({ image: '', description: '' });
		setValue(feilds);
		Data.setFieldValue('application', feilds);
		setWaring('New Input field added');
	};
	//-------------------------------------
	const warning = () => {
		if (
			JSON.stringify(Data.values.application) ===
			JSON.stringify(Product.application)
		) {
			setWaring('');
		} else {
			setWaring('Input field changed');
		}
	};
	//----------------------------------------------
	useEffect(() => {
		setValue(Data.values.application);
		Data && Product && warning();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Data.values.application, Product.application]);
	return (
		<>
			<MainBox>
				{waring && <Warning message={waring} top='-30px' />}
				<ItemName>
					<h2>Applications</h2>
				</ItemName>
				<ApplicationConatiner>
					{value &&
						value.map((item, idx) => {
							return (
								<ApplicationItem
									Item={item}
									Formik={Data}
									idx={idx}
									key={idx}
									setState={setWaring}
								/>
							);
						})}
				</ApplicationConatiner>
				<ButtonZone>
					<Button
						variant='contained'
						size='small'
						color='primary'
						onClick={addForms}
						disabled={userPermission.disableAdd}
						style={{
							borderRadius: '50%',
							fontSize: '1.2rem',
							maxWidth: '40px',
							maxHeight: '40px',
							minWidth: '40px',
							minHeight: '40px',
						}}>
						<MdAdd />
					</Button>
				</ButtonZone>
			</MainBox>
		</>
	);
};;

export default Application;
const MainBox = styled.fieldset`
	width: 100%;
	border: 2px dotted #666;
	padding: 20px;
	position: relative;
`;
const ApplicationConatiner = styled.form`
	width: 100%;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
	grid-gap: 20px;
	grid-auto-flow: dense;
`;

const ItemName = styled.legend`
	display: grid;
	place-items: center;
	position: relative;
	height: 70px;
	margin-left: 10px;
	background-color: transparent;
	h2 {
		text-transform: capitalize;
		font-size: 1.1rem;
	}
	&::after {
		content: '';
		width: 100px;
		height: 3px;
		background-color: #ffb511;
		position: absolute;
		bottom: 15px;
		left: 0;
	}
	&::before {
		content: '';
		width: 3px;
		height: 3px;
		background-color: #ffb511;
		border-radius: 50%;
		position: absolute;
		bottom: 15px;
		left: 105px;
	}
`;
const ButtonZone = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
`;
