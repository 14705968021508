import React, { useEffect } from 'react';
import styled from 'styled-components';
// import { MdKeyboardArrowDown } from 'react-icons/md';

const TableChildRow = ({ Item, State }) => {
	// const [childOpen, IsChildOpen, setChild] = State;

	useEffect(() => {
		// setChild(Item.category ? Item.category.length : 0);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Item]);
	return (
		<Box Height={Item.category ? Item.category.length : 0}>
			<SubBox>
				<List>{Item.name}</List>
				{/* <Icon onClick={() => IsChildOpen(!childOpen)}>
					<MdKeyboardArrowDown />
				</Icon> */}
			</SubBox>
			{/* <ChildDetails className={childOpen ? 'SubActive' : 'Subclose'}>
               
			</ChildDetails> */}
		</Box>
	);
};

export default TableChildRow;
const SubBox = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;
const List = styled.li`
	font-size: 0.8rem;
	letter-spacing: 1px;
	line-height: 1rem;
	margin: 7px;
`;
const Box = styled.div`
	.Subclose {
		height: 0;
	}
	.SubActive {
		height: ${({ Height }) => Height * 32}px;
	}
`;
/* const Icon = styled.div``;
const ChildDetails = styled.div`
	transition: all 1s ease;
`;
 */