import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import styled from 'styled-components';
import { BiSearchAlt } from 'react-icons/bi';
import { useData } from '../../../Context/DataProvider';

export const GlobalFilter = ({ filter, setFilter }) => {
	const [value, setValue] = useState(filter);
	const onChange = useAsyncDebounce((value) => {
		setFilter(value || undefined);
	}, 1000);
	const { tableData } = useData();
	return (
		<Header>
			<Info>{tableData.tableNameData}</Info>
			<Search>
				<form>
					<input
						type='text'
						value={value || ''}
						placeholder='search'
						onChange={(e) => {
							setValue(e.target.value);
							onChange(e.target.value);
						}}
					/>
					<button type='submit'>
						<BiSearchAlt size={20} style={{ color: 'white' }} />
					</button>
				</form>
			</Search>
		</Header>
	);
};
const Header = styled.div`
	width: 100%;
	height: 40px;
	background-color: #ece3e3;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding:5px;
`;
const Info = styled.p`
	letter-spacing: 1px;
	font-weight: 600;
	text-transform: capitalize;
	font-size: 0.9rem;
`;
const Search = styled.div`
	form {
		width: 300px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid #b3d0d8;
		border-radius: 5px;
	}
	button {
		padding: 6px 10px;
		font-size: 1px;
		border: none;
		cursor: pointer;
		background: #b8b5ff;
		border-bottom-right-radius: 5px;
		border-top-right-radius: 5px;
		transition-duration: 0.5s;
		&:hover {
			background: #92a8d1;
		}
	}
	input {
		width: 100%;
		border-bottom-left-radius: 5px;
		border-top-left-radius: 5px;
	}
	input[type='text'] {
		padding: 8px;
		font-size: 12px;
		border: none;
		outline: none;

		&::placeholder {
			font-size: 0.7rem;
			letter-spacing: 1px;
		}
	}
`;
