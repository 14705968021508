import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useData } from '../../../Context/DataProvider';
import { useParams } from 'react-router';
import Warning from '../../Reuseable/Warning';
import Input from '../Input/Input';

const ImageBox = ({ Data }) => {
	const [waring, setWaring] = useState('');
	const { productData } = useData();
	const {  type } = useParams();
	//-------------------------------------------
	const Product = JSON.parse(productData);

	//-------------------------------

	
	//-------------------------
	useEffect(() => {
		if (
			type === 'system'
				? Data.values.system_image === Product.system_image
				: Data.values.product_image === Product.product_image
		) {
			setWaring('');
		} else {
			setWaring('Image changed');
		}
	}, [
		Data.values.system_image,
		Data.values.product_image,
		type,
		Product.system_image,
		Product.product_image,
	]);

	return (
		<ImageConatiner>
			{waring && <Warning message={waring} />}
			<Image htmlFor='file'>
				<img
					id='imagePreview'
					src={
						Data.values.system_image
							? Data.values.system_image
							: Data.values.product_image
					}
					alt='pic'
					onError={(e) => {
						e.target.src = '/Assets/loading.png';
					}}
				/>
			</Image>
			<InputBox>
				<Input
					onChange={Data.handleChange}
					// eslint-disable-next-line react/jsx-no-duplicate-props
					value={
						type === 'system'
							? Data.values.system_image
							: Data.values.product_image
					}
					lable={type === 'system' ? 'system_image' : 'product_image'}
					name={type === 'system' ? 'system_image' : 'product_image'}
					onBlur={Data.handleBlur}
					errorData={Data}
				/>
			</InputBox>
		</ImageConatiner>
	);
};

export default ImageBox;
const ImageConatiner = styled.div`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-around;
	border: 3px dotted #00a9c9;
	width: 50%;
	max-height: 450px;
	padding: 30px 0;
`;
const Image = styled.label`
	width: 100%;
	height: 80%;
	min-height: 300px;
	max-height: 300px;
	transform: translateX(-40px);
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;
const InputBox = styled.div`
	display: flex;
	width: 90%;
	padding: 10px;
`;

