import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

const UpdateButton = ({ ...prop }) => {
	const { userPermission } = userAuthorization();

	return (
		<Save
			variant='contained'
			disabled={userPermission.disableUpdate}
			color='primary'
			style={{
				fontSize: '0.9rem',
				color: '#f6f6f6',
			}}
			{...prop}>
			Update
		</Save>
	);
};

export default UpdateButton;
const Save = styled(Button)`
	height: 35px;
`;
