import React, { useEffect, useRef, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Api_Helper from '../../../Adapters/Api/Api_Helper';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import { FaCamera } from 'react-icons/fa';
import BackButton from '../../../Components/Reuseable/BackButton';
import MediaInput from '../../../Components/General/Media/_INPUT/MediaInput';
import { MdKeyboardArrowUp } from 'react-icons/md';
import UpdateButton from '../../../Components/General/Media/MediaReuseable/UpdateButton';
import ResetButton from '../../../Components/General/Media/MediaReuseable/ResetButton';
import Warning from '../../../Components/Reuseable/Warning';
import PopUp from '../../../Components/Reuseable/PopUp';
import Modal from '../../../Components/General/Media/MediaReuseable/Modal';
import { DeleteButton } from '../../../Components/General/Media/MediaReuseable/DeleteButton';
import userAuthorization from '../../../Adapters/Functions/userAuthorization';

export const SocialmediaEdit = () => {
	const { uid } = useParams();
	const Loction = useLocation();
	const { tag } = queryString.parse(Loction.search);
	const { getQuerys, getUrl, validation } = Api_Helper();
	const imageFile = useRef();
	const [IsDrop, setIsDrop] = useState(true);
	const [Image, setImage] = useState(null);
	//--------------------------------------------------
	const [popUp, setPopUp] = useState('');
	const [waring, setWaring] = useState('');
	const type = ['png', 'jpg', 'webp', 'jpeg'];
	const [data, setData] = useState();
	const [isOpen, setIsOpen] = useState('');
	const { userPermission } = userAuthorization();

	//-------------
	const FormSchema = Yup.object({
		title: Yup.string()
			.min(20, 'Must be 20 characters or more')
			.max(120, 'Must be 120 characters or less')
			.required('Required'),
		description: Yup.string()
			.min(100, 'Must be 100 characters or more')
			.max(300, 'Must be 300 characters or less ')
			.required('Required'),
		url: Yup.string().required('Required'),
		category: Yup.string().required('Required'),
	});
	//---------------Drop-Function------------------
	const dropHandler = (name) => {
		formik.setFieldValue('category', name);
		setIsDrop(!IsDrop);
	};
	//-----------------Image----------------
	const handleChange = (e) => {
		if (
			e.target.files[0] &&
			type.includes(e.target.files[0].type.split('/')[1].toLowerCase())
		) {
			if (e.target.files[0].size / 1024 / 1024 < 5) {
				setImage(e.target.files[0]);
				const src1 = URL.createObjectURL(e.target.files[0]);
				formik.setFieldValue('image', src1);
			} else {
				setPopUp('Image Should Less than 5MB');
			}
		} else {
			setPopUp('Please upload only png ,jpg or jpeg files');
		}
	};
	//------------------------------Formik--------
	const formik = useFormik({
		initialValues: {
			title: '',
			description: '',
			url: '',
			category: '',
			image: '',
		},
		validationSchema: FormSchema,
		onSubmit: () => {
			updateHandler();
		},
	});
	//---------------------post nomportfolio-------------------
	const nonPortfolioHandler = async (data) => {
		const collection_identifier =
			tag === '1' ? `socialmedia/${uid}` : 'socialmedia';
		const Product = await getQuerys('post', collection_identifier, data);
		console.log(Product);
		setPopUp('Updated');

		imageFile.current.value = '';
	};
	//-----------------update---------------------
	const updateHandler = async () => {
		try {
			if (tag === '1' ? waring : true) {
				if (Image) {
					const name = Image.name;
					await validation(Image, 350, 350);
					const url = await getUrl(Image, name, 'socialmedia/');
					const imageLink = url.data.url;
					formik.setFieldValue('image', imageLink);
					const data = { document: { ...formik.values, image: imageLink } };
					nonPortfolioHandler(data);
					setPopUp('Updated');
					fetchProduct();
				} else {
					if (formik.values.image) {
						const data = { document: formik.values };
						nonPortfolioHandler(data);
						fetchProduct();
					} else {
						setPopUp('Image is Not Set');
					}
				}
				imageFile.current.value = '';
			} else {
				setPopUp('Nothing to Update');
			}
		} catch (error) {
			console.log(error);
			if (error.response) {
				console.log(error.response.data);
				setPopUp('The Image width height should be 350 X 350');
			} else {
				setPopUp('Sorry Try Again later');
			}
		}
	};
	//-------------------------
	//-------------------------
	const fetchProduct = async () => {
		try {
			if (tag === '1') {
				const Query = { query: { u_id: uid } };
				const Details = await getQuerys('patch', 'socialmedia', Query);
				const Data = Details.data[0];
				setData(Data);
				formik.resetForm({
					values: {
						u_id: Data.uid,
						title: Data.title,
						description: Data.description,
						url: Data.url,
						category: Data.category || 'linkedin',
						image: Data.image,
					},
				});
			} else {
				formik.resetForm({
					values: {
						u_id: '',
						title: '',
						description: '',
						url: '',
						category: 'linkedin',
						image: '',
					},
				});
			}
		} catch (e) {
			console.log(e);
		}
	};
	//-------------------------
	const waringHandler = () => {
		if (
			data.title === formik.values.title &&
			data.description === formik.values.description &&
			data.url === formik.values.url &&
			data.category === formik.values.category &&
			data.image === formik.values.image
		) {
			setWaring('');
		} else {
			setWaring('input changed ');
		}
	};

	//--------------------------------------------------
	useEffect(() => {
		fetchProduct();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [uid, tag]);
	//-----------------------------------
	useEffect(() => {
		data && formik.values && waringHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, formik.values]);
	return (
		<StyledConatiner>
			{isOpen && (
				<Modal
					uid={isOpen}
					type='socialmedia'
					state={setIsOpen}
					subName='social-media'
				/>
			)}
			{waring && <Warning message={waring} top='10px' right='20px' />}
			{popUp && <PopUp state={[popUp, setPopUp]} />}
			<SocialMediaBox>
				<BackButton />
				<SocialMediaImage>
					<input
						ref={imageFile}
						type='file'
						id='file'
						name='file'
						required
						onChange={handleChange}
						disabled={userPermission.disableUpdate}
					/>
					<label htmlFor='file'>
						<img
							src={formik.values.image}
							alt='test'
							onError={(e) => {
								e.target.src = '/Assets/loading.png';
							}}
						/>
						<FaCamera />
					</label>
				</SocialMediaImage>
				<SocialMediaText>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.title || ''}
						lable='title'
						name='title'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='100%'
					/>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.description || ''}
						lable='description'
						name='description'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='100%'
					/>
					<MediaInput
						onChange={formik.handleChange}
						value={formik.values.url || ''}
						lable='url'
						name='url'
						onBlur={formik.handleBlur}
						errorData={formik}
						width='100%'
					/>
					<DropConatiner>
						<Label>Platform :</Label>
						<DropBox>
							<DropDown
								onClick={() =>
									!userPermission.disableUpdate && setIsDrop(!IsDrop)
								}
								state={IsDrop}>
								<p>{formik.values.category}</p>
								<span>
									<MdKeyboardArrowUp />
								</span>
							</DropDown>
							<DropDownItem className={IsDrop ? 'MenuOpen' : 'MenuClose'}>
								<Item onClick={() => dropHandler('linkedin')}>linkedin</Item>
								<Item onClick={() => dropHandler('facebook')}>facebook</Item>
								<Item onClick={() => dropHandler('instagram')}>instagram</Item>
							</DropDownItem>
						</DropBox>
						{formik.errors.category && formik.touched.category ? (
							<Error>{formik.errors.category}</Error>
						) : (
							<Error></Error>
						)}
					</DropConatiner>

					<ButtonsBox>
						<div>
							<ResetButton
								onClick={fetchProduct}
								disabled={waring === '' ? true : false}
							/>
							<UpdateButton onClick={formik.handleSubmit} />
						</div>
						{tag === '1' && <DeleteButton onClick={() => setIsOpen(uid)} />}
					</ButtonsBox>
				</SocialMediaText>
			</SocialMediaBox>
		</StyledConatiner>
	);
};
const StyledConatiner = styled.div`
	width: 100%;
	min-height: calc(100vh - 110px);
	display: grid;
	place-items: center;
	position: relative;
`;
const SocialMediaBox = styled.div`
	width: 750px;
	height: 460px;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
		rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
	padding: 20px;
	position: relative;
	display: flex;
	align-items: center;
`;
const SocialMediaImage = styled.div`
	width: 40%;
	height: 100%;
	display: grid;
	place-items: center;
	position: relative;
	margin: 10px 20px 10px 0;
	img {
		width: 220px;
		height: 250px;
		border-radius: 10px;
		object-fit: cover;
	}
	input {
		display: none;
	}
	label {
		position: absolute;
		top: 50px;
		left: 0;
		transform: translate(-50%-50%);
		width: 180px;
		height: 180px;
		color: #ffb511;
		display: grid;
		place-items: center;
		font-size: 2rem;
		background-color: #00000030;
		&:hover {
			background-color: #00000060;
			cursor: pointer;
		}
	}
`;
const SocialMediaText = styled.div`
	width: 90%;
`;
const DropConatiner = styled.div`
	display: flex;
	align-items: flex-start;
	justify-content: space-around;
	width: 70%;
`;
const Label = styled.p`
	font-size: 0.8rem;
	font-weight: 500;
	margin-top: 5px;
`;
const DropBox = styled.div`
	width: 140px;
	& .MenuOpen {
		opacity: 0;
		visibility: hidden;
		transform: translateY(1rem);
	}
	& .MenuClose {
		visibility: visible;
		opacity: 1;
		transform: translateY(0);
	}
`;

const DropDown = styled.div`
	width: 100%;
	height: 30px;
	background-color: #303f9f;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	border-radius: 3px;
	cursor: pointer;
	span {
		margin-left: 5px;
		display: grid;
		place-items: center;
		transition: all 0.35s;
		transform: ${({ state }) => (state ? 'rotate(180deg)' : 'rotate(0deg)')};
	}
	p {
		font-size: 0.9rem;
	}
`;
const DropDownItem = styled.div`
	width: 100%;
	transition: all 0.35s;
	border-left: 1px solid #c2c2c2;
	border-right: 1px solid #c2c2c2;
`;
const Item = styled.div`
	width: 100%;
	height: 30px;
	display: grid;
	place-items: center;
	border-bottom: 1px solid #c2c2c2;
	transition: all 0.35s;
	cursor: pointer;
	font-size: 0.9rem;
	&:hover {
		background-color: #eeeeee;
	}
`;
const ButtonsBox = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
`;
const Error = styled.p`
	font-size: 0.6rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
	height: 12px;
	width: 60px;
`;
