export const card_Data = [
	{
		id: 1,
		name: 'industy',
		link: 'building_block.png',
	},
	{
		id: 2,
		name: 'application',
		link: 'collaborate.png',
	},
	{
		id: 1,
		name: 'chemistry',
		link: 'system.png',
	},
	{
		id: 1,
		name: 'brands',
		link: 'regulatory.png',
	},
];