import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Api_Helper from '../../Adapters/Api/Api_Helper';
import {
	REQUEST_TDS_HEADER,
	REQUEST_MSDS_HEADER,
	REQUEST_MSDS_COLUMN,
	REQUEST_TDS_COLUMN,
	ENQUIRY_COLUMN,
	ENQUIRY_HEADER,
	SAMPLE_REQUEST_COLUMN,
	SAMPLE_REQUEST_HEADER,
	CONATACT_US_HEADER,
	CONTACT_US_COLUMN
} from '../../Components/General/Data/GeneralData';
import TableSection from '../../Components/General/Table/TableSection';
import { useData } from '../../Context/DataProvider';

const Request = () => {
	const { subName } = useParams();
	const { pathname } = useLocation();
	const subCatogery = pathname.split('/')[2];
	const [data, setData] = useState('');
	const { setTableData } = useData();
	//------------------------
	const collection_identifier = 'forms';
	const Data = {
		query: { formType: subName },
	};
	const { getQuerys } = Api_Helper();
	const getDetails = async () => {
		const nonPortfolioData = await getQuerys(
			'patch',
			collection_identifier,
			Data,
		);
		setData(nonPortfolioData);
		setTableData((state) => ({
			...state,
			tableNameData: `${subCatogery}(${subName})`,
		}));
	};
	useEffect(() => {
		subName && getDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [subName]);
	return (
		<>
			<TableSection
				Columu={
					subName === 'msds-request'
						? REQUEST_MSDS_COLUMN
						: subName === 'enquiry'
						? ENQUIRY_COLUMN
						: subName === 'sample-request'
						? SAMPLE_REQUEST_COLUMN
						: subName === 'contact-us'
						? CONTACT_US_COLUMN
						: REQUEST_TDS_COLUMN
				}
				Data={data.data}
				CSV={
					subName === 'msds-request'
						? REQUEST_MSDS_HEADER
						: subName === 'enquiry'
						? ENQUIRY_HEADER
						: subName === 'sample-request'
						? SAMPLE_REQUEST_HEADER
						: subName === 'contact-us'
						? CONATACT_US_HEADER
						: REQUEST_TDS_HEADER
				}
				type={subName}
			/>
		</>
	);
};

export default Request;
