import React, { createContext, useState } from 'react';
import {
	availability,
} from '../Components/worldMapComponent/TestData';

export const MapContext = createContext();

export const MapProvider = (props) => {
	const [type, setType] = useState(availability.location);
	const [pinColor, setPinColor] = useState('#b51fd3f');
	const [typeName, setTypeName] = useState('availability');

	const values = {
		mapType: [type, setType],
		color: [pinColor, setPinColor],
		typeNames: [typeName, setTypeName],
	};
	return (
		<MapContext.Provider value={values}>{props.children}</MapContext.Provider>
	);
};
