import React from 'react';
import styled from 'styled-components';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

const MediaInput = ({ lable, name, errorData, width, ...props }) => {
	const { userPermission } = userAuthorization();

	return (
		<>
			<InputBox>
				<InputZone
					type='text'
					id={lable}
					autocomplete='off'
					Width={ width }
					required
					disabled={userPermission.disableInput}
					{...props}
				/>
				<Lable htmlFor={lable}>{lable}</Lable>
				{errorData.touched[name] && errorData.errors[name] ? (
					<Error>{errorData.errors[name]}</Error>
				) : (
					<Error></Error>
				)}
			</InputBox>
		</>
	);
};

export default MediaInput;
const InputBox = styled.div`
	position: relative;
	margin-bottom: 15px;
	& input:focus + label,
	& input:valid + label {
		font-size: 11px;
		top: -5px;
	}
	& input:disabled + label {
		font-size: 11px;
		top: -5px;
	}
	& input:focus + label {
		color: #39a9cb;
	}
`;
const InputZone = styled.input`
	display: block;
	width: ${({ Width }) => (Width ? Width : '400px')};
	height: 40px;
	background: #ffffff;
	border: none;
	transition: all 0.3s ease;
	padding: 0 15px;
	outline: none;
	box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
		rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
	&:focus {
		border-color: #00c3ff;
	}
`;
const Lable = styled.label`
	position: absolute;
	cursor: text;
	z-index: 2;
	top: 13px;
	left: 10px;
	font-size: 12px;
	font-weight: bold;
	background: #fff;
	padding: 0 10px;
	color: #000000;
	transition: all 0.3s ease;
	text-transform: capitalize;
`;
const Error = styled.p`
	font-size: 0.6rem;
	color: #dd2121;
	font-weight: 500;
	padding: 5px 0;
	height: 12px;
`;
