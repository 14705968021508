import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import styled from 'styled-components';
import NavBar from './Components/Navbar/NavBar';
import storage from 'local-storage-fallback';
import Footer from './Components/Footer/Footer';
import SideMenu from './Components/Sidemenu/SideMenu';

const ProtectedRoute = ({ Component, ...rest }) => {
	const Auth_Token = storage.getItem('Token');
	const Auth_Authority = storage.getItem('Authority');
	const Data = storage.getItem('ProfileData');
	const ProfileData = JSON.parse(Data);
	return (
		<>
			<NavBar />
			<MainConatiner>
				<SideMenu />
				<Route
					{...rest}
					render={() => {
						return Auth_Token &&
							Auth_Authority.length > 0 &&
							ProfileData ? (
							<Component />
						) : (
							<Redirect to='/login' />
						);
					}}
				/>
				<Footer />
			</MainConatiner>
		</>
	);
};

export default ProtectedRoute;
const MainConatiner = styled.div`
	width: 100%;
	height: 100%;
	background-color: #f6f6f6;
	display: flex;
`;
