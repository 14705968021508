import React, { useContext } from 'react';
import styled from 'styled-components';
import { MapContext } from '../../Context/MapProvider';
import { availability, manufacturingPlants, salesOffice } from './TestData';

const Buttons = ({ data }) => {
	const { mapType, color, typeNames } = useContext(MapContext);
	// eslint-disable-next-line
	const [type, setType] = mapType;
	// eslint-disable-next-line
	const [pinColor, setPinColor] = color;
	const [typeName, setTypeName] = typeNames;
	const showPoints = () => {
		setTypeName(data.type);
		if (data.type === 'availability') {
			setType(availability.location);
		}
		if (data.type === 'manufacturingPlants') {
			setType(manufacturingPlants.location);
		}
		if (data.type === 'salesOffice') {
			setType(salesOffice.location);
		}
		setPinColor(data.color);
	};

	return (
		<ButtonHolder onClick={showPoints} active={typeName === data.type}>
			{data.name}
		</ButtonHolder>
	);
};
export const ButtonHolder = styled.h1`
	height: 30px;
	font-size: 0.9rem;
	font-weight: 400;
	letter-spacing: 1px;
	cursor: pointer;
	position: relative;
	transition: all 0.5s ease;
	color: ${({ active }) => (!active ? ' #f7f7f7' : '#FFB511')};
	margin-right: ${({ active }) => (active ? '10px' : '0')};
`;

export default Buttons;
