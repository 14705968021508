export const Portfolio_Data = [
	{
		id: 1,
		catogery: 'Industy',
		flag: 1,
		query: 'industry',
		mainCatogery: [
			{
				id: 1.1,
				family_id: '',
				name: 'Aerospace',
				type: '',
			},
			{
				id: 1.2,
				family_id: '',
				name: 'Coating',
				type: '',
			},
			{
				id: 1.3,
				family_id: '',
				name: 'Composite',
				type: '',
			},
			{
				id: 1.4,
				family_id: '',
				name: 'Construction',
				type: '',
			},
			{
				id: 1.5,
				family_id: '',
				name: 'Electrical',
				type: '',
			},
			{
				id: 1.6,
				family_id: '',
				name: 'Marine',
				type: '',
			},
			{
				id: 1.7,
				family_id: '',
				name: 'Sports',
				type: '',
			},
		],
	},
	{
		id: 2,
		catogery: 'Application',
		flag: 1,
		query: 'application',
		mainCatogery: [
			{
				id: 2.1,
				family_id: '105',
				name: 'Adhesives',
				type: 'system',
				subCatogery: [
					{
						id: '2.1.1',
						name: 'Construction Epoxy Adhesives',
						tid: '100D',
						childFlag: 0,
					},
					{
						id: '2.1.2',
						name: 'Composite Epoxy Adhesives',
						tid: '6E',
						childFlag: 0,
					},
				],
			},
			{
				id: 2.2,
				family_id: '100',
				name: 'Construction',
				type: 'system',
				subCatogery: [
					{
						id: '2.2.1',
						name: 'Epoxy Concrete Primer',
						tid: '100A',
						childFlag: 0,
					},
					{
						id: '2.2.2',
						name: 'Epoxy Self-Levelling Flooring',
						tid: '100B',
						childFlag: 0,
					},
					{
						id: '2.2.3',
						name: 'Epoxy Mortar',
						tid: '100C',
						childFlag: 0,
					},
					{
						id: '2.2.4',
						name: 'Epoxy Adhesives',
						childFlag: 0,
						tid: '100D',
					},
					{
						id: '2.2.5',
						name: 'Epoxy Concrete Repair',
						tid: '100E',
						childFlag: 0,
					},
					{
						id: '2.2.6',
						name: 'Epoxy  Casting',
						tid: '100F',
						childFlag: 0,
					},
					{
						id: '2.2.7',
						name: 'Epoxy Top-Coats',
						tid: '100G',
						childFlag: 0,
					},
					{
						id: '2.2.8',
						name: 'Epoxy Stone Processing',
						tid: '100H',
						childFlag: 0,
					},
				],
			},
			{
				id: 2.3,
				family_id: '102',
				name: 'Composites',
				type: 'system',
				subCatogery: [
					{
						id: '2.3.1',
						name: 'Wind Turbine, Rotor Blades',
						childFlag: 1,
					},
					{
						id: '2.3.2',
						name: 'Light Weighting Solutions for Automotive',
						childFlag: 1,
					},
					{
						childFlag: 0,
						id: '2.3.3',
						tid: '102C',
						name: 'Composite Tanks & Cylinders',
					},
					{
						id: '2.3.4',
						tid: '102D',
						name: 'Composite Pipes',
						childFlag: 0,
					},
					{
						id: '2.3.5',
						tid: '102E',
						name: 'Composite Rebar',
						childFlag: 0,
					},
					{
						id: '2.3.6',
						tid: '102F',
						name: 'Tooling, Molds & Patterns',
						childFlag: 0,
					},
					{
						id: '2.3.7',
						tid: '102G',
						name: 'Composite Insulators (Tubes/Rods)',
						childFlag: 0,
					},
					{
						id: '2.3.8',
						name: 'Adhesives',
						childFlag: 1,
					},
				],
			},
			{
				id: 2.4,
				family_id: '101',
				name: 'Coating',
				type: 'system',
				subCatogery: [
					{
						id: '2.4.1',
						name: 'Epoxy Metalised Polymer Film Coating',
						tid: '101A',
						childFlag: 0,
					},
					{
						id: '2.4.2',
						name: 'Epoxy Metal Primers',
						tid: '101B',
						childFlag: 0,
					},
					{
						id: '2.4.3',
						name: 'Epoxy Powder Coatings',
						tid: '101C',
						childFlag: 0,
					},
					{
						id: '2.4.4',
						name: 'Epoxy Can & Coil Coatings',
						tid: '101D',
						childFlag: 0,
					},
				],
			},
			{
				id: 2.5,
				family_id: '103',
				name: 'Electricals & Electronics',
				type: 'system',
				subCatogery: [
					{
						id: '2.5.1',
						tid: '103A',
						name: 'Switchgear, Bushings & Insulators',
						childFlag: 0,
					},
					{
						id: '2.5.2',
						tid: '103B',
						name: 'Distribution & Power Transformers',
						childFlag: 0,
					},
					{
						id: '2.5.3',
						tid: '103D',
						name: 'Electronic Components',
						childFlag: 0,
					},
					{
						id: '2.5.4',
						tid: '103E',
						name: 'Power Generators & HV motors',
						childFlag: 0,
					},
				],
			},
		],
	},
	{
		id: 3,
		catogery: 'chemistry',
		flag: 1,
		query: 'chemistry',
		mainCatogery: [
			{
				id: 3.1,
				family_id: 'F002',
				name: 'Solid Epoxy Resin',
				type: 'product',
				subCatogery: [
					{
						id: '3.1.1',
						childFlag: 1,
						name: 'Powder Coating Epoxy Resin',
					},
					{
						id: '3.1.2',
						childFlag: 1,
						name: 'Packaging / Can Coating',
					},
					{ id: '3.1.3', childFlag: 1, name: 'Coil Coating' },
				],
			},
			{
				id: 3.2,
				family_id: 'F005',
				name: 'Curing Agents & Hardeners',
				type: 'product',
				subCatogery: [
					{
						id: '3.2.1',
						tid: '5A',
						name: 'Modified Aliphatic Amines',
						childFlag: 0,
					},
					{
						id: '3.2.2',
						tid: '5B',
						name: 'Modified Cycloaliphatic Amines',
						childFlag: 0,
					},
					{
						id: '3.2.3',
						tid: '5C_1',
						name: 'Polyamides',
						childFlag: 0,
					},
					{
						id: '3.2.4',
						tid: '5C_2',
						name: 'Polyamidoamines',
						childFlag: 0,
					},
					{
						id: '3.2.5',
						tid: '5D',
						name: 'Phenalkamines',
						childFlag: 0,
					},
					{
						id: '3.2.6',
						tid: '5E',
						name: 'Phenolic Resins',
						childFlag: 0,
					},
					{
						id: '3.2.7',
						tid: '5G',
						name: 'Waterborne Curing Agents',
						childFlag: 0,
					},
					{
						id: '3.2.8',
						tid: '5F',
						name: 'Fast Curing Agents',
						childFlag: 0,
					},
				],
			},
			{
				id: 3.3,
				family_id: 'F001',
				name: 'Epoxy Resins',
				type: 'product',
				subCatogery: [
					{
						id: '3.3.1',
						tid: '1A',
						name: 'Liquid Epoxy Resin (BPA type)',
						childFlag: 0,
					},
					{
						id: '3.3.2',
						tid: '1B',
						name: 'Solvent cut/semi-solid epoxy resins (Unmodified, BPA based)',
						childFlag: 0,
					},
					{
						id: '3.3.3',
						tid: '1C',
						name: 'Liquid Epoxy Resin (BPF type)',
						childFlag: 0,
					},
					{
						id: '3.3.4',
						tid: '1D',
						name: 'High Functional Epoxy Resins (Phenol novolac type)',
						childFlag: 0,
					},
					{
						id: '3.3.5',
						tid: '1E',
						name: 'High Functional Epoxy Resins (BPA novolac type)',
						childFlag: 0,
					},
					{
						id: '3.3.6',
						tid: '1F',
						name: 'Specialty Liquid Epoxy Resin (Modified, Blends)',
						childFlag: 0,
					},
					{
						id: '3.3.7',
						tid: '1G',
						name: 'High Performance Multifunctional Epoxy Resin',
						childFlag: 0,
					},
					{
						id: '3.3.8',
						tid: '1H',
						name: 'Cycloaliphatic Epoxy Resin',
						childFlag: 0,
					},
				],
			},
			{
				id: 3.4,
				family_id: 'F007',
				name: 'Electrical Process & Systems',
				type: 'system',
				subCatogery: [
					{
						id: '3.4.1',
						tid: '7A',
						table_type: 'system',
						name: 'Vacuum Casting',
						childFlag: 0,
					},
					{
						id: '3.4.2',
						tid: '7B',
						table_type: 'system',
						name: 'Automatic Pressure Gelation / Liquid Injection Molding',
						childFlag: 0,
					},
					{
						id: '3.4.3',
						tid: '7C',
						table_type: 'system',
						name: 'Potting & Encapsulation',
						childFlag: 0,
					},
					{
						id: '3.4.4',
						tid: '7D',
						table_type: 'system',
						name: 'Vacuum Pressure Impregnation',
						childFlag: 0,
					},
				],
			},
			{
				id: 3.5,
				family_id: 'F004',
				name: 'Reactive Diluents',
				type: 'product',
				subCatogery: [
					{ id: '3.5.1', childFlag: 1, name: 'Aliphatic Reactive Diluents' },
					{ id: '3.5.2', childFlag: 1, name: 'Aromatic Reactive Diluents' },
				],
			},
			{
				id: 3.6,
				family_id: 'F006',
				name: 'Composites Process & Systems',
				type: 'system',
				subCatogery: [
					{
						id: '3.6.1',
						tid: '6A',
						name: 'Prepreg & SMC System',
						childFlag: 0,
					},
					{
						id: '3.6.2',
						tid: '6B',
						name: 'Filament Winding and Pultrusion Systems',
						childFlag: 0,
					},
					{
						id: '3.6.3',
						tid: '6C',
						name: 'High Performance Multifunctional Epoxy Systems',
						childFlag: 0,
					},
					{
						id: '3.6.4',
						tid: '6D',
						name: 'Laminating Resin Systems for RTM, Hand lay-up and Infusion/ VARTM',
						childFlag: 0,
					},
					{
						id: '3.6.5',
						tid: '6E',
						name: 'Structural Adhesive Systems',
						childFlag: 0,
					},
					{
						id: '3.6.6',
						tid: '6F',
						name: 'Expandable Epoxy Systems',
						childFlag: 0,
					},
					{
						id: '3.6.7',
						tid: '6G',
						name: 'Flexible Epoxy Systems',
						childFlag: 0,
					},
					{
						id: '3.6.8',
						tid: '6H',
						name: 'Mineral Casting Systems',
						childFlag: 0,
					},
					{
						id: '3.6.9',
						tid: '6I',
						name: 'Preformbinder & Spray Adhesive Systems',
						childFlag: 0,
					},
				],
			},
			{
				id: 3.7,
				family_id: 'F003',
				name: 'Waterborne Products',
				type: 'product',
				subCatogery: [
					{
						id: '3.7.1',
						depth: 0,
						tid: '3A',
						name: 'Waterborne Epoxy Resin',
						childFlag: 0,
					},
					{
						id: '3.7.2',
						tid: '3B',
						name: 'Waterborne Curing Agent / Hardeners',
						childFlag: 0,
					},
				],
			},
			{
				id: 3.8,
				family_id: 'F008',
				name: 'Recyclamine Technology',
				type: 'system',
				subCatogery: [
					{
						id: '3.8.1',
						tid: '8A',
						name: 'Recyclamine-based Epoxy Systems',
						childFlag: 0,
					},
					{
						id: '3.8.2',
						tid: '8B',
						name: 'Recyclamine-based Building Blocks',
						childFlag: 0,
					},
				],
			},
		],
	},
	{
		id: 4,
		catogery: 'Brands',
		flag: 1,
		query: 'brands',
		mainCatogery: [
			{
				id: 4.1,
				family_id: '',
				name: 'Epotec ',
				type: 'Epotec',
			},
			{
				id: 4.2,
				family_id: '',
				name: 'CeTePox ',
				type: 'CeTePox',
			},
			{
				id: 4.3,
				family_id: '',
				name: 'Briozen',
				type: 'Briozen',
			},
			{
				id: 4.4,
				family_id: '',
				name: 'Recyclamine ',
				type: 'Recyclamine',
			},
		],
	},
];
