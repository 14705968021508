import React, { useState } from 'react';
import styled from 'styled-components';
import Form from './Form';

const LocationCard = ({ data }) => {
	const [showForm, setShowForm] = useState(false);
	// eslint-disable-next-line
	const showFormHandler = () => {
		setShowForm(true);
	};
	return (
		<>
			<Header>
				<h1>{data.officeName}</h1>
			</Header>
			<Details>
				<Line>
					<Label>Address</Label> : <h1>{data.address}</h1>
				</Line>
				<Line>
					<Label>Phone</Label> : <h1>{data.phone}</h1>
				</Line>
				<Line>
					<Label>Email</Label> : <h1>{data.email}</h1>
				</Line>
			</Details>
			<Button onClick={showFormHandler}>Enquires</Button>
			{showForm && <Form />}
		</>
	);
};
const Header = styled.div`
	width: 90%;
	h1 {
		color: #313131;
		font-size: 1rem;
		font-weight: 600;
	}
`;
const Label = styled.h2`
	width: 70px;
	color: #0a0a0a;
	font-size: 0.9rem;
	font-weight: 800;
`;
const Line = styled.div`
	display: flex;
	width: 100%;
	h1{
		width:80%;
		margin-left: 10px;
	}
`;
const Details = styled.div`
	width: 90%;
	height: 50%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-around;
	h1 {
		color: #313131;
		font-size: 0.8rem;
		font-weight: 500;
	}
`;
export const Button = styled.button`
	width: 150px;
	height: 35px;
	border: none;
	outline: none;
	background: #00a9c9;
	transition: all 0.5s ease;
	font-size: 0.9rem;
	font-weight: 500;
	letter-spacing: 1px;
	color: #fafafa;
	border-radius: 10px;
	cursor: pointer;
	&:hover {
		background: #39a9cb8d;
	}
`;
export default LocationCard;
