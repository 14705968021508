import { Button, Tooltip } from '@material-ui/core';
import React from 'react';
import { MdDeleteForever } from 'react-icons/md';
import Zoom from '@material-ui/core/Zoom';
import userAuthorization from '../../../../Adapters/Functions/userAuthorization';

export const DeleteButton = ({ ...props }) => {
	const { userPermission } = userAuthorization();

	return (
		<>
			<Tooltip
				placement='top'
				arrow
				TransitionComponent={Zoom}
				title={'Delete'}>
				<>
					<Button
						variant='contained'
						size='small'
						color='secondary'
						disabled={userPermission.disableDelete}
						style={{
							borderRadius: '50%',
							fontSize: '1.2rem',
							maxWidth: '35px',
							maxHeight: '35px',
							minWidth: '35px',
							minHeight: '35px',
						}}>
						<MdDeleteForever {...props} />
					</Button>
				</>
			</Tooltip>
		</>
	);
};
