import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import styled from 'styled-components';
const Card = ({ Data }) => {
	const Loction = useLocation();
	const { subname} = queryString.parse(Loction.search);
	return (
		<CardContainer to={`/portfolio?subname=${Data.name}`}>
			<Image>
				<img src={`/Assets/${Data.link}`} alt='pic' />
			</Image>
			<Details>
				<h3>{Data.name}</h3>
			</Details>
			{subname===Data.name && <Icon>
				<svg
					className='MuiSvgIcon-root jss537'
					focusable='false'
					viewBox='0 0 24 24'
					aria-hidden='true'>
					<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 15l-5-5 1.41-1.41L10 14.17l7.59-7.59L19 8l-9 9z'></path>
				</svg>
			</Icon>}
		</CardContainer>
	);
};

export default Card;
const CardContainer = styled(Link)`
	width: 100%;
	max-width: 250px;
	min-width: 220px;
	height: 120px;
	background-color: ${({ state }) => (state ? '#36a9cc' : '#ffffff')};
	border-radius: 20px;
	display: flex;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 10px;
	justify-content: space-around;
	cursor: pointer;
	border: 2px solid ${({ state }) => (state ? '#379DBD' : 'transparent')};
	text-decoration: none;
	color: #333;
	position: relative;
`;
const Image = styled.div`
	width: 35%;
	display: grid;
	place-items: center;
	img {
		width: 60px;
		object-fit: contain;
	}
`;
const Details = styled.div`
	width: 60%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: space-around;
	height: 90%;
	position: relative;
	h3 {
		font-size: 1rem;
		color: ${({ state }) => (state ? '#ffffff' : '##373744')};
		text-transform: capitalize;
		text-align: center;
		width: 100%;
	}
`;
const Icon = styled.div`
	width: 25px;
	height: 25px;
	position: absolute;
	top: 5px;
	right: 5px;
	svg {
		fill: #15d815;
	}
`;
