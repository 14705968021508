import React, { useState } from "react";
import { useAsyncDebounce } from "react-table";
import styled from "styled-components";
import { BiSearchAlt } from "react-icons/bi";
import { useData } from "../../../Context/DataProvider";
import { CSVLink } from "react-csv";
import { SiMicrosoftexcel } from "react-icons/si";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom";
import moment from "moment";

export const GlobalFilter = ({ filter, setFilter, CSV_Data, CSV_Header }) => {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce((value) => {
    setFilter(value || undefined);
  }, 1000);
  const { tableData } = useData();

  const CSV_Dataa = CSV_Data.map((el) => {
    let uuid = el.uid;
    let newId = '=""' + uuid + '""';
    let plainDate = el.createdAt;
    let date = moment(plainDate).format("DD-MMM-YYYY");
    let newDate = '=""' + date + '""';
    return { ...el, uid: newId, createdAt: newDate };
  });

  //------------------
  const csvReport = {
    //filename: 'Report.csv',
    filename: `${CSV_Data[0]?.formType}.csv`,
    headers: CSV_Header,
    //data: CSV_Data,
    data: CSV_Dataa,
  };
  return (
    <Header>
      <Info>{tableData.tableNameData.replace("-", " ")}</Info>
      <Search>
        <form>
          <input
            type="text"
            value={value || ""}
            placeholder="search"
            onChange={(e) => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
          />
          <button type="submit">
            <BiSearchAlt size={20} style={{ color: "white" }} />
          </button>
        </form>
        <CSVLink {...csvReport}>
          <Tooltip
            arrow
            TransitionComponent={Zoom}
            title={"Export CSV File"}
            placement="top"
          >
            <Icons>
              <SiMicrosoftexcel />
            </Icons>
          </Tooltip>
        </CSVLink>
      </Search>
    </Header>
  );
};
const Header = styled.div`
  width: 100%;
  height: 40px;
  background-color: #ece3e3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
`;
const Info = styled.p`
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 0.9rem;
`;
const Search = styled.div`
  display: flex;
  align-items: center;
  form {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b3d0d8;
    border-radius: 5px;
  }
  button {
    padding: 6px 10px;
    font-size: 1px;
    border: none;
    cursor: pointer;
    background: #b8b5ff;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    transition-duration: 0.5s;
    &:hover {
      background: #92a8d1;
    }
  }
  input {
    width: 100%;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }
  input[type="text"] {
    padding: 8px;
    font-size: 12px;
    border: none;
    outline: none;

    &::placeholder {
      font-size: 0.7rem;
      letter-spacing: 1px;
    }
  }
`;
const Icons = styled.div`
  margin: 0 10px;
  font-size: 1.2rem;
  color: #ee842d;
`;
